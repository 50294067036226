import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import Browse from "../../assets/images/Browse.png";
import { avatars_list } from "../../static-data/AvaratsList";
import GlobalContext from "../../context/GlobalContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function UploadCV(props) {
  const cancelButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(true);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Transition.Root show={props.showAvatar} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999999]"
          initialFocus={cancelButtonRef}
          onClose={props.setShowAvatar}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[500px] py-8 px-8">
                  <div className="absolute top-[10px] right-[10px] cursor-pointer">
                    <AiOutlineClose
                      onClick={() => props.setShowAvatar(false)}
                    />
                  </div>
                  <div className="w-full h-auto flex flex-col justify-center items-center">
                    <div className="w-full tab-button flex flex-col justify-center items-center">
                      <div className="w-full flex justify-between py-[10px] px-[10px] bg-[#4E49491A] rounded-[9px] mt-4">
                        <button
                          className={`picture-btn ${isOpen ? "active" : ""}`}
                          onClick={handleOpen}
                        >
                          Picture
                        </button>
                        <button
                          className={`avatar-btn ${!isOpen ? "active" : ""}`}
                          onClick={handleClose}
                        >
                          Avatar
                        </button>
                      </div>
                      {isOpen && (
                        <Picture
                          setShowAvatar={props.setShowAvatar}
                          handleSavePicture={props.handleSavePicture}
                        />
                      )}
                      {!isOpen && (
                        <Avatar
                          setShowAvatar={props.setShowAvatar}
                          handleSaveAvatar={props.handleSaveAvatar}
                        />
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

function Picture(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    let interval;

    if (selectedFile) {
      setFileError(null);
      const fileType = selectedFile.type;
      if (fileType !== "image/jpeg" && fileType !== "image/png") {
        setFileError("Only JPEG and PNG file types are allowed.");
        setSelectedFile(null);
        return;
      }

      interval = setInterval(() => {
        if (progress < 100) {
          setProgress(progress + 20);
        }
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [progress, selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setProgress(0);
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInMb = sizeInBytes / (1024 * 1024);
    return sizeInMb.toFixed(2) + " MB";
  };

  const removefile = () => {
    setSelectedFile(null);
    setProgress(0);
  };

  return (
    <>
      <div className="w-full mt-4 flex flex-col justify-center items-center">
        <h1 className="text-[#263238] font-medium text-[30px]">
          Upload picture
        </h1>
        <div className="rounded-[10px] border border-dotted border-[#C6A4FF] mt-2 w-full h-auto py-4 flex flex-col justify-center items-center">
          <div>
            {/* {!values.Browse && ( */}
            <img className="w-[70px] h-[60px]" src={Browse} alt="" />
            {/* )} */}
          </div>
          <div className="text-center px-2 pb-5 ">
            <span className="text-base">
              Drag and drop to upload <br />
              or{" "}
            </span>
            <label
              className="text-[#C6A4FF] font-normal text-base cursor-pointer underline "
              htmlFor="sub_category_pic"
            >
              Browse
            </label>
          </div>
          <input
            type="file"
            id="sub_category_pic"
            accept=".jpg, .png, .jpeg, .jfif"
            name="image"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {/* {selectedFile && <p>{selectedFile.name}</p>} */}
        </div>

        <div className="w-full flex  justify-between py-2">
          <span className="text-[10px] ">JPG or PNG</span>
          {fileError && (
            <span className="text-red-700 text-[10px]">{fileError}</span>
          )}
        </div>
        {selectedFile && (
          <div className="w-full justify-start py-3">
            <p className="text-[#4E4949] font-normal text-xs">
              {selectedFile.name}
            </p>
            <p className="text-[#4E4949] font-normal text-xs">
              {formatFileSize(selectedFile.size)}
            </p>
          </div>
        )}
        <div className="w-full flex items-center justify-center gap-1">
          <div class="w-[100%] bg-[#C6A4FF] rounded-full h-0.5 dark:bg-[#FF5E59]">
            <div
              class="bg-[#FF5E59] h-0.5 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <AiOutlineCloseCircle
            onClick={removefile}
            className="cursor-pointer "
          />
        </div>
        <div className="flex justify-between w-full mt-1">
          <span className=" text-[10px] text-[#4E4949]">
            Time remaining: 0 sec
          </span>
          <span className="text-[#4E4949] text-[10px]">{`${progress}%`}</span>
        </div>
        <div className="flex w-full gap-2 justify-end mt-5">
          <button
            className="bg-[#4E494933] w-[94px] h-[36px] rounded-[5px] text-black cursor-pointer"
            onClick={() => props.setShowAvatar(false)}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              props.handleSavePicture(selectedFile);
              props.setShowAvatar(false);
            }}
            className={`bg-[#FF5E59] w-[94px] h-[36px] rounded-[5px] outline-none text-white cursor-pointer ${
              !selectedFile ? "disabled:opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={!selectedFile}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

function Avatar(props) {
  const { userInfo } = useContext(GlobalContext);
  const [ShowSkeleton, setShowSkeleton] = useState(true);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setShowSkeleton(false);
  //   }, 2000);

  //   return () => clearTimeout(timeoutId);
  // }, []);

  useEffect(() => {
    const hasShownSkeleton = localStorage.getItem("hasShownSkeleton");

    if (hasShownSkeleton) {
      setShowSkeleton(false);
    } else {
      const timeoutId = setTimeout(() => {
        setShowSkeleton(false);
        localStorage.setItem("hasShownSkeleton", "true");
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  const [selectedAvatar, setSelectedAvatar] = useState(
    userInfo?.avatar_url ? userInfo?.avatar_url : "0"
  );

  return (
    <>
      <div className="flex flex-col justify-center items-center w-full ">
        <h1 className="text-[#263238] text-3xl font-medium pt-5 ">
          Choose avatar
        </h1>
        {ShowSkeleton ? (
          <Skeleton
            count={1}
            variant="circular"
            width={100}
            height={100}
            borderRadius={100}
          />
        ) : (
          <div
            className="h-full w-[200px] flex items-center justify-center  bg-contain bg-center bg-no-repeat  "
            style={{ backgroundImage: "url(/images/Avatarbg.svg)" }}
          >
            <img
              className=" rounded-full w-[120px] "
              src={avatars_list[selectedAvatar]?.avatar}
              alt=""
            />
          </div>
        )}
        <div className="flex flex-col gap-1 w-full">
          <span className="lg:text-[18px]">Select avatar</span>
          <div className=" border-t border-[#95969D]" />
          {ShowSkeleton ? (
            <div className="flex flex-row flex-wrap justify-center w-full gap-2">
              <Skeleton
                count={1}
                variant="rectangle"
                width={80}
                height={80}
                borderRadius={100}
              />
              <Skeleton
                count={1}
                variant="rectangle"
                width={80}
                height={80}
                borderRadius={100}
              />
              <Skeleton
                count={1}
                variant="rectangle"
                width={80}
                height={80}
                borderRadius={100}
              />
              <Skeleton
                count={1}
                variant="rectangle"
                width={80}
                height={80}
                borderRadius={100}
              />
              <Skeleton
                count={1}
                variant="rectangle"
                width={80}
                height={80}
                borderRadius={100}
              />
              <Skeleton
                count={1}
                variant="rectangle"
                width={80}
                height={80}
                borderRadius={100}
              />
              <Skeleton
                count={1}
                variant="rectangle"
                width={80}
                height={80}
                borderRadius={100}
              />
              <Skeleton
                count={1}
                variant="rectangle"
                width={80}
                height={80}
                borderRadius={100}
              />
            </div>
          ) : (
            <div className="flex flex-wrap justify-center items-center gap-2 mt-2">
              {avatars_list?.map((data, index) => (
                <div key={index}>
                  <img
                    onClick={() => setSelectedAvatar(index)}
                    className=" rounded-full w-[80px] "
                    src={data.avatar}
                    alt=""
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex w-full gap-2 justify-end mt-6">
          <button
            className="bg-[#4E494933] w-[94px] h-[36px] rounded-[5px] text-black cursor-pointer"
            onClick={() => props.setShowAvatar(false)}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              props.handleSaveAvatar(selectedAvatar);
              props.setShowAvatar(false);
            }}
            className="bg-[#FF5E59] w-[94px] h-[36px] rounded-[5px] outline-none text-white cursor-pointer"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
