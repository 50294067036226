/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { IoMdSend } from "react-icons/io";
import TopicIcon from "../assets/images/newtopic.png";
import Voice from "../assets/images/Voicesearch.png";
import SpeechRecognition, {
  useSpeechRecognition
} from "react-speech-recognition";
import useCVModification from "../hooks/useCVModification";

function ChatInput(props) {
  const {
    inputQuery,
    setInputQuery,
    setChatData,
    setChatLoading,
    setErrorMessage,
    setSendBtnLoading,
    sendBtnLoading,
    cvDetail,
    setSecondQuestion,
    clearChatData,
    chatData,
    chatLoading,
    setShowMakeCVQuestion
  } = useContext(GlobalContext);
  const [isBlinking, setBlinking] = useState(false);
  const { CVModification } = useCVModification();

  useEffect(() => {
    if (props.alreadyUploadedCV === "UPLOAD_CV") {
      setChatLoading(true);
      setSendBtnLoading(true);
      const payload = {
        cv: cvDetail?.cv_obj,
        job_description: cvDetail.job_description,
        user_query: ""
      };

      CVModification(payload);
    } else if (props.alreadyUploadedCV === "USE_MY_SAVED_CV") {
      setChatLoading(true);
      setSendBtnLoading(true);
      const payload = {
        cv: cvDetail?.cv_obj,
        job_description: cvDetail.job_description,
        user_query: ""
      };
      CVModification(payload);
    }
  }, [props.alreadyUploadedCV]);
  const handleSubmit = (e) => {
    e.preventDefault();
    props.setProgress(0);
    props.setTextIndex(0);
    setShowMakeCVQuestion(false);
    const messageWithLineBreaks = inputQuery.replace(/\n/g, "\n");
    setChatLoading(true);
    setSendBtnLoading(true);
    setChatData([{ role: "user", content: messageWithLineBreaks }]);
    let data;
    for (let i = chatData.length - 1; i >= 0; i--) {
      if (chatData[i].role === "cv_data") {
        data = chatData[i].content;
        break;
      }
    }
    const JsonData = JSON.parse(data);
    const payload = {
      cv: JsonData,
      job_description: cvDetail.job_description,
      user_query: inputQuery
    };
    CVModification(payload);
    setInputQuery("");
  };

  const handleChange = (e) => {
    setInputQuery(e.target.value);
  };

  //testarea
  useEffect(() => {
    const textarea = document.querySelector(".input");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [inputQuery]);
  useEffect(() => {
    const textarea = document.querySelector(".input");
    if (textarea) {
      textarea.removeAttribute("style");
      textarea.style.height = "20px";
      textarea.style.minHeight = "20px";
      textarea.style.maxHeight = "40px";
      textarea.style.resize = "none";
      const scrollHeight = textarea.scrollHeight;
      if (scrollHeight <= 150) {
        textarea.style.height = `${scrollHeight}px`;
      } else {
        textarea.style.height = "100px";
      }
      textarea.addEventListener("input", () => {
        const scrollHeight = textarea.scrollHeight;
        if (scrollHeight <= 150) {
          textarea.style.height = `${scrollHeight}px`;
        } else {
          textarea.style.height = "40px";
        }
      });
    }
  }, [inputQuery]);

  const { transcript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  useEffect(() => {
    if (transcript) {
      setInputQuery(transcript);
    }
  }, [transcript]);
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const handleVoiceClick = () => {
    if (listening) {
      setBlinking(false);
      SpeechRecognition.stopListening();
    } else {
      setBlinking(true);
      SpeechRecognition.startListening();
    }
  };

  const handlecleardata = () => {
    clearChatData();
    setSecondQuestion(true);
    setChatLoading(false);
    setSendBtnLoading(false);
    props.setAlreadyUploadedCV(null);
    setShowMakeCVQuestion(false);
    setErrorMessage("");
    props.setProgress(0);
    props.setTextIndex(0);
  };
  return (
    <>
      {chatLoading === true || chatData.length === 0 ? (
        ""
      ) : (
        <div className="flex items-center  xs:justify-center w-full py-2 px-2 h-auto gap-3 mb-0 xs:m-0 shadow-input rounded-[10px] relative overflow-hidden">
          {(chatLoading === true || chatData.length === 0) && (
            <div className="h-full w-full bg-[#82828250] absolute z-10"></div>
          )}
          <div onClick={handlecleardata} className="flex lg:px-2 xs:px-0">
            <div className="cursor-pointer bg-[#C6A4FF] lg:w-[117px] xs:w-[30px] xs:h-[20px] lg:h-[34px]   lg:text-xs xs:text-[10px] text-white flex items-center justify-center gap-2 shadow-topic rounded-[5px]">
              <img src={TopicIcon} alt="" className="xs:w-[12px] xs:h-[12px]" />
              <span className="xs:hidden lg:flex">New Topic</span>
            </div>
          </div>
          <div className="lg:w-full w-full lg:h-[44px] flex z-[1] items-center justify-center py-1 h-full  box-border border border-[#EBEBEB] rounded-[10px]">
            <textarea
              className="lg:w-full w-full  xs:text-[8px] lg:text-base  py-[4px] px-[10px]  touch-manipulation  focus:outline-none overflow-scroll input placeholder-text"
              type="text"
              placeholder="Hello, how can I help you..."
              onChange={(e) => handleChange(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  e.stopPropagation();
                  if (
                    cvDetail?.job_description &&
                    inputQuery &&
                    !sendBtnLoading
                  ) {
                    setSendBtnLoading(true);
                    handleSubmit(e);
                  } else {
                    setSendBtnLoading(false);
                  }
                }
              }}
              value={inputQuery}
              style={{
                minHeight: "20px",
                maxHeight: "40px",
                fontSize: "16px"
              }}
              required
              name="footer-input"
            />

            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  cvDetail?.job_description &&
                  inputQuery &&
                  !sendBtnLoading
                ) {
                  setSendBtnLoading(true);
                  handleSubmit(e);
                } else {
                  setSendBtnLoading(false);
                }
              }}
              className={`flex items-center justify-center text-center rounded-[100px] lg:w-[35px] xs:w-[16px] xs:h-[16px] lg:h-[30px] cursor-pointer border-none mx-5 
          ${
            cvDetail?.job_description && inputQuery && !sendBtnLoading
              ? "bg-[#C6A4FF]"
              : "bg-[#CCCCCC]"
          }`}
            >
              <IoMdSend
                style={{
                  objectFit: "fill",
                  width: "20px",
                  height: "20px",
                  color: "#FFFFFF",
                  marginLeft: "3px"
                }}
              />
            </button>
          </div>
          <div
            onClick={handleVoiceClick}
            style={{ boxShadow: " 0px 2px 8px 0px #00000040" }}
            className="bg-[#ffff] lg:rounded-[50px] lg:w-[45px] lg:h-[35px] xs:w-[38px] xs:h-[22px] xs:rounded-[50px] flex items-center justify-center cursor-pointer lg:mx-3 xs:mx-0 "
          >
            <img
              className={`lg:w-[15px] xs:w-[7px] lg:h-[18px] xs:h-[12px] relative ${
                isBlinking && listening ? "blinking" : ""
              }`}
              src={Voice}
              alt=""
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ChatInput;
