function useRemoveHtmlTags() {
  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  function removeHtmlTagsFromData(data) {
    const newData = { ...data };

    for (const key in newData) {
      if (typeof newData[key] === "string") {
        newData[key] = stripHtmlTags(newData[key]);
      } else if (Array.isArray(newData[key])) {
        newData[key] = newData[key].map((item) => removeHtmlTagsFromData(item));
      } else if (typeof newData[key] === "object") {
        newData[key] = removeHtmlTagsFromData(newData[key]);
      }
    }

    return newData;
  }
  return { removeHtmlTagsFromData };
}

export default useRemoveHtmlTags;
