import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";

function BackButton() {
  return (
    <div className="flex items-center gap-2">
      <div className="h-[35px] flex items-center">
        <IoMdArrowRoundBack className="text-[22px]" />
      </div>
      <div className="h-[35px] flex items-center">
        <h1 className="font-bold text-[22px]">Back</h1>
      </div>
    </div>
  );
}

export default BackButton;
