import { useState } from "react";
import axios from "axios";

function useDownloadCV() {
  const PDF_BASE_URL = process.env.REACT_APP_PDF_BASE_URL;
  const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);
  const [pdfViewer, setPdfViewer] = useState(null);

  const DownloadCV = async (payload, username) => {
    setDownloadBtnLoading(true);
    await axios
      .post(
        `${PDF_BASE_URL}/api/pdf-preview`,
        { html: payload, username: username }
        // { responseType: "blob" }
      )
      .then((response) => {
        // const blob = new Blob([response.data], {
        //   type: "application/pdf",
        // });
        // console.log({ response });
        setPdfViewer(response.data.fileUrl);
        // const url = URL.createObjectURL(blob);
        // const a = document.createElement("a");
        // a.href = url;
        // a.download = `${username}.pdf`;
        // a.click();
        // URL.revokeObjectURL(url);
        setDownloadBtnLoading(false);
      })
      .catch((error) => {
        console.log({ error });
        setDownloadBtnLoading(false);
      });
  };

  return {
    downloadBtnLoading,
    DownloadCV,
    pdfViewer,
  };
}

export default useDownloadCV;
