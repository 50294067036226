import React, { useState } from "react";
import { Link } from "react-router-dom";
import useNewPassword from "../../hooks/useNewPassword";
import { ButtonLoader2 } from "../../components/shared/ButtonLoader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { BiHide, BiShow } from "react-icons/bi";
import Cookies from "js-cookie";

const initialValues = {
  password: "",
  confirm_password: "",
};
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password length must be at least 6 characters long!")
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      "Password must contain at least one alphabetic character and one special character"
    )
    .max(20, "password must not exceed 20 characters"),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

const NewPassword = () => {
  const { loading, newPassword } = useNewPassword();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const PasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [Visible, setVisible] = useState(false);
  const Visibility = () => {
    setVisible(!Visible);
  };

  const verification_token = Cookies.get("verification_token");
  const uid = Cookies.get("UID");

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await newPassword({
        password: values.password,
        token: verification_token,
        uidb64: uid,
      });
    },
  });

  return (
    <>
      <div className="flex flex-col justify-center w-full h-screen items-center">
        <div className="fixed top-[10px] left-[10px] lg:flex xs:hidden flex items-center justify-start gap-2 ">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={"/images/crewdog_black.svg"} alt="" className="" />
          </a>
          <img src={"/images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img src={"/images/cvlab_black.svg"} alt="" className="" />
          </Link>
        </div>
        <div className="lg:hidden xs:flex justify-center flex items-center gap-2 h-[25vh] ">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={"/images/crewdog_black.svg"} alt="" className="" />
          </a>
          <img src={"/images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img src={"/images/cvlab_black.svg"} alt="" className="" />
          </Link>
        </div>
        <div className="lg:w-[40%] 2xl:w-[35%] 3xl:w-[25%] w-full lg:h-auto xs:h-[75vh] flex flex-col justify-center items-center box-border border border-[#FFEFEE] bg-[#fcf4f4] p-forgot rounded-[45px] px-5 xs:py-12 lg:py-20  lg:mt-0 xs:mt-4">
          <h1 className="text-[#263238] lg:text-[44px] xs:text-2xl font-semibold">
            New password
          </h1>

          <span className="text-[#95969D] lg:text-[18px] text-[12px] font-normal my-4 text-center w-[345px]">
            Set the new password for your account
          </span>
          <div className="flex flex-col gap-2 w-full">
            <div className="w-full">
              <label className="md:text-[18px] text-[14px]">Password</label>
              <div className="relative mt-1">
                <input
                  type={Visible ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Enter password..."
                  className="bg-[#fff] w-full py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px] text-[18px]"
                />
                <div
                  className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                  onClick={Visibility}
                >
                  {Visible ? (
                    <BiShow className="text-2xl text-[#95969D]" />
                  ) : (
                    <BiHide className="text-2xl text-[#95969D]" />
                  )}
                </div>
              </div>
              {errors.password && touched.password && (
                <p className="text-red-700 text-xs">{errors.password}</p>
              )}
            </div>
            <div className="w-full">
              <label className="md:text-[18px] text-[14px]">
                Confirm Password
              </label>
              <div className="relative mt-1">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  placeholder="confirm password..."
                  className="bg-[#fff] w-full py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px] text-[18px]"
                />
                <div
                  className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center"
                  onClick={PasswordVisibility}
                >
                  {passwordVisible ? (
                    <BiShow className="text-2xl text-[#95969D]" />
                  ) : (
                    <BiHide className="text-2xl text-[#95969D]" />
                  )}
                </div>
              </div>
              {errors.confirm_password && touched.confirm_password && (
                <p className="text-red-700 text-xs">
                  {errors.confirm_password}
                </p>
              )}
            </div>
          </div>

          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-[#FF5E59] w-full h-[50px] text-white rounded-[5px] flex justify-center items-center mt-8 text-[20px]"
          >
            {loading ? <ButtonLoader2 /> : "Reset password"}
          </button>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
