import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import Navbar from "../../components/shared/Navbar";
import Footer from "../../components/shared/Footer";
import Edit from "../../assets/images/Edit.png";
import Notification from "../../assets/images/notification.png";
import demo_avatar from "../../assets/images/profilepic.png";
import BG from "../../assets/images/Banner-bg.png";

import Lock from "../../assets/images/ChnagePassword.png";
import "../../style/Circle.css";
import { IoMdArrowBack } from "react-icons/io";
import { avatars_list } from "../../static-data/AvaratsList";
import BackButton from "../../components/shared/BackButton";

const Profile = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const { userInfo } = useContext(GlobalContext);

  const Navigate = useNavigate();
  const Toeditprofile = () => {
    Navigate("/editprofile");
  };

  // console.log({ offset });
  return (
    <>
      <Navbar />
      <div className="grid lg:grid-cols-[30%_minmax(70%,_1fr)] lg:mt-[75px] xs:mt-[70px] py-5 mb-8 bg-contain bg-no-repeat min-h-[75vh] xs:px-2 lg:px-0 overflow-x-hidden">
        <div className="h-[30px] px-10 lg:flex xs:hidden ">
          <img
            className="w-[100%] h-[45%] absolute top-0 left-0 z-[-1]"
            // style={{ aspectRatio: "1/1", objectFit: "cover" }}
            src={BG}
            alt=""
          />
          <Link className="cursor-pointer" to="/">
            <BackButton />
          </Link>
        </div>

        <div className="h-auto lg:w-[500px] 2xl:w-[50%] w-full flex flex-col xs:justify-start lg:justify-center items-center rounded-[20px] gap-2 relative sm:py-[5px] lg:py-10 lg:shadow-profile xs:shadow-profile lg:px-12 xs:px-6">
          <h1 className="flex text-[#263238] font-semibold lg:text-[44px] xs:text-[30px] ">
            Profile
          </h1>
          <div className="large-circle">
            {userInfo?.profile_pic && (
              <img
                className="rounded-full w-full h-full"
                style={{ aspectRatio: "1/1", objectFit: "cover" }}
                src={`${BASE_URL}/${userInfo.profile_pic}`}
                alt=""
              />
            )}
            {userInfo?.avatar_url && (
              <img
                className="rounded-full w-full h-full"
                style={{ aspectRatio: "1/1", objectFit: "cover" }}
                src={avatars_list[userInfo.avatar_url]?.avatar}
                alt=""
              />
            )}
            {!userInfo?.profile_pic && !userInfo?.avatar_url && (
              <img
                className="rounded-full w-full h-full"
                style={{ aspectRatio: "1/1", objectFit: "cover" }}
                src={demo_avatar}
                alt=""
              />
            )}
          </div>
          <div className="absolute top-3 left-3 lg:hidden xs:block">
            <Link to="/">
              <IoMdArrowBack className="text-[30px]" />
            </Link>
          </div>
          <div
            onClick={Toeditprofile}
            className="flex items-center justify-center bg-[#ffff] rounded-full lg:w-[50px] xs:w-[32px] lg:h-[50px] xs:h-[32px] border-[2px] border-[#C6A4FF] absolute lg:right-5 lg:top-5 xs:top-2 xs:right-2 cursor-pointer "
          >
            <img
              src={Edit}
              alt=""
              className="lg:w-[25px] lg:h-[25px] xs:w-[13px] xs:h-[14px]"
            />
          </div>
          <h2 className="text-[#FF5E59] font-semibold lg:text-[44px] xs:text-[24px]">
            {userInfo?.first_name} {userInfo?.last_name}
          </h2>
          <span className="text-[#263238] font-normal lg:text-lg xs:text-[14px] mb-4">
            {userInfo?.email}
          </span>
          <div className="flex flex-col border-t border-[#95969D] w-full gap-5 ">
            <div className="flex items-center gap-2 py-3 border-b border-[#95969D] px-4 ">
              <img src={Lock} alt="" className="w-[16px] h-[22px]" />
              <Link to="/changepassword" className="text-[#263238] text-[18px]">
                Change password
              </Link>
            </div>
            <div className="flex items-center justify-between gap-2 py-2 border-b border-[#95969D] px-4">
              <div className="flex items-center gap-2">
                <img src={Notification} alt="" className="w-[16px] h-[22px]" />
                <span className="text-[#263238] text-[18px]">
                  Notifications
                </span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className="w-8 h-4 bg-[#4E4949] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
