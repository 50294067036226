import React from "react";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";

function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <div className="  py-10 lg:px-14 xs:px-7 w-full text-justify ">
        <h1 className="font-semibold text-[20px] text-center mt-16 mb-5">
          Privacy Policy
        </h1>
        <p>
          This privacy policy applies to CrewDog, an intelligent assistant
          developed by the CrewDog team. CrewDog aims to provide a safe and
          secure experience for all users while maintaining the confidentiality
          of their information. CrewDog complies with the UK General Data
          Protection Regulation (GDPR) and ensures that user data is used only
          for the purposes described below.
        </p>
        <div className="px-7">
          <h1 className="font-semibold mt-5 text-[16px]">
            Information Collection and Use:
          </h1>
          <div className="px-2">
            <li className="my-1">
              When users register with CrewDog, personal data such as name,
              contact details, work preferences, and CV information may be
              collected.
            </li>
            <li className="my-1">
              CrewDog uses this personal data to manage user accounts and send
              email notifications about relevant job alerts and courses.
            </li>
            <li className="my-1">
              User information is securely stored and protected, and access to
              it is restricted to the user themselves.
            </li>
            <li className="my-1">
              Users have the option to delete their accounts or hide their CVs
              from view.
            </li>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            Profile Matching and Job Search:
          </h1>
          <div className="px-2">
            <li className="my-1">
              CrewDog matches user profiles with job requirements and provides
              intelligent job recommendations based on user preferences and
              behaviour.
            </li>
            <li className="my-1">
              Jobseeker profiles, including CVs, can be made available for
              searching and viewing by recruiters and employment consultants.
            </li>
            <li className="my-1">
              CrewDog attempts to verify the identity and authenticity of
              organisations accessing user profiles but does not provide
              warranties in this regard.
            </li>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            Privacy and Security:
          </h1>
          <div className="px-2">
            <li className="my-1">
              CrewDog takes privacy and security seriously and ensures that user
              information is used only for job search and profile matching
              purposes.
            </li>
            <li className="my-1">
              User data is not shared with unauthorised third parties, and users
              have control over the visibility of their profiles.
            </li>
            <li className="my-1">
              CrewDog is not responsible for the information received by clients
              or customers of CrewDog.
            </li>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            Cookies and Tracking:
          </h1>
          <div className="px-2">
            <li className="my-1">
              CrewDog and its partners use technologies like cookies to store
              and/or access device information for personalised experiences.
            </li>
            <li className="my-1">
              Users can choose to accept or reject these technologies, but not
              consenting may affect certain features and functions.
            </li>
            <li className="my-1">
              CrewDog uses IP logging for general location determination and to
              provide location-specific content and questions.
            </li>
            <li className="my-1">
              Information gathered through cookies is used for statistical
              purposes, website administration, and service improvement.
            </li>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            Third-Party Cookies and Pixels:
          </h1>
          <div className="px-2">
            <li className="my-1">
              CrewDog uses third-party services such as Google Maps and Criteo
              for personalised advertising and marketing activities.
            </li>
            <li className="my-1">
              Users have the right to express their choices regarding the
              sharing of data and can opt-out of certain features.
            </li>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            Email Communications:
          </h1>
          <div className="px-2">
            <li className="my-1">
              Users who register with CrewDog will receive essential
              communications, job alerts, and updates via email.
            </li>
            <li className="my-1">
              Users can customise their email preferences and unsubscribe from
              specific types of communications.
            </li>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            Statistical Information:
          </h1>
          <div className="px-2">
            <li className="my-1">
              CrewDog gathers general information about user behaviour on the
              site for statistical purposes and service improvement.
            </li>
            <li className="my-1">
              Aggregated and anonymous statistics may be shared with users,
              clients, advertisers, and reputable third parties.
            </li>
          </div>
        </div>
        <h1 className="font-semibold mt-5  text-[16px]">Conclusion:</h1>
        <p>
          CrewDog prioritises user privacy and security while providing
          personalised job search and profile matching services. Users have
          control over their personal information and can customise their
          preferences. CrewDog complies with GDPR regulations and ensures the
          confidentiality of user data.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
