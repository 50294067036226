import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const useChangePassword = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token") || Cookies.get("token");

  const ChangePassword = async (payload, action) => {
    setLoading(true);
    await axios
      .put(`${BASE_URL}/api/accounts/password/change/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        action.resetForm();
        Swal.fire({
          icon: "success",
          text: "Password changed succcessfully",
          customClass: {
            confirmButton: "custom-green-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Navigate("/");
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.detail,
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, ChangePassword };
};

export default useChangePassword;
