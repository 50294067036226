import React, { Fragment, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import GlobalContext from "../context/GlobalContext";
import Cookies from "js-cookie";
import { OauthSender } from "react-oauth-flow";
const MobileMenu = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token") || Cookies.get("token");
  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL_SSO;
  const SSO_Client_id = process.env.REACT_APP_CLIENT_ID_SSO;

  const { setActiveButton } = useContext(GlobalContext);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="flex items-center outline-none">
          <div className="flex items-center lg:hidden xs:flex">
            <div
              className=" border border-[#263238] rounded-[2px] p-[6px]"
              style={{
                background:
                  "linear-gradient(93deg, #FFD8D8 0.91%, #C6A4FF 99.36%)"
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
              >
                <path
                  d="M7.5 10H0.833333C0.333333 10 0 9.66667 0 9.16667C0 8.66667 0.333333 8.33333 0.833333 8.33333H7.5C8 8.33333 8.33333 8.66667 8.33333 9.16667C8.33333 9.66667 8 10 7.5 10ZM12.5 5.83333H0.833333C0.333333 5.83333 0 5.5 0 5C0 4.5 0.333333 4.16667 0.833333 4.16667H12.5C13 4.16667 13.3333 4.5 13.3333 5C13.3333 5.5 13 5.83333 12.5 5.83333ZM12.5 1.66667H5.83333C5.33333 1.66667 5 1.33333 5 0.833333C5 0.333333 5.33333 0 5.83333 0H12.5C13 0 13.3333 0.333333 13.3333 0.833333C13.3333 1.33333 13 1.66667 12.5 1.66667Z"
                  fill="#263238"
                />
              </svg>
            </div>
          </div>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-1">
            <div className="w-[250px] h-auto bg-[#FFFCFC] border-[0.8px] border-[#FFEFEE] rounded-[16px]">
              {token ? (
                <div className="flex flex-col px-2 border border-[#F8F8F8] bg-[#fff] w-[230px] h-auto rounded-[10px] my-2 mx-2">
                  <NavLink
                    to="/jobchat"
                    className="nav-link font-normal text-[14px] text-[#263238] my-2"
                  >
                    Job Chat
                  </NavLink>
                  <NavLink
                    to="/chat"
                    className="nav-link font-normal text-[14px] text-[#263238] my-2"
                  >
                    Write my CV
                  </NavLink>
                  <div
                    className="nav-link font-normal text-[14px] text-[#263238] my-2 cursor-pointer"
                    onClick={() => (
                      navigate("/saved"), setActiveButton("templates")
                    )}
                  >
                    Templates
                  </div>
                  <NavLink
                    to="/blogs"
                    className="nav-link font-normal text-[14px] text-[#263238] my-2"
                  >
                    blogs
                  </NavLink>
                </div>
              ) : (
                <OauthSender
                  clientId={SSO_Client_id}
                  authorizeUrl={`${SSOUrl}/authorize`}
                  redirectUri={redirectUrl}
                  render={({ url }) => (
                    <a
                      href={url}
                      className="w-[250px] h-auto bg-[#FFFCFC] border-[0.8px] border-[#FFEFEE] rounded-[16px]"
                    >
                      <div className="flex flex-col px-2 border border-[#F8F8F8] bg-[#fff] w-[230px] h-auto rounded-[10px] my-2 mx-2">
                        <div className="nav-link font-normal text-[14px] text-[#263238] my-2">
                          Job Chat
                        </div>
                        <div className="nav-link font-normal text-[14px] text-[#263238] my-2">
                          Write my CV
                        </div>
                        <div className="nav-link font-normal text-[14px] text-[#263238] my-2 cursor-pointer">
                          Templates
                        </div>
                        <div className="nav-link font-normal text-[14px] text-[#263238] my-2">
                          blogs
                        </div>
                      </div>
                    </a>
                  )}
                />
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default MobileMenu;
