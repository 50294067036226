import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";

const BlogDetails = () => {
  const { id } = useParams();
  const data = localStorage.getItem("blogs");
  const [blogDetails, setBlogDetails] = useState(null);

  useEffect(() => {
    if (data) {
      const selectedBlog = JSON.parse(data).find(
        (blog) => blog.id === parseInt(id)
      );
      setBlogDetails(selectedBlog);
    }
  }, [data, id]);

  return (
    <>
      <Navbar />
      <div className="w-full min-h-[100vh] gap-4 xs:py-[80px] md:py-[100px] xs:px-[20px] md:px-[5%]">
        <h2 className="text-[#000000] md:text-[30px] text-[24px] font-bold">
          {blogDetails?.title}
        </h2>
        <img
          src={blogDetails?.image_url}
          alt=""
          className="rounded-[10px] my-4"
        />
        <div className="w-full flex flex-col justify-start gap-2">
          <p className="flex text-[16px] text-[#464646] items-center gap-2">
            By
            <span className="font-bold ">{blogDetails?.author}</span>
          </p>
          <p className="text-[16px] font-normal text-[#FF5E59]">
            Category: {blogDetails?.category || "Mismatch"}
          </p>
          <p className="text-[16px] font-normal text-[#FF5E59]">
            {blogDetails?.date}
          </p>
        </div>
        <p
          style={{ lineHeight: "26px" }}
          className="font-normal text-[#464646] text-[16px] mt-2"
        >
          {blogDetails?.description}
        </p>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetails;
