/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useRef, useState, useContext } from "react";
import "../style/JobInput.css";
import ReactMarkdown from "react-markdown";
import JobInput from "../components/JobInput";
import GlobalContext from "../context/GlobalContext";
import { avatars_list } from "../static-data/AvaratsList";
import UserAvatar from "../assets/images/UserAvatar.png";
import Navbar from "../components/shared/Navbar";
import Icon from "../assets/images/usericon.svg";
import Footer from "../components/shared/Footer";

const JobSearch = () => {
  const webSocket = useRef(null);
  const [inputQuery, setInputQuery] = useState(null);
  const [chatData, setChatData] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [debugMessage, setDebugMessage] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;

  const messageEndRef = useRef(null);
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end"
    });
  }, [chatData, debugMessage]);
  const { userInfo } = useContext(GlobalContext);
  console.log({ userInfo });
  return (
    <div id="jobsearch" className=" lg:h-[85vh] 3xl:h-[80vh] h-[100vh] p-0 m-0">
      <Navbar />
      <section className="heroBG pt-[20px] w-full   flex flex-col justify-start gap-[40px] items-center relative mt-[100px]">
        {chatData.length === 0 ? (
          <div></div>
        ) : (
          <div className=" chatMain xs:min-h-[80%] 3xl:min-h-[78vh]  lg:min-h-[70vh] px-4 xs:w-[100%] lg:w-[60%] absolute  ">
            <div className=" w-[100%] h-[400px]">
              <div className="conversation-box w-[100%] p-1 rounded-[5px] mt-[10px] ">
                {chatData?.map((data, chatIndex) => (
                  <div key={chatIndex}>
                    {data.role === "user" && (
                      <div className="w-full h-auto flex flex-col items-end mt-2 py-2 lg:pr-[3%] xs:pr-[3%]">
                        <div className=" w-auto max-w-fit md:min-w-[30%] 2xl:min-w-[20%] xs:min-w-[30%] flex items-center relative">
                          {userInfo?.profile_pic && (
                            <img
                              src={`${BASE_URL}${userInfo.profile_pic}`}
                              alt=""
                              className="rounded-full border-3 border-white absolute lg:top-[-30px] lg:right-[-30px] xs:top-[-10px] xs:right-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                            />
                          )}
                          {userInfo?.avatar_url && (
                            <img
                              src={`${
                                avatars_list[userInfo?.avatar_url].avatar
                              }`}
                              alt=""
                              className="rounded-full border-3 border-white absolute lg:top-[-30px] lg:right-[-30px] xs:top-[-10px] xs:right-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                            />
                          )}
                          {!userInfo?.profile_pic && !userInfo?.avatar_url && (
                            <img
                              src={UserAvatar}
                              alt=""
                              className="rounded-full border-3 border-white absolute lg:top-[-30px] lg:right-[-30px] xs:top-[-10px] xs:right-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                            />
                          )}
                          <p
                            className="w-full bg-opacity-[10%] py-4 px-6  lg:text-base xs:text-[10px] text-[#4E4949] md:rounded-searchInput rounded-searchmobile"
                            style={{
                              background: "rgba(255, 94, 89, 0.1)"
                            }}
                          >
                            {data.content}
                          </p>
                        </div>
                      </div>
                    )}

                    {data.role === "ai" ? (
                      <div className="lg:w-full h-auto w-full flex flex-col 2xl:justify-center py-6 lg:pl-[3%] xs:pl-[3%]">
                        <div className="w-[80%] max-w-fit min-w-[50%] h-auto py-4 flex flex-col items-center relative md:rounded-searchInput rounded-searchmobile  bg-[#C6A4FF] bg-opacity-[10%]">
                          <img
                            src={Icon}
                            alt=""
                            className="rounded-full border-4 border-white absolute lg:top-[-30px] lg:left-[-30px] xs:top-[-10px] xs:left-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                          />
                          {data?.content
                            ?.split("\n")
                            .map((paragraph, index) => (
                              <div
                                key={index}
                                className=" w-full py-4 px-6  lg:text-base xs:text-[10px] text-[#4E4949] "
                              >
                                <ReactMarkdown
                                  components={{
                                    a: ({ node, ...props }) => (
                                      <a
                                        style={{ color: "#C6A4FF" }}
                                        className="underline"
                                        {...props}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {props.children}
                                      </a>
                                    )
                                  }}
                                >
                                  {paragraph}
                                </ReactMarkdown>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
                {chatLoading && <div class="custom-loader"> </div>}
                <div ref={messageEndRef} />

                {debugMessage && (
                  <div className="receiver-div flex">
                    <div className="receiver-message lg:w-[85%] xs:w-[80%]  px-4 py-1  rounded w-full bg-[#C6A4FF] bg-opacity-[10%]">
                      {debugMessage.split("\n").map((paragraph, index) => (
                        <p
                          key={index}
                          className="mb-3 text-base  break-words target:blank "
                        >
                          <ReactMarkdown>{paragraph}</ReactMarkdown>
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <JobInput
          inputQuery={inputQuery}
          setInputQuery={setInputQuery}
          setChatData={setChatData}
          setChatLoading={setChatLoading}
          setErrorMessage={setErrorMessage}
          setBtnLoading={setBtnLoading}
          btnLoading={btnLoading}
          setDebugMessage={setDebugMessage}
          webSocket={webSocket}
        />
      </section>
    </div>
  );
};

export default JobSearch;
