import React, { useContext, useEffect, useState } from "react";
import CVLab from "../../assets/images/cvlab_nav.png";
import Crewdog from "../../assets/images/crewdog_nav.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "../../App.css";
import Menu from "../ProfileMenu";
import MobileMenu from "../MobileMenu";
import GlobalContext from "../../context/GlobalContext";
import UploadCv from "../modals/UploadCv";
import Cookies from "js-cookie";
import { OauthSender } from "react-oauth-flow";
import axios from "axios";
import useSSOCrewdog from "../../hooks/useSSOCrewdog";

const Navbar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [uploadCVModal, setUploadCVModal] = useState(false);
  const { setActiveButton } = useContext(GlobalContext);
  const token = localStorage.getItem("token") || Cookies.get("token");
  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL_SSO;
  const SSO_Client_id = process.env.REACT_APP_CLIENT_ID_SSO;
  const { loginToCrewdog } = useSSOCrewdog();

  const goToChat = () => {
    if (token) {
      navigate("/chat");
    } else {
      navigate("/login");
    }
  };
  const [fixed, setFixed] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    });
  }, []);

  return (
    <>
      {/* Web Navbar */}
      <div
        className={`w-full lg:flex xs:hidden justify-between items-center lg:text-[10px] xl:text-xs 2xl:text-base ${
          fixed ? "navbar fixer" : "navbar"
        }`}
        style={{
          background:
            location?.pathname === "/"
              ? fixed
                ? "white"
                : "transparent"
              : "white",
          borderBottom:
            location?.pathname === "/"
              ? fixed
                ? "1px solid rgba(128, 128, 128, 0.137)"
                : "none"
              : "1px solid rgba(128, 128, 128, 0.137)",
        }}
      >
        <div className="w-[33.33%] flex items-center justify-start gap-2">
          {token ? (
            <img
              src={
                location?.pathname === "/"
                  ? fixed
                    ? "images/crewdog_black.svg"
                    : "images/crewdog.svg"
                  : Crewdog
              }
              alt=""
              className="w-[131px] h-[45px] cursor-pointer"
              onClick={loginToCrewdog}
            />
          ) : (
            <a
              href="https://beta.crewdog.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={
                  location?.pathname === "/"
                    ? fixed
                      ? "images/crewdog_black.svg"
                      : "images/crewdog.svg"
                    : Crewdog
                }
                alt=""
                className="w-[131px] h-[45px] cursor-pointer"
              />
            </a>
          )}

          <img src={"images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img
              src={
                location?.pathname === "/"
                  ? fixed
                    ? "images/cvlab_black.svg"
                    : "images/cvlab_black.svg"
                  : CVLab
              }
              alt=""
              className="w-[130px] h-[45px]"
            />
          </Link>
        </div>
        {token ? (
          <div className="flex items-center justify-center w-[33.33%] ">
            <div className="flex gap-10 ">
              <NavLink
                to="/jobchat"
                className="nav-link2"
                style={{
                  color:
                    location?.pathname === "/"
                      ? fixed
                        ? "#4e4949"
                        : "white"
                      : "#4e4949",
                }}
              >
                Job Chat
              </NavLink>
              <NavLink
                to="/chat"
                className="nav-link2"
                style={{
                  color:
                    location?.pathname === "/"
                      ? fixed
                        ? "#4e4949"
                        : "white"
                      : "#4e4949",
                }}
              >
                Write my CV
              </NavLink>
              <div
                // to="/saved"
                className="nav-link2 cursor-pointer"
                onClick={() => (
                  navigate("/saved"), setActiveButton("templates")
                )}
                style={{
                  color:
                    location?.pathname === "/"
                      ? fixed
                        ? "#4e4949"
                        : "white"
                      : "#4e4949",
                  borderBottom:
                    location?.pathname === "/saved" ? "1px solid #000" : "none",
                  fontWeight: location?.pathname === "/saved" ? "500" : "400",
                }}
              >
                Templates
              </div>
              <NavLink
                to="/blogs"
                className="nav-link2"
                style={{
                  color:
                    location?.pathname === "/"
                      ? fixed
                        ? "#4e4949"
                        : "white"
                      : "#4e4949",
                }}
              >
                Blogs
              </NavLink>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="w-[33.33%] flex items-center justify-end gap-4">
          {token && (
            <>
              {location?.pathname === "/chat" ? (
                <></>
              ) : (
                <button
                  onClick={goToChat}
                  className="w-auto px-2 h-[35px] bg-[#FF5E59] rounded-[5px] text-white outline-none border-none"
                >
                  Write my CV
                </button>
              )}
            </>
          )}

          {token ? (
            <Menu />
          ) : (
            <OauthSender
              clientId={SSO_Client_id}
              authorizeUrl={`${SSOUrl}/authorize`}
              redirectUri={redirectUrl}
              render={({ url }) => (
                <a
                  href={url}
                  className="w-auto px-4 h-[35px] font-medium text-center flex justify-center items-center m-0 no-underline border border-[#FFF] rounded-[5px] text-[#FFF] outline-none bg-[#FF5E59] "
                >
                  Login
                </a>
              )}
            />
          )}
        </div>
      </div>

      {/* Mobile Navbar */}
      <div
        className={`w-full justify-between items-center xs:flex lg:hidden ${
          fixed ? "navbar fixer" : "navbar"
        }`}
        style={{
          background:
            location?.pathname === "/"
              ? fixed
                ? "white"
                : "transparent"
              : "white",
          borderBottom:
            location?.pathname === "/"
              ? fixed
                ? "1px solid rgba(128, 128, 128, 0.137)"
                : "none"
              : "1px solid rgba(128, 128, 128, 0.137)",
        }}
      >
        <div className="w-[33.33%] h-full flex items-center">
          <MobileMenu />
        </div>
        <div className="w-[43.33%] h-full flex items-center gap-2">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={
                location?.pathname === "/"
                  ? fixed
                    ? "images/crewdog_black.svg"
                    : "images/crewdog.svg"
                  : "images/crewdog_black.svg"
              }
              alt=""
              className=""
            />
          </a>
          <img src={"images/divider_mobile.svg"} alt="" className="" />
          <Link to="/">
            <img
              src={
                location?.pathname === "/"
                  ? fixed
                    ? "images/cvlab_black.svg"
                    : "images/cvlab.svg"
                  : "images/cvlab_black.svg"
              }
              alt=""
              className=""
            />
          </Link>
        </div>
        <div className="w-[33.33%] h-full flex items-center justify-end gap-2">
          {token ? (
            <>
              <Menu />
            </>
          ) : (
            <OauthSender
              clientId={SSO_Client_id}
              authorizeUrl={`${SSOUrl}/authorize`}
              redirectUri={redirectUrl}
              render={({ url }) => (
                <a
                  href={url}
                  className="w-auto px-4 h-[35px] font-medium text-center flex justify-center items-center m-0 no-underline border border-[#FFF] rounded-[5px] text-[#FFF] outline-none bg-[#FF5E59] "
                >
                  Login
                </a>
              )}
            />
          )}
        </div>
      </div>
      <UploadCv
        isOpenModal={uploadCVModal}
        setIsOpenModal={setUploadCVModal}
        setAlreadyUploadedCV={props.setAlreadyUploadedCV}
      />
    </>
  );
};

export default Navbar;
