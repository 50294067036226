import React, { useContext } from "react";
import FooterLogo from "../../assets/images/footerlogo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import Cookies from "js-cookie";
import { OauthSender } from "react-oauth-flow";

const Footer = () => {
  const token = localStorage.getItem("token") || Cookies.get("token");

  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL_SSO;
  const SSO_Client_id = process.env.REACT_APP_CLIENT_ID_SSO;
  const { setActiveButton } = useContext(GlobalContext);
  const location = useLocation();
  const currentPageUrl = location.pathname;
  const navigate = useNavigate();
  const handleReload = () => {
    if (currentPageUrl === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <div className="bg-[#645086] w-full h-auto flex flex-col justify-around lg:px-14 px-3 py-4 ">
        <div className="w-full flex lg:flex-row flex-col justify-between items-center mb-3">
          <div className="flex flex-col items-center">
            <img src={FooterLogo} alt="" className="w-[170px] lg:w-[275px]" />
            <span className="font-normal lg:text-2xl xs:text-[13px] text-white ">
              The Best AI CV Writing Assistant
            </span>
          </div>
          {token ? (
            <div className="flex flex-col gap-2 text-white">
              <div onClick={handleReload} className="font-bold cursor-pointer">
                Home
              </div>
              <Link to="/profile" className="font-bold">
                Profile
              </Link>
              <Link
                to="/saved"
                className="font-bold"
                onClick={() => setActiveButton("my-cvs")}
              >
                Saved
              </Link>
              <Link
                to="/blogs"
                className="font-bold"
                onClick={() => setActiveButton("my-cvs")}
              >
                Blogs
              </Link>
            </div>
          ) : (
            <OauthSender
              clientId={SSO_Client_id}
              authorizeUrl={`${SSOUrl}/authorize`}
              redirectUri={redirectUrl}
              render={({ url }) => (
                <a href={url}>
                  <div className="flex flex-col gap-2 text-white">
                    <div className="font-bold cursor-pointer">Home</div>
                    <div className="font-bold">Profile</div>
                    <div className="font-bold">Saved</div>
                    <div className="font-bold">Blogs</div>
                  </div>
                </a>
              )}
            />
          )}
        </div>
        <div className="border border-dotted my-2"></div>
        <div className="lg:flex w-full justify-between  xs:hidden">
          <span className="text-white text-[13px]">
            CrewDog Ltd Registered In England 14167884
          </span>
          <div className="flex items-center justify-center gap-2 text-white">
            <h2 className="text-[13px]"> &#169; Copyright 2024</h2>
          </div>
          <div className="flex gap-5 text-white">
            <Link to="/terms-condition" className="underline text-[13px]">
              T&Cs
            </Link>
            <Link to="/cookies" className="underline text-[13px]">
              Cookies Policy
            </Link>
            <Link to="/privacy-policy" className="underline text-[13px]">
              Privacy Policy
            </Link>
            <Link to="/gdpr" className="underline text-[13px]">
              GDPR
            </Link>
          </div>
        </div>
        {/* **********MOBILE************ */}
        <div className="lg:hidden w-full flex-col  xs:flex gap-2 py-2">
          <div className="flex items-center justify-center gap-5 text-white">
            <Link to="/terms-condition" className="underline">
              T&Cs
            </Link>
            <Link to="/cookies" className="underline">
              Cookies Policy
            </Link>
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
            <Link to="/gdpr" className="underline">
              GDPR
            </Link>
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <span className="text-white text-[10px]">
              CrewDog Ltd Registered In England 14167884
            </span>
            <div className="flex items-center">
              <h2 className="text-white  text-[10px]">&#169; Copyright 2024</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
