import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const useRegister = () => {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const registerUser = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/accounts/api/signup/`, payload)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          text: "You have successfully registered",
          customClass: {
            confirmButton: "custom-green-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Navigate("/login");
          }
        });
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);

        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          customClass: {
            confirmButton: "custom-red-button",
          },
        }).then((result) => {
          if (result.isConfirmed && error.response.status === 403) {
            Navigate("/forgotpassword");
          }
        });
      });
  };

  return { loading, registerUser };
};
export default useRegister;
