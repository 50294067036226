/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ButtonLoader2 } from "../../components/shared/ButtonLoader";
import useVerifyOtp from "../../hooks/useVerifyOtp";
import { Link, useParams } from "react-router-dom";
import Crewdog from "../../assets/images/crewdog_nav.png";
import CV_lab from "../../assets/images/cvlab_nav.png";
import Cookies from "js-cookie";
import userForgotPassword from "../../hooks/useForgotPassword";
import Swal from "sweetalert2";

const Verify_OTP = () => {
  const { email } = useParams();
  const { sendEmail } = userForgotPassword();
  const { loading, verifyOTP } = useVerifyOtp();
  const [checkOTP, setCheckOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(120);
  const [formattedTime, setFormattedTime] = useState("02:00");
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  const userEmail = Cookies.get("userEmail");
  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        setFormattedTime(
          `${minutes.toString().padStart(2, "0")}:${remainingSeconds
            .toString()
            .padStart(2, "0")}`
        );
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  const handleVerify = () => {
    if (otp.length === 4) {
      verifyOTP({ email: email, otp: otp });
    } else {
      setCheckOTP(true);
    }
  };
  const handleResend = () => {
    sendEmail({
      email: userEmail,
    });
    setSeconds(120);
    setFormattedTime("02:00");
    Swal.fire({
      icon: "success",
      text: "Otp is sent to your email address",
      customClass: {
        confirmButton: "custom-green-button",
      },
    });
  };

  return (
    <>
      <div className="flex flex-col justify-center w-full h-screen items-center">
        <div className="fixed top-[10px] left-[10px] lg:flex xs:hidden flex items-center justify-start gap-2 ">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={"/images/crewdog_black.svg"} alt="" className="" />
          </a>
          <img src={"/images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img src={"/images/cvlab_black.svg"} alt="" className="" />
          </Link>
        </div>
        <div className="lg:hidden xs:flex justify-center flex items-center gap-2 h-[25vh]">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={"/images/crewdog_black.svg"} alt="" className="" />
          </a>
          <img src={"/images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img src={"/images/cvlab_black.svg"} alt="" className="" />
          </Link>
        </div>

        <div className="lg:w-[40%] 2xl:w-[35%] 3xl:w-[25%] w-full xs:h-[75vh] h-auto  flex flex-col justify-center items-center box-border border border-[#FFEFEE] bg-[#fcf4f4] p-forgot rounded-[45px] px-5 xs:pb-2 lg:pb-8 xs:pt-12 lg:pt-16 lg:mt-0 xs:mt-4">
          <h1 className="text-[#263238] lg:text-[44px] text-2xl font-semibold">
            Enter 4 digit code
          </h1>

          <span className="text-[#95969D] text-[18px] font-normal my-4 text-center w-full">
            Enter the 4 digit code that you received on your email.
          </span>
          <OtpInput length={4} setOtp={setOtp} setCheckOTP={setCheckOTP} />
          {checkOTP && (
            <p className="font-[400] text-[12px] 2xl:text-[14px] text-red-600 pt-3 ">
              * Enter the OTP
            </p>
          )}

          <buuton
            onClick={handleVerify}
            className="text-white cursor-pointer bg-[#FF5E59] lg:w-[80%] w-full h-[40px] text-center text-[20px] flex justify-center items-center rounded-[5px] mt-8"
          >
            {loading ? <ButtonLoader2 /> : " Verify"}
          </buuton>
          <div className="py-4 gap-1 flex">
            <span className="text-[#95969D]  text-[14px] font-medium">
              If you didn’t receive code?
            </span>
            {seconds === 0 ? (
              <button
                onClick={handleResend}
                className="text-[#FF5E59] text-[14px] font-normal underline"
              >
                Resend
              </button>
            ) : (
              <div className="text-[#FF5E59] text-sm font-normal">
                {formattedTime}
              </div>
            )}
          </div>

          <div className="flex justify-center items-center gap-1 lg:mt-24 xs:mt-16">
            <span className="text-[#95969D] text-sm font-medium">Back to?</span>
            <Link
              to="/login"
              className="text-[#FF5E59] text-sm font-normal underline"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify_OTP;

function OtpInput({ length, setOtp, setCheckOTP }) {
  const inputs = Array.from({ length }, (_, index) => React.createRef());

  const handleInputChange = (event, index) => {
    const inputElement = event.target;
    const nextInput = inputs[index + 1]?.current;

    if (inputElement.value !== "" && nextInput) {
      nextInput.focus();
    }

    const otpValue = inputs.reduce(
      (acc, input) => acc + input.current.value,
      ""
    );
    setOtp(otpValue);
    setCheckOTP(false);
  };

  const handleInputKeyDown = (event, index) => {
    const inputElement = event.target;
    const previousInput = inputs[index - 1]?.current;

    if (
      event.key === "Backspace" &&
      inputElement.value === "" &&
      previousInput
    ) {
      previousInput.focus();
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    setCheckOTP(false);
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    const pastedCharacters = pastedData.split("");
    for (let i = 0; i < length; i++) {
      if (i < pastedCharacters.length) {
        inputs[i].current.value = pastedCharacters[i];
      } else {
        inputs[i].current.value = "";
      }
    }
    const otpValue = inputs.reduce(
      (acc, input) => acc + input.current.value,
      ""
    );
    setOtp(otpValue);
  };

  return (
    <div className="flex items-center justify-center gap-3">
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type="text"
          placeholder="-"
          maxLength="1"
          pattern="[0-9]"
          inputMode="numeric"
          ref={inputs[index]}
          onChange={(e) => handleInputChange(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          onKeyDown={(e) => handleInputKeyDown(e, index)}
          className="md:w-[60px] md:h-[60px] xs:w-[44px] xs:h-[44px] 2xl:w-[80px] 2xl:h-[80px] bg-[#fffff] text-center  outline-none rounded-xl  text-lg border border-[#DFDFDF] text-[#95969D]"
        />
      ))}
    </div>
  );
}
