import React, { Fragment, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { GoPlus } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useState } from "react";

function Skills(props) {
  const cancelButtonRef = useRef(null);
  const { data, onSave } = props;
  const [cvData, setCvData] = useState(data);
  const [validationErrors, setValidationErrors] = useState([]);
  useEffect(() => {
    setCvData(data);
  }, [data]);

  const handleInputChange = (index, field, value) => {
    setValidationErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = { ...updatedErrors[index], [field]: null };
      return updatedErrors;
    });

    setCvData((prevState) => {
      const updatedSkill = [...prevState.skills];
      updatedSkill[index] = {
        ...updatedSkill[index],
        [field]: value,
      };
      return {
        ...prevState,
        skills: updatedSkill,
      };
    });
  };

  const addSkill = () => {
    setCvData((prevState) => ({
      ...prevState,
      skills: [...prevState.skills, {}],
    }));
  };

  const removeSkill = (index) => {
    setCvData((prevState) => {
      if (prevState.skills.length === 1) {
        return prevState;
      }

      const updatedSkill = [...prevState.skills];
      updatedSkill.splice(index, 1);
      return {
        ...prevState,
        skills: updatedSkill,
      };
    });
  };

  const validateSkills = () => {
    const errors = cvData.skills.map((skill) => {
      return {
        name:
          !skill.name || skill.name.trim() === ""
            ? "skill name is required"
            : null,
      };
    });

    setValidationErrors(errors);

    return errors.every((error) =>
      Object.values(error).every((value) => value === null)
    );
  };

  const handleSubmit = () => {
    if (validateSkills()) {
      onSave(cvData.skills);
    }
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[999999]"
        initialFocus={cancelButtonRef}
        onClose={props.setIsOpenModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[500px] py-4 px-8 ">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={() => props.setIsOpenModal(false)} />
                </div>

                <div className="flex flex-col w-full">
                  <h1 className="text-[#263238] font-medium text-[30px]">
                    Skills
                  </h1>
                  <div className="max-h-[300px] overflow-y-scroll pe-2 mt-4">
                    <div className="w-full h-auto border border-[#EBEBEB] rounded-[20px] py-2 px-4 gap-4 flex flex-col mb-4">
                      {cvData?.skills.map((data, index) => (
                        <form className="flex flex-col w-full">
                          <div className="w-full mb-2">
                            <label>Skill name</label>
                            <div className="flex items-center gap-2">
                              <input
                                type="text"
                                placeholder="Enter skill name"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.name || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                              <RiDeleteBin6Line
                                onClick={() => removeSkill(index)}
                                className="cursor-pointer text-[20px]"
                              />
                            </div>
                            {validationErrors[index]?.name && (
                              <p className="text-red-500 text-sm mt-1">
                                {validationErrors[index].name}
                              </p>
                            )}
                          </div>
                        </form>
                      ))}
                    </div>
                  </div>
                  <div
                    onClick={addSkill}
                    className="flex items-center justify-center w-full border border-[#FF5E59] text-[#FF5E59] py-[10px] rounded-[5px] gap-1 mt-4 cursor-pointer"
                  >
                    <GoPlus className="text-[25px]" />
                    <button className="text-[16px] font-normal ">
                      Add Skill
                    </button>
                  </div>
                  <div className="flex justify-end w-full gap-4 py-4 ">
                    <button
                      onClick={() => props.setIsOpenModal(false)}
                      className="bg-[#4E494933] bg-opacity-[60%] text-[black] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="bg-[#FF5E59]  text-[#fff] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Skills;
