import React, { Fragment, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { useState } from "react";
import { GoPlus } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../style/Editor.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function Education(props) {
  const cancelButtonRef = useRef(null);
  const { data, onSave } = props;
  const [cvData, setCvData] = useState(data);
  const [validationErrors, setValidationErrors] = useState([]);
  useEffect(() => {
    setCvData(data);
  }, [data]);

  const [educationVisibility, setEducationVisibility] = useState(
    cvData?.education?.map(() => true)
  );

  const handleInputChange = (index, field, value) => {
    setValidationErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = { ...updatedErrors[index], [field]: null };
      return updatedErrors;
    });

    setCvData((prevState) => {
      const updatedEducation = [...prevState.education];
      updatedEducation[index] = {
        ...updatedEducation[index],
        [field]: value,
      };
      return {
        ...prevState,
        education: updatedEducation,
      };
    });
  };

  const addEducation = () => {
    setCvData((prevState) => ({
      ...prevState,
      education: [...prevState.education, {}],
    }));
  };

  const removeEducation = (index) => {
    setCvData((prevState) => {
      if (prevState.education.length === 1) {
        return prevState;
      }

      const updatedEducation = [...prevState.education];
      updatedEducation.splice(index, 1);
      return {
        ...prevState,
        education: updatedEducation,
      };
    });
  };

  const handleArrowClick = (index) => {
    setEducationVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };
  const [editorStates, setEditorStates] = useState(() =>
    cvData?.education.map((education) => education.description || "")
  );
  const onEditorStateChange = (index, newEditorState) => {
    setValidationErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = { ...updatedErrors[index], description: null };
      return updatedErrors;
    });

    setEditorStates((prevEditorStates) => {
      const updatedEditorStates = [...prevEditorStates];
      updatedEditorStates[index] = newEditorState;

      setCvData((prevState) => {
        const updatedEducation = [...prevState.education];
        updatedEducation[index] = {
          ...updatedEducation[index],
          description: newEditorState,
        };
        return {
          ...prevState,
          education: updatedEducation,
        };
      });

      return updatedEditorStates;
    });
  };
  const validateEducation = () => {
    const errors = cvData?.education.map((education) => {
      return {
        institute_name: !education.institute_name
          ? "Institution name is required"
          : null,
        field_of_study: !education.field_of_study
          ? "Field of study is required"
          : null,
        // city: !education.city ? "City is required" : null,
        // country: !education.country ? "Country is required" : null,
        // start_date: !education.start_date ? "Start date is required" : null,
        description: !education.description ? "Description is required" : null,
      };
    });

    cvData?.education.forEach((education, index) => {
      if (!education.institute_name?.trim()) {
        errors[index].institute_name = "Company name cannot be empty";
      }
      if (!education.field_of_study?.trim()) {
        errors[index].field_of_study = "Job title cannot be empty";
      }
      // if (!education.city?.trim()) {
      //   errors[index].city = "City cannot be empty";
      // }
      // if (!education.country?.trim()) {
      //   errors[index].country = "Country cannot be empty";
      // }
      // if (!education.start_date?.trim()) {
      //   errors[index].start_date = "Start date cannot be empty";
      // }
      if (!education.description?.trim()) {
        errors[index].description = "Description cannot be empty";
      }
    });

    setValidationErrors(errors);

    return errors.every((error) =>
      Object.values(error).every((value) => value === null)
    );
  };
  const handleSubmit = () => {
    if (validateEducation()) {
      onSave(cvData?.education);
    }
  };
  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999999999]"
        initialFocus={cancelButtonRef}
        onClose={props.setIsOpenModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[500px] py-4 px-8 ">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={() => props.setIsOpenModal(false)} />
                </div>

                <div className="flex flex-col w-full">
                  <h1 className="text-[#263238] font-medium text-[30px]">
                    Education
                  </h1>
                  <div className="max-h-[300px] overflow-y-scroll pe-2 mt-4">
                    {cvData?.education?.map((data, index) => (
                      <div className="w-full h-auto border border-[#EBEBEB] rounded-[20px] py-2 px-4 gap-4 flex flex-col mb-4">
                        <div className="w-full flex justify-between items-end">
                          <span>Education {index + 1}</span>
                          <div className="flex justify-center items-center gap-2">
                            <RiDeleteBin6Line
                              onClick={() => removeEducation(index)}
                              className="cursor-pointer text-[15px]"
                            />
                            <div onClick={() => handleArrowClick(index)}>
                              {educationVisibility[index] ? (
                                <MdKeyboardArrowDown className="text-[20px] cursor-pointer" />
                              ) : (
                                <MdKeyboardArrowUp className="text-[20px] cursor-pointer" />
                              )}
                            </div>
                          </div>
                        </div>
                        {!educationVisibility[index] && (
                          <form className="flex flex-col w-full">
                            <div className="w-full mb-2">
                              <label>Institution name</label>
                              <input
                                type="text"
                                placeholder="Enter institution name"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.institute_name || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "institute_name",
                                    e.target.value
                                  )
                                }
                              />
                              {validationErrors[index]?.institute_name && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].institute_name}
                                </p>
                              )}
                            </div>
                            <div className="w-full mb-2">
                              <label>Field of study</label>
                              <input
                                type="text"
                                placeholder="Enter field of study"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.field_of_study || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "field_of_study",
                                    e.target.value
                                  )
                                }
                              />
                              {validationErrors[index]?.field_of_study && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].field_of_study}
                                </p>
                              )}
                            </div>
                            <div className="w-full mb-2">
                              <label>City</label>
                              <input
                                type="text"
                                placeholder="Enter city"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.city}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "city",
                                    e.target.value
                                  )
                                }
                              />
                              {validationErrors[index]?.city && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].city}
                                </p>
                              )}
                            </div>
                            <div className="w-full mb-2">
                              <label>Country</label>
                              <input
                                type="text"
                                placeholder="Enter country"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.country || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "country",
                                    e.target.value
                                  )
                                }
                              />
                              {validationErrors[index]?.country && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].country}
                                </p>
                              )}
                            </div>
                            <div className="w-full mb-2">
                              <label>Time period</label>
                              <div className="flex items-center gap-2">
                                <div>
                                  <input
                                    type="date"
                                    className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                    value={data.start_date || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "start_date",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {validationErrors[index]?.start_date && (
                                    <p className="text-red-500 text-sm mt-1">
                                      {validationErrors[index].start_date}
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <input
                                    type="date"
                                    className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                    value={data.end_date || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "end_date",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="w-full mb-2 ">
                              <label>Description</label>
                              <div className="border border-[#EBEBEB] px-2 py-1 rounded-[10px]">
                                <ReactQuill
                                  value={editorStates[index]}
                                  onChange={(value) =>
                                    onEditorStateChange(index, value)
                                  }
                                  modules={{
                                    toolbar: [
                                      ["bold", "italic", "underline", "strike"],
                                      [{ list: "bullet" }],
                                      [{ align: [] }],
                                      [{ color: [] }, { background: [] }],
                                    ],
                                    clipboard: {
                                      matchVisual: false,
                                    },
                                  }}
                                  formats={[
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "list",
                                    "bullet",
                                    "color",
                                    "background",
                                    "size",
                                    "align",
                                  ]}
                                />
                              </div>
                              {validationErrors[index]?.description && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].description}
                                </p>
                              )}
                            </div>
                          </form>
                        )}
                      </div>
                    ))}
                  </div>
                  <div
                    onClick={addEducation}
                    className="flex items-center justify-center w-full border border-[#FF5E59] text-[#FF5E59] py-[10px] rounded-[5px] gap-1 mt-4 cursor-pointer"
                  >
                    <GoPlus className="text-[25px]" />
                    <button className="text-[16px] font-normal ">
                      Add Education
                    </button>
                  </div>
                  <div className="flex justify-end w-full gap-4 py-4 ">
                    <button
                      onClick={() => props.setIsOpenModal(false)}
                      className="bg-[#4E494933] bg-opacity-[60%] text-[black] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="bg-[#FF5E59]  text-[#fff] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Education;
