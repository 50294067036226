import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import pdf1 from "../assets/images/templates/temp1.png";
import pdf2 from "../assets/images/templates/temp2.png";
import pdf3 from "../assets/images/templates/temp3.png";
import pdf4 from "../assets/images/templates/temp4.png";
import pdf5 from "../assets/images/templates/temp5.png";
import Active from "../assets/images/activelogo2.png";

import { HiArrowRight } from "react-icons/hi";
import "swiper/css/pagination";
import "swiper/css";
import { useNavigate } from "react-router-dom";

const CVSlider = () => {
  const swiperRef = useRef(null);
  const Navigate = useNavigate();

  const template = () => {
    Navigate("/saved");
  };

  const slider = [
    {
      id: 1,
      img: pdf1,
    },
    {
      img: pdf2,
      id: 2,
    },
    {
      id: 3,
      img: pdf3,
    },
    {
      img: pdf4,
      id: 4,
    },
    {
      id: 5,
      img: pdf5,
    },
    {
      img: pdf2,
      id: 6,
    },
    {
      img: pdf4,
      id: 7,
    },
    {
      img: pdf3,
      id: 8,
    },
  ];

  return (
    <div className="bg-[#C6A4FF] w-full h-auto lg:px-0 xs:px-2 py-4 flex flex-col items-center">
      <h1 className="text-white font-bold lg:text-[44px] xs:text-[30px]">
        What's your style?
      </h1>
      <p className="text-white lg:text-[16px] xs:text-[12px]  text-center">
        Browse through the available styles and choose a specific one by
        clicking on it.
      </p>

      <div className="flex flex-col 3xl:w-[80%] lg:w-[100%] xs:w-[100%] items-center justify-center lg:mt-2 xs:mt-4 lg:px-7 xs:px-0 relative">
        <Swiper
          ref={swiperRef}
          pagination={true}
          modules={[Pagination, Navigation]}
          spaceBetween={20}
          slidesPerView={2}
          centeredSlides={true}
          // loop={true}
          breakpoints={{
            640: {
              width: 640,
              slidesPerView: 1,
              centeredSlides: true,
            },

            768: {
              width: 768,
              slidesPerView: 2,
              centeredSlides: true,
            },
            1500: {
              width: 1500,
              slidesPerView: 4,
              centeredSlides: true,
            },
          }}
        >
          {slider.map((slide, index) => (
            <SwiperSlide key={index}>
              {({ isActive }) => (
                <>
                  <div
                    className="lg:w-[355px] lg:h-[450px] xs:w-[300px] xs:h-[220px] flex  justify-center cursor-pointer rounded-[10px]  overflow-hidden"
                    onClick={() => {
                      swiperRef?.current?.swiper.slideTo(index);
                    }}
                  >
                    <img src={slide.img} alt="" className="w-full h-full" />
                  </div>
                  {isActive && (
                    <>
                      <img
                        src={Active}
                        alt="FDv"
                        className="absolute lg:top-[15px] xs:hidden lg:flex"
                      />
                      <img
                        src={"/images/activeDog_mobile.svg"}
                        alt="FDv"
                        className="absolute xs:top-[0px] lg:hidden xs:flex "
                      />
                    </>
                  )}
                </>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          onClick={template}
          className="w-full flex items-center justify-center"
        >
          <button
            className="py-[8px] px-[25px] justify-center items-center flex gap-3 rounded-[5px] border-[1px] border-[#FF5E59] text-[#FF5E59] lg:text-[20px] xs:text-[14px] font-[500]"
            style={{
              background:
                "linear-gradient(93deg, #FFF 0.91%, rgba(255, 255, 255, 0.60) 99.36%)",
            }}
          >
            <span>View All</span>
            <HiArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CVSlider;
