const Auth = (state, action) => {
  switch (action.type) {
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "ACTIVE_BUTTON":
      return {
        ...state,
        activeButton: action.payload,
      };
    case "SET_SECONDQUESTION":
      return {
        ...state,
        secondQuestion: action.payload,
      };
    case "SET_UPLOAD_CV_DATA":
      return {
        ...state,
        cvDetail: action.payload,
      };
    case "SET_CHAT_DATA":
      return {
        ...state,
        chatData: [...state.chatData, ...action.payload],
      };

    case "SET_CHAT_LOADING":
      return {
        ...state,
        chatLoading: action.payload,
      };
    case "SET_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: action.payload,
      };
    case "SET_INPUT_QUERY":
      return {
        ...state,
        inputQuery: action.payload,
      };
    case "SET_SEND_BTN_LOADING":
      return {
        ...state,
        sendBtnLoading: action.payload,
      };
    case "SET_CHECK_DELETE_STATUS":
      return {
        ...state,
        checkDeleteStatus: action.payload,
      };
    case "SET_SHOW_MAKE_CV_QUESTION":
      return {
        ...state,
        showMakeCVQuestion: action.payload,
      };
    case "CLEAR_CHAT_DATA":
      return {
        ...state,
        chatData: [],
      };
    default:
      return state;
  }
};

export default Auth;
