import mobile from "../assets/icons/mobile.png";
import laptop from "../assets/icons/laptop.png";
import desktop from "../assets/icons/desktop.png";
import tablet from "../assets/icons/tablet.png";

import emoji_1 from "../assets/icons/emoji_1.png";
import emoji_2 from "../assets/icons/emoji_2.png";
import emoji_3 from "../assets/icons/emoji_3.png";
import emoji_4 from "../assets/icons/emoji_4.png";
import emoji_5 from "../assets/icons/emoji_5.png";

export const Devices = [
  { id: 1, name: "Mobile", picture: mobile },
  { id: 2, name: "Laptop", picture: laptop },
  { id: 3, name: "Desktop", picture: desktop },
  { id: 4, name: "Tablet", picture: tablet },
];

export const Browsers = [
  { id: 1, name: "Chrome" },
  { id: 2, name: "Safari" },
  { id: 3, name: "Opera" },
  { id: 4, name: "Firefox" },
  { id: 5, name: "Others" },
];

export const Rating = [
  { id: 1, name: "1", picture: emoji_1 },
  { id: 2, name: "2", picture: emoji_2 },
  { id: 3, name: "3", picture: emoji_3 },
  { id: 4, name: "4", picture: emoji_4 },
  { id: 5, name: "5", picture: emoji_5 },
];
