import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const useLogout = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const logout = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/accounts/logout/`, payload)
      .then((response) => {
        localStorage.clear();
        Cookies.clear();
        setLoading(false);
        window.location.href = "/";
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return { loading, logout };
};

export default useLogout;
