import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({ url, username, setShowPDF }) => {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleDownloadPDF = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const pdfurl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = pdfurl;
      link.setAttribute("download", `${username}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowPDF(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div className="flex items-center justify-center flex-col">
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} renderMode="canvas" />
      </Document>
      <div className="flex justify-between w-[595px] mt-6 items-center">
        <div className="w-[33.33%]">
          <button disabled={pageNumber <= 1} onClick={goToPreviousPage}>
            Previous
          </button>
        </div>
        <p className="w-[33.33%] flex justify-center">
          Page {pageNumber} of {numPages}
        </p>
        <div className="w-[33.33%] flex justify-end">
          <button disabled={pageNumber >= numPages} onClick={goToNextPage}>
            Next
          </button>
        </div>
      </div>
      <div className="flex justify-center w-[595px] mt-6 items-center">
        <button
          className="receipt-modal-download-button bg-[#FF5E59] w-[160px] h-[40px] flex justify-center items-center text-white font-bold rounded"
          onClick={handleDownloadPDF}
        >
          Download as PDF
        </button>
      </div>
    </div>
  );
};

export default PdfViewer;
