import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const useLogin = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const currentPagePath = Cookies.get("current_Page");
  const Login = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/accounts/api/login/`, payload)
      .then((response) => {
        setLoading(false);
        if (!currentPagePath === "/") {
          window.location.href = currentPagePath;
        } else {
          window.location.href = "/chat";
        }
        if (rememberMe) {
          localStorage.setItem("token", response.data.access);
          localStorage.setItem("refresh", response.data.refresh);
          localStorage.setItem("remember_me", rememberMe);
        } else {
          Cookies.set("token", response.data.access);
          Cookies.set("refresh", response.data.refresh);
          Cookies.set("remember_me", rememberMe);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.error,
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, rememberMe, setRememberMe, Login };
};

export default useLogin;
