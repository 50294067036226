import React from "react";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";

function CookiesJS() {
  return (
    <>
      <Navbar />
      <div className=" py-10 lg:px-14 xs:px-7 w-full text-justify">
        <h1 className="font-semibold text-[20px] text-center mt-14 mb-8">
          Cookies
        </h1>
        <p>
          CrewDog uses browser cookies to collect information and enhance the
          user experience on our website. When you visit our site, small files
          known as 'cookies' are placed on your computer. The content of these
          cookies varies depending on the specific features they are used for.
          The use of cookies allows CrewDog to monitor website traffic, remember
          user preferences, and provide a seamless experience across multiple
          web servers.
        </p>
        <h1 className="font-semibold text-[16px] my-4">
          There are several types of cookies that we use for different purposes:
        </h1>
        <div className="px-2">
          <li className="flex">
            <h2 className="font-semibold ">
              User Interface Elements:
              <span className="font-normal">
                We utilise cookies to display or hide user interface elements,
                such as alerts on web pages.
              </span>
            </h2>
          </li>
          <li className="flex my-2">
            <h2 className="font-semibold ">
              Traffic Monitoring:
              <span className="font-normal">
                Cookies are used to identify the areas of our website that are
                being visited by users.
              </span>
            </h2>
          </li>{" "}
          <li className="flex my-2">
            <h2 className="font-semibold ">
              User Identification and Preferences:
              <span className="font-normal">
                These cookies enable us to personalise the website based on
                individual user preferences.
              </span>
            </h2>
          </li>{" "}
          <li className="flex my-2">
            <h2 className="font-semibold ">
              Testing Cookies:
              <span className="font-normal">
                In order to improve our services, we conduct tests that require
                cookies to direct users to new features and track the results.
              </span>
            </h2>
          </li>{" "}
          <li className="flex my-2">
            <h2 className="font-semibold ">
              Temporary Storage:
              <span className="font-normal">
                As we operate multiple servers, temporary data, such as a basket
                of jobs that users may want to apply for later, is stored in
                cookies.
              </span>
            </h2>
          </li>{" "}
          <li className="flex my-2">
            <h2 className="font-semibold ">
              Persistent Storage
              <span className="font-normal">
                : Cookies are utilised to store settings and identifiers that
                allow users to revisit the website and be recognised.
              </span>
            </h2>
          </li>
        </div>
        <p>
          CrewDog also uses third-party cookies to support the services provided
          by external companies. Some of these services require the use of
          cookies to function properly.
        </p>

        <h1 className="font-semibold text-[16px] my-4">
          In addition to the aforementioned types, we also utilise generic
          cookie types, including:
        </h1>
        <div className="px-2">
          <li>User Personalisation Cookies</li>
          <li className="mt-2">Analytics Cookies</li>
          <li className="mt-2">Third-Party Service Cookies</li>
          <li className="mt-2">Advertising Cookies</li>
        </div>
        <p className="mt-2">
          For advertising purposes, we work with third-party advertising
          companies that may use cookies to serve ads based on users' interests.
          These companies do not collect personally identifiable information
          such as name, address, email address, or telephone number. If you
          would like more information about this practice and how to opt-out,
          please refer to the provided link.
        </p>
        <p className="mt-2">
          CrewDog also employs website management cookies, such as session
          cookies and persistent cookies, to effectively manage the website.
        </p>

        <h1 className="font-semibold text-[16px] my-4">
          Furthermore, we have specific cookies that are unique to CrewDog's
          website, including:
        </h1>
        <div className="px-2">
          <li className="flex">
            <h2 className="font-semibold ">
              Feature Experiment Cookies:
              <span className="font-normal">
                These cookies are used during the testing of new features on the
                site, typically in "A/B" tests where a cookie determines whether
                users should see the new feature or not.
              </span>
            </h2>
          </li>
          <li className="flex my-2">
            <h2 className="font-semibold ">
              Referral Cookies:
              <span className="font-normal">
                These cookies help identify incoming traffic from partner
                websites.
              </span>
            </h2>
          </li>
          <li className="flex my-2">
            <h2 className="font-semibold ">
              User Interface Cookies:
              <span className="font-normal">
                These cookies handle user interface elements that need to
                persist between pages and visits. For example, they may record
                whether a user has hidden informational banner messages on
                certain pages.
              </span>
            </h2>
          </li>
        </div>
        <p>
          For more detailed information about CrewDog's cookie policy, please
          visit our official website or contact our support team.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default CookiesJS;
