import { useContext, useState } from "react";
import axios from "axios";
import { templates } from "../static-data/TemplateImg";
import GlobalContext from "../context/GlobalContext";
import Cookies from "js-cookie";

const useGetUserCVList = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [cvList, setCvList] = useState(null);
  const token = localStorage.getItem("token") || Cookies.get("token");
  const { setCheckDeleteStatus } = useContext(GlobalContext);

  const getUserCVList = async (query) => {
    await axios
      .get(`${BASE_URL}/api/mycvs/?name=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (!cvList) {
          setLoading(false);
        }
        setCheckDeleteStatus(false);
        function compareArrays(arr1, arr2) {
          const idMap = new Map();
          arr1?.forEach((obj) => {
            const templateName = obj.template.name;
            if (!idMap.has(templateName)) {
              idMap.set(templateName, [
                { id: obj.cv.id, username: obj.username },
              ]);
            } else {
              idMap
                .get(templateName)
                .push({ id: obj.cv.id, username: obj.username });
            }
          });
          const commonObjects = arr2?.filter((obj) => idMap.has(obj.id));

          return commonObjects
            ?.map((obj) => {
              const templateName = obj.id; // Assuming obj.id represents the template name
              const idsAndUsernames = idMap.get(templateName);

              // Map each ID and username to the object and return the modified object
              return idsAndUsernames.map(({ id, username }) => ({
                ...obj,
                tempId: id,
                username: username,
              }));
            })
            .flat();
        }

        const result = compareArrays(response.data.results, templates);
        setCvList(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return { loading, getUserCVList, cvList };
};

export default useGetUserCVList;
