import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const useNewPassword = () => {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const newPassword = async (payload) => {
    setLoading(true);

    await axios
      .post(`${BASE_URL}/accounts/api/resetPassword/`, payload)
      .then((response) => {
        setLoading(false);
        Cookies.remove("verification_token");
        Cookies.remove("current_Page");
        Cookies.remove("UID");
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          text: "You can login now with your new password",
          customClass: {
            confirmButton: "custom-green-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Navigate("/login");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.response.data.detail,
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
        setLoading(false);
      });
  };

  return { loading, newPassword };
};

export default useNewPassword;
