import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const useUpdateUserCV = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading_2, setLoading] = useState(false);
  const token = localStorage.getItem("token") || Cookies.get("token");

  const updateUserCV = async (payload) => {
    setLoading(true);
    await axios
      .put(`${BASE_URL}/api/updateCV/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Successfully updated",
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.error,
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading_2, updateUserCV };
};

export default useUpdateUserCV;
