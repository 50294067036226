import React from "react";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";

function GDPR() {
  return (
    <>
      <Navbar />
      <div className="  py-10 lg:px-14 xs:px-7 w-full text-justify ">
        <h1 className="font-semibold text-[20px] text-center mt-16 mb-5">
          General Data Protection Regulation (GDPR)
        </h1>
        <p>
          Exceptions are, in most cases, allowed for the GDPR process, which was
          created in 2018 to regulate the conduct of the processing of personal
          data that belongs to EU citizens or residents. The regulations confer
          new responsibilities (for instance, adjusting every processing of
          personal data) and new rights (for example, being able to obtain
          information pertaining to the subject’s personal data, as well as
          omitting violations of and comfortable deletion).
        </p>
        <div className="px-7">
          <h1 className="font-semibold mt-5 text-[16px]">
            1. Crewdog Commitment:
          </h1>
          <div className="px-2">
            <p className="my-1">
              The team at Crewdog is dedicated to adhering to the GDPR. We
              regularly research the latest developments on GDPR requirements
              and amend our company’s policies in accordance. Our business takes
              these new data guidelines into consideration, and makes
              modifications to our products, contracts and policies to bring our
              data security in line with the GDPR.
            </p>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            2. Worldwide Product Conformity
          </h1>
          <div className="px-2">
            <p className="my-1">
              To keep a consistent user experience, Crewdog has chosen to adopt
              the GDPR requirements to our program and support it worldwide. We
              firmly believe that our provision of uniform rules and program
              logic will make all of our customers highly likely to obey GDPR
              rules.
            </p>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            3. You have the right to any information you provide about yourself.
          </h1>
          <div className="px-2">
            <p className="my-1">
              We take the privacy of our users and contacts critically and
              encourage them to contact us directly at any time with any
              questions, comments, or suggestions about privacy. The list
              provided is here as an example of things you notice and would like
              to interact with.
            </p>
            <ul style={{ listStyleType: "lower-alpha" }} className="px-2">
              <li className="my-1">The Right of Access:</li>
              <p className="my-1">
                You can contact Crewdog to request access to your account
                information and obtain a copy of your information that is being
                processed. In the event that you request to know what Personal
                Information is currently being processed, we will offer you the
                following information free of charge:
                <br /> categories of Personal Information processed; recipients
                of Personal Information; the length of time during which
                Personal Information will be stored; your privacy rights; and
                data on technical and organisational measures taken. You can
                submit your requests to hr@crewdog.ai, so please make sure to
                provide all applicable information.
              </p>
              <li className="my-1">The right of rectification:</li>
              <p className="my-1">
                You may be able to update, change or complete any missing or
                incorrect information we learn about you by sending an email to
                hr@crewdog.ai with the pertinent information we need. Please
                note we can revise, replenish or remove any incomplete or
                dubious data, as well as data which is otherwise unsuitable for
                us for our own purposes.
              </p>
              <li className="my-1">The Right of Erasure:</li>
              <p className="my-1">
                At any time, you are able to withdraw your consent to our
                processing of your information. If there is a legitimate
                interest for continuing the processing of Your Information
                (e.g., if it is necessary to comply with our legal obligations,
                resolve disputes, enforce our agreements, etc.), and your
                Information is no longer relevant for the purpose for which it
                was originally collected, we will remove any Personal
                Information that has been processed. Such removal of consent
                will be completed by sending a message to hr@crewdog.ai with
                your relevant info.
              </p>
              <li className="my-1">The Right of Restriction of Processing:</li>
              <p className="my-1">
                You have the right to request that we refrain from processing
                any of your Information if (i) the accuracy of the Information
                is disputed by you; (ii) the processing is unlawful, or (iii)
                once we no longer need the Information. You can submit this
                request by emailing us with the details to hr@crewdog.ai.
              </p>
              <li className="my-1">The Right to Data Portability:</li>
              <p className="my-1">
                You have the exclusive right to request that your personal
                information be sent in a way that is readable by any machine.
                When submitting a request electronically, send the following
                details to hr@crewdog.ai. If you are not pleased with the
                response from us or believe we are collecting or processing your
                information unlawfully, you can file a complaint with the
                applicable authority.
              </p>
            </ul>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">4. Retention</h1>
          <div className="px-2">
            <p className="my-1">
              Your personal information will remain in these databases in a way
              that makes it possible to be identified for the length of time
              that is reasonably necessary for Crewdog’s processing activities
              (Processing Date). Crewdog adheres to the same retention policy
              for all Users and Contacts worldwide regardless of their place of
              residence, and will adhere to the reasonable legal retention
              period, which is 7 years from the processing date. Please be aware
              that we might keep your information for as long as needed to
              complete our Services, as well as to comply with our legal
              obligations, respond to disputes, and enforce our agreements.
            </p>
          </div>

          <h1 className="font-semibold mt-5  text-[16px]">
            Please be advised:
          </h1>
          <div className="px-2">
            <p className="my-1">
              The information that can come to you is retrieved from the
              Internet and various other sources and is public. In some cases,
              you may encounter your details again after request for removal, in
              case they were re-collected over the web, contributed by other
              partners or in other means that do not include access to your
              device. We recommend that you periodically check your details or
              the Services to ensure that your current profile or account
              includes the Information you had chosen to have displayed.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GDPR;
