import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const initialState = {
  userInfo: null,
  chatData: [],
  chatLoading: false,
  errorMessage: null,
  inputQuery: null,
  sendBtnLoading: false,
  cvDetail: null,
  activeButton: "my-cvs",
  secondQuestion: true,
  checkDeleteStatus: false,
  showMakeCVQuestion: false
};

const GlobalContext = createContext(initialState);
export default GlobalContext;

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const setUserInfo = (userInfo) => {
    dispatch({
      type: "SET_USER_INFO",
      payload: userInfo
    });
  };
  const setCVDetail = (cvDetail) => {
    dispatch({
      type: "SET_UPLOAD_CV_DATA",
      payload: cvDetail
    });
  };
  const setActiveButton = (activeButton) => {
    dispatch({
      type: "ACTIVE_BUTTON",
      payload: activeButton
    });
  };
  const setSecondQuestion = (secondQuestion) => {
    dispatch({
      type: "SET_SECONDQUESTION",
      payload: secondQuestion
    });
  };
  const setChatData = (chatData) => {
    dispatch({
      type: "SET_CHAT_DATA",
      payload: chatData
    });
  };
  const setChatLoading = (chatLoading) => {
    dispatch({
      type: "SET_CHAT_LOADING",
      payload: chatLoading
    });
  };
  const setErrorMessage = (errorMessage) => {
    dispatch({
      type: "SET_ERROR_MESSAGE",
      payload: errorMessage
    });
  };
  const setInputQuery = (inputQuery) => {
    dispatch({
      type: "SET_INPUT_QUERY",
      payload: inputQuery
    });
  };
  const setSendBtnLoading = (sendBtnLoading) => {
    dispatch({
      type: "SET_SEND_BTN_LOADING",
      payload: sendBtnLoading
    });
  };
  const setCheckDeleteStatus = (checkDeleteStatus) => {
    dispatch({
      type: "SET_CHECK_DELETE_STATUS",
      payload: checkDeleteStatus
    });
  };
  const setShowMakeCVQuestion = (showMakeCVQuestion) => {
    dispatch({
      type: "SET_SHOW_MAKE_CV_QUESTION",
      payload: showMakeCVQuestion
    });
  };
  const clearChatData = () => {
    dispatch({
      type: "CLEAR_CHAT_DATA"
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        userInfo: state.userInfo,
        setUserInfo,
        chatData: state.chatData,
        setChatData,
        chatLoading: state.chatLoading,
        setChatLoading,
        errorMessage: state.errorMessage,
        setErrorMessage,
        inputQuery: state.inputQuery,
        setInputQuery,
        sendBtnLoading: state.sendBtnLoading,
        setSendBtnLoading,
        cvDetail: state.cvDetail,
        setCVDetail,
        activeButton: state.activeButton,
        setActiveButton,
        secondQuestion: state.secondQuestion,
        setSecondQuestion,
        checkDeleteStatus: state.checkDeleteStatus,
        setCheckDeleteStatus,
        showMakeCVQuestion: state.showMakeCVQuestion,
        setShowMakeCVQuestion,
        clearChatData
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
