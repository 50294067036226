import React, { useState, useEffect } from "react";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";
import StandOut from "../components/StandOut";
import CVSlider from "../components/CVSlider";
import { useNavigate } from "react-router-dom";
import Coach from "../assets/images/coach.png";
import Hunt from "../assets/images/hunt.png";
import Transition from "../assets/images/transition.png";
import Icon from "../assets/images/usericon.svg";
import Conversation from "../assets/images/conversation.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Cookies from "js-cookie";
import { OauthSender } from "react-oauth-flow";

const Homepage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token") || Cookies.get("token");
  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL_SSO;
  const SSO_Client_id = process.env.REACT_APP_CLIENT_ID_SSO;
  return (
    <div>
      <></>
      <Navbar />
      <div className="flex flex-col w-full h-auto justify-center items-center bg-contain bg-no-repeat">
        <div className="home_background flex gap-2 w-full lg:h-[70vh] xl:h-[100vh] sm:h-[35vh] xs:h-[33vh] items-center lg:justify-start xs:justify-center lg:pl-[80px] xs:pl-[0px]">
          <div className="lg:w-[55%] xs:w-[100%] pb-5 flex flex-col lg:items-start xs:items-center lg:justify-center xs:justify-center text-center xs:mt-[30px]">
            <h1 className="font-bold xl:text-[35px] 2xl:text-[40px] lg:text-[30px] text-[#FFF] xs:text-[15px] capitalize lg:text-left xs:text-center xs:mt-[50px] sm:mt-0">
              personalise Your CV for Targeted Job Applications. Your Future
              Awaits
            </h1>
            <span
              className="xl:text-[40px] lg:text-[30px] text-[#FFF] xs:text-[15px] font-bold text-left"
              style={{
                borderleft: "2px solid #9457FB",
                background:
                  "linear-gradient(90deg, #C6A4FF 0%, rgba(255, 94, 89, 0.00) 100%)"
              }}
            >
              Start Crafting Your Success Story!
            </span>
            {token ? (
              <button
                style={{
                  boxShadow: "-3px -3px 20px 0px rgba(150, 190, 231, 0.50)"
                }}
                className=" lg:mt-10 xs:mt-2 text-[#fff] lg:text-[20px] font-[500] xs:text-[15px] lg:py-[12px] xs:py-[6px] px-[19px] border-2 border-[#FFF] bg-[#FF5E59] text-center rounded-[5px]"
                onClick={() => navigate("/chat")}
              >
                Customise your CV
              </button>
            ) : (
              <OauthSender
                clientId={SSO_Client_id}
                authorizeUrl={`${SSOUrl}/authorize`}
                redirectUri={redirectUrl}
                render={({ url }) => (
                  <a
                    href={url}
                    className=" lg:mt-10 xs:mt-2 text-[#fff] lg:text-[20px] font-[500] xs:text-[15px] lg:py-[12px] xs:py-[6px] px-[19px] border-2 border-[#FFF] bg-[#FF5E59] text-center rounded-[5px]"
                  >
                    Customise your CV
                  </a>
                )}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col items-center lg:gap-12 xs:gap-2 w-[100%]">
          <StandOut />
          <ConversationCV />
          <CareerPotential />
          <PowerHouse />
          <CareerTransition />
          <CVSlider />
          <div className="xs:hidden xl:flex ">
            <Customertestimonials />
          </div>
          <div className="xs:flex xl:hidden w-[100%] h-auto">
            <CustomertestimonialsMobile />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;

function ConversationCV() {
  const data = [
    {
      img: Conversation,
      text: "Custom your CV to each individual job application - just add the job title"
    },
    {
      img: Conversation,
      text: "Create your first CV - chat for immediate result"
    },
    {
      img: Conversation,
      text: "Add a Cover Letter to accompany your CV"
    },
    {
      img: Conversation,
      text: "Be interview ready by knowing potential questions before the day!"
    },
    {
      img: Conversation,
      text: "Unlimited Career Advice : How can I… change career, get a promotion, ask for a raise?"
    }
  ];

  const [generatedText, setGeneratedText] = useState("");
  const [displayedText, setDisplayedText] = useState("");
  const typingSpeed = 20;

  const handleGenerateClick = () => {
    const newText =
      "CV Lab is the AI-powered solution for effortless CV customisation and 24/7 career coaching. Our personalised coaching leverages advanced AI technology to offer continuous support, guidance, and strategies for navigating job applications, interviews, professional development, and industry-specific trends. CV Lab's advanced algorithms analyze job descriptions, identify key skills and qualifications, and seamlessly tailor your existing CV to match specific job requirements. Say goodbye to manual CV tweaking for every application – with CV Lab, customisation is automatic, saving you time and ensuring your CV stands out for each opportunity";
    setGeneratedText(newText);
  };

  useEffect(() => {
    const typeText = () => {
      for (let i = 0; i <= generatedText.length; i++) {
        setTimeout(() => {
          setDisplayedText(generatedText.substring(0, i));
        }, i * typingSpeed);
      }
    };

    if (generatedText) {
      typeText();
    }
  }, [generatedText]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = 200;
      const element = document.getElementById("auto-generate-trigger");

      if (
        element &&
        window.scrollY + window.innerHeight > element.offsetTop + offset &&
        !generatedText
      ) {
        handleGenerateClick();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [generatedText]);

  return (
    <>
      <div
        className="flex flex-col gap-3 bg-[#C6A4FF] w-full h-auto lg:px-[60px] xs:px-[20px] py-4 
    lg:relative  "
      >
        <img
          src={"/images/dog-gif.gif"}
          alt=""
          className="lg:w-[200px]  absolute right-8 xl:top-12 lg:top-[13%] lg:flex xs:hidden  "
        />
        <h2 className="text-white font-bold lg:text-[44px] xs:text-[13px] ">
          Conversational CV customiser and careers coach
        </h2>
        {data.map((item, index) => (
          <div className="flex items-center lg:gap-3 xs:gap-2" key={index}>
            <img
              src={item.img}
              alt=""
              className="lg:w-[17px] xs:w-[6px] lg:h-[17px] xs:h-[6px]"
            />
            <p
              className={`text-white lg:text-[16px] xs:text-[10px] ${
                index === data.length - 1 ? "w-[80%] " : "w-[100%] "
              }`}
            >
              {item.text}
            </p>
          </div>
        ))}

        <div className="shadow-conversation bg-[#FBFBFB] w-full lg:h-[400px] xs:h-[260px] rounded-[20px] px-4 py-2 xs:relative lg:flex lg:flex-col">
          <img
            src={"/images/dog.svg"}
            alt=""
            className="lg:w-[200px] xs:w-[50px] absolute xs:right-0 xs:top-[-50px] lg:right-8 lg:top-12 lg:hidden xs:flex  "
          />
          <div className="flex items-center gap-2 mb-2">
            <img src={Icon} alt="" className="lg:w-[35px] xs:w-[18px]" />
            <h2 className="font-semibold lg:text-xl xs:text-[12px]">
              Instructions
            </h2>
          </div>

          <div className="flex flex-col lg:h-[300px] xs:h-[200px] px-4 py-2 border border-[#DDDDDD] rounded-[10px] relative overflow-auto ">
            <div className="w-full mt-3">
              <h2 className="text-[#B793FF] lg:text-lg xs:text-[10px] font-semibold">
                Don’t send your CV for that job until you have done this
              </h2>
              {/* <div
                onClick={handleGenerateClick}
                className="absolute top-[10px] right-[10px]"
              >
                <button className="bg-[#B793FF] px-3 py-1 lg:text-[20px] xs:text-[8px] text-white rounded-[5px]">
                  Generate
                </button>
              </div> */}
            </div>
            <span className="lg:text-[16px] xs:text-[8px] mt-4">
              {displayedText}
            </span>
          </div>
        </div>
        <div
          id="auto-generate-trigger"
          style={{ height: "1px", opacity: "0" }}
        ></div>
      </div>
    </>
  );
}

function CareerPotential() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token") || Cookies.get("token");
  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL_SSO;
  const SSO_Client_id = process.env.REACT_APP_CLIENT_ID_SSO;
  const goToChat = () => {
    const token = localStorage.getItem("token") || Cookies.get("token");

    if (token) {
      navigate("/chat");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div className="flex items-center justify-center lg:w-[85%] xs:w-[95%] xs:flex-col-reverse lg:flex-row">
        <div className="flex flex-col justify-start lg:w-[50%] xs:w-[100%] lg:gap-8 xs:gap-5 xs:top-1 xs:left-[0px] lg:top-0 lg:left-0 xs:px-3 lg:px-0 xs:py-2 lg:py-0">
          <h1 className=" font-bold xl:text-[44px] lg:text-[30px] xs:text-[24px] lg:w-[80%] xl:w-[80%] xs:w-full xs:leading-[30px] lg:leading-[45px]">
            Hunt out your career potential
          </h1>

          <div className="flex xs:flex-row lg:flex-col xs:items-center lg:items-start gap-1">
            <span className="lg:text-lg xs:text-[12px] sm:w-[60%] xs:w-[100%]">
              Have a chat for any kind of job advice and career coaching.
            </span>
          </div>
          {token ? (
            <button
              onClick={goToChat}
              className="bg-[#FF5E59] lg:text-[20px] xs:text-[10px] lg:w-[170px] xs:w-[90px] lg:h-[50px] xs:h-[27px] text-white rounded-[5px]"
            >
              Chat Now
            </button>
          ) : (
            <OauthSender
              clientId={SSO_Client_id}
              authorizeUrl={`${SSOUrl}/authorize`}
              redirectUri={redirectUrl}
              render={({ url }) => (
                <a
                  href={url}
                  className="bg-[#FF5E59] flex justify-center items-center lg:text-[20px] xs:text-[10px] lg:w-[170px] xs:w-[90px] lg:h-[50px] xs:h-[27px] text-white rounded-[5px]"
                >
                  Chat Now
                </a>
              )}
            />
          )}
        </div>
        <img src={Coach} alt="" className="lg:flex lg:w-[50%] xs:w-[100%]" />
      </div>
    </>
  );
}

function PowerHouse() {
  const cards = [
    {
      img: Conversation,
      Text: "Conversational input"
    },
    {
      img: Conversation,
      Text: "No spelling or grammar mistakes"
    },
    {
      img: Conversation,
      Text: "Re-do for multiple job applications"
    },
    {
      img: Conversation,
      Text: "Download and save Word, PDF files"
    },
    {
      img: Conversation,
      Text: "Create a CV in an instant"
    },
    {
      img: Conversation,
      Text: "Modifications by conversational chat"
    },
    {
      img: Conversation,
      Text: "Careers advice 24/7"
    }
  ];

  return (
    <>
      <div className="flex items-center lg:justify-start xs:justify-between  xs:gap-0 lg:w-[95%] w-[95%] xs:flex-col lg:flex-row xl:gap-[200px] lg:gap-[100px]">
        {/* <img src={"/images/career_second.svg"} alt="" className="" /> */}

        <img src={Hunt} alt="" className="lg:w-[45%] 2xl:w-[85%]" />
        <div className="flex flex-col gap-1 lg:items-center xs:items-center xs:justify-start lg:justify-center  xs:px-3 lg:px-0 xs:py-2 lg:py-0 xs:w-full lg-w-[50%] ">
          <div className="w-full flex xs:flex-row lg:flex-col gap-1 xs:mb-2 lg:mb-10">
            <h1 className=" font-bold xl:text-[44px] lg:text-[30px] xs:text-[22px]  xs:leading-[30px] lg:leading-[45px]">
              Our powerhouse features
            </h1>
          </div>
          {cards.map((card, index) => (
            <div className="w-full flex items-center gap-3" key={index}>
              <img
                src={card.img}
                alt=""
                className="lg:w-[17px] xs:w-[6px] lg:h-[17px] xs:h-[6px]"
              />
              <p className="lg:text-[16px] xs:text-[10px]">{card.Text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function CareerTransition() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token") || Cookies.get("token");
  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL_SSO;
  const SSO_Client_id = process.env.REACT_APP_CLIENT_ID_SSO;

  const goToChat = () => {
    const token = localStorage.getItem("token") || Cookies.get("token");

    if (token) {
      navigate("/chat");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div className="flex items-center justify-between lg:w-[85%] xs:w-[95%] xs:flex-col-reverse lg:flex-row">
        <div className="flex flex-col lg:gap-8 xs:gap-5 xs:px-3 lg:px-0 xs:py-2 lg:py-0 lg:w-full lg:items-start">
          <h1 className="font-bold xl:text-[44px] lg:text-[30px] xs:text-[20px]">
            Career transition
          </h1>
          <span className="lg:w-[300px] xs:w-full xs:text-[12px] lg:text-[18px]">
            How do I highlight my transferable skills as I want to apply for a
            job in a different industry?
          </span>
          {token ? (
            <button
              onClick={goToChat}
              className="bg-[#FF5E59] lg:text-[20px] xs:text-[10px] lg:w-[170px] xs:w-[90px] lg:h-[50px] xs:h-[27px] text-white rounded-[5px]"
            >
              Chat Now
            </button>
          ) : (
            <OauthSender
              clientId={SSO_Client_id}
              authorizeUrl={`${SSOUrl}/authorize`}
              redirectUri={redirectUrl}
              render={({ url }) => (
                <a
                  href={url}
                  className="bg-[#FF5E59] flex justify-center items-center lg:text-[20px] xs:text-[10px] lg:w-[170px] xs:w-[90px] lg:h-[50px] xs:h-[27px] text-white rounded-[5px]"
                >
                  Chat Now
                </a>
              )}
            />
          )}
        </div>

        <img src={Transition} alt="" className="lg:w-[50%] xs:w-full" />
      </div>
    </>
  );
}

function Customertestimonials() {
  const data = [
    {
      rating: 5,
      Text: "I just pasted my CV and CrewDog recommended jobs that matched my transferrable skills.Amazing!",
      name: "James M  ",
      span: "Project Manager | Renewables"
    },
    {
      rating: 5,
      Text: "Customising my old CV quickly using Crewdog’s CVLab meant that after 3 months of no replies from recruiters, I got 5 interviews in 3 weeks! Fast and simple to use.",
      name: "Mark E",
      span: "International Sales Representative"
    },
    {
      rating: 5,
      Text: "With CrewDog's CVLab, customising CVs and branding for my clients is a breeze. No more sending to CV writers as I can do it all in minutes, giving me more time with valuable clients.",
      name: "Margot Swan",
      span: " SAP Recruiter"
    }
  ];
  function renderRatingStars(rating) {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(
          <span key={i} style={{ color: "#FEC84D", fontSize: "30px" }}>
            &#9733;
          </span>
        ); // filled star
      } else {
        stars.push(
          <span style={{ color: "#FEC84D", fontSize: "30px" }} key={i}>
            &#9734;
          </span>
        ); // empty star
      }
    }
    return stars;
  }

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center">
        <h1 className="font-bold lg:text-[44px] xs:text-[24px] text-[#263238]">
          Customer testimonials
        </h1>
        <span className="lg:text-lg xs:text-[10px] text-[#4E4949] font-normal">
          105 real life stories from trusted users
        </span>
        <div className="flex justify-between lg:gap-6 2xl:gap-[100px] w-[100%] my-5">
          {data.map((data, index) => (
            <div
              className="flex flex-col items-center w-full gap-3"
              key={index}
            >
              <div>{renderRatingStars(data.rating)}</div>
              <p className="w-[365px] text-lg text-center">"{data.Text}"</p>
              <div>
                <p className="text-[#C6A4FF] font-bold text-lg text-center">
                  {data.name}
                </p>
                <span className="text-[#4E4949] text-[18px] font-medium">
                  {data.span}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function CustomertestimonialsMobile() {
  return (
    <>
      <Swiper
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="w-full flex flex-col items-center justify-center ">
            <h1 className="font-bold lg:text-[44px] xs:text-[24px] text-[#263238]">
              Customer testimonials
            </h1>
            <span className="lg:text-lg xs:text-[10px] text-[#4E4949] font-normal">
              105 real life stories from trusted users
            </span>
            <div className="flex flex-col  my-5 ">
              <div class="flex items-center justify-center pb-2">
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </div>
              <span className="text-[14px] px-10 ">
                "I just pasted my CV and CrewDog recommended jobs that matched
                my transferrable skills. Amazing!"
              </span>
              <p className="text-[#C6A4FF] font-bold text-[18px]">James M</p>
              <span className="text-[#4E4949] text-[18px] font-medium">
                {" "}
                Project Manager | Renewables
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full flex flex-col items-center justify-center ">
            <h1 className="font-bold lg:text-[44px] xs:text-[24px] text-[#263238]">
              Customer testimonials
            </h1>
            <span className="lg:text-lg xs:text-[10px] text-[#4E4949] font-normal">
              105 real life stories from trusted users
            </span>
            <div className="flex flex-col  my-5 ">
              <div class="flex items-center justify-center pb-2">
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </div>
              <span className="text-[14px] px-10 ">
                “Customising my old CV quickly using Crewdog’s CVLab meant that
                after 3 months of no replies from recruiters, I got 5 interviews
                in 3 weeks! Fast and simple to use.”
              </span>
              <p className="text-[#C6A4FF] font-bold text-[18px] pt-2">
                Mark E
              </p>
              <span className="text-[#4E4949] text-[18px] font-medium">
                {" "}
                International Sales Representative
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full flex flex-col items-center justify-center ">
            <h1 className="font-bold lg:text-[44px] xs:text-[24px] text-[#263238]">
              Customer testimonials
            </h1>
            <span className="lg:text-lg xs:text-[10px] text-[#4E4949] font-normal">
              105 real life stories from trusted users
            </span>
            <div className="flex flex-col  my-5 ">
              <div class="flex items-center justify-center pb-2">
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </div>
              <span className="text-[14px] px-10 ">
                “With CrewDog's CVLab, customising CVs and branding for my
                clients is a breeze. No more sending to CV writers as I can do
                it all in minutes, giving me more time with valuable clients.”
              </span>
              <p className="text-[#C6A4FF] font-bold text-[18px] pt-2">
                Margot Swan
              </p>
              <span className="text-[#4E4949] text-[18px] font-medium">
                SAP Recruiter
              </span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
