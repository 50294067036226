import { useContext, useState } from "react";
import axios from "axios";
import { templates } from "../static-data/TemplateImg";
import GlobalContext from "../context/GlobalContext";
import Cookies from "js-cookie";

const useGetFavouriteTemp = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [favoriteTemplates, setFavoriteTemplates] = useState(null);
  const { setCheckDeleteStatus } = useContext(GlobalContext);

  const token = localStorage.getItem("token") || Cookies.get("token");

  const getFavouriteTemp = async () => {
    await axios
      .get(`${BASE_URL}/api/favorite/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (!favoriteTemplates) {
          setLoading(false);
        }
        setCheckDeleteStatus(false);
        function compareArrays(arr1, arr2) {
          const idMap = new Map(
            arr1?.map((obj) => [obj.template.name, obj.id])
          );
          const commonObjects = arr2?.filter((obj) => idMap.has(obj.id));

          return commonObjects?.map((obj) => ({
            ...obj,
            tempId: idMap?.get(obj.id),
          }));
        }
        const result = compareArrays(response.data, templates);
        setFavoriteTemplates(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return { loading, getFavouriteTemp, favoriteTemplates };
};

export default useGetFavouriteTemp;
