import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const useSaveUserCV = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token") || Cookies.get("token");

  const saveUserCV = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/save/cv/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);

        Swal.fire({
          icon: "success",
          text: "Successfully Saved",
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: error?.response?.data?.message || "Error ocuured while saving",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, saveUserCV };
};

export default useSaveUserCV;
