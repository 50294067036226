import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const useVerifyOtp = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const verifyOTP = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/accounts/api/verifyOTP/`, payload)
      .then((response) => {
        Cookies.set("verification_token", response.data.token);
        Cookies.set("UID", response.data.uid);
        if (response.data.token) {
          setLoading(false);
          window.location.href = "/new-password";
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: error?.response?.data?.message,
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, verifyOTP };
};

export default useVerifyOtp;
