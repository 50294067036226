import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import GlobalContext from "../context/GlobalContext";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const useUploadCVLink = () => {
  const { setCVDetail, setSecondQuestion } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token") || Cookies.get("token");
  const Navigate = useNavigate();
  const uploadCVLink = async (payload, setSelectedFile, handleClose) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/process_data/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);
        setCVDetail(response.data);
        setSecondQuestion(false);
        handleClose();
        setSelectedFile(null);
        Navigate("/chat");
      })
      .catch((error) => {
        setLoading(false);
        handleClose();
        setSelectedFile(null);
        setSecondQuestion(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.error,
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, uploadCVLink };
};
export default useUploadCVLink;
