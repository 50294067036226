import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";
import CVTemplate1 from "./templates/CV_Template_1";
import CVTemplate2 from "./templates/CV_Template_2";
import CVTemplate3 from "./templates/CV_Template_3";
import CVTemplate4 from "./templates/CV_Template_4";
import CVTemplate5 from "./templates/CV_Template_5";
import CVTemplate6 from "./templates/CV_Template_6";
import BackButton from "../components/shared/BackButton";

function EditTemplate() {
  const { template_id } = useParams();
  const Navigate = useNavigate();
  const handleGoBack = () => {
    Navigate(-1);
  };

  const getTemplateContent = () => {
    const prefixedId = `${template_id}`;

    switch (prefixedId) {
      case "v101":
        return <CVTemplate1 />;
      case "v102":
        return <CVTemplate2 />;
      case "v103":
        return <CVTemplate3 />;
      case "v104":
        return <CVTemplate4 />;
      case "v105":
        return <CVTemplate5 />;
      case "v106":
        return <CVTemplate6 />;

      default:
        return (
          <div className="h-[70vh] flex items-center justify-center">
            <h2 className="font-bold text-[35px] ">Coming Soon...</h2>
          </div>
        );
    }
  };

  return (
    <div>
      <Navbar />
      <div className="flex gap-2 h-auto mt-[90px] px-8">
        <div
          onClick={handleGoBack}
          className="cursor-pointer lg:fixed xs:absolute"
        >
          <BackButton />
        </div>
      </div>
      {getTemplateContent()}
      <Footer />
    </div>
  );
}

export default EditTemplate;
