import axios from "axios";
import GlobalContext from "../context/GlobalContext";
import { useContext } from "react";
import Cookies from "js-cookie";

const useCVModification = () => {
  const {
    setShowMakeCVQuestion,
    setChatData,
    setChatLoading,
    setSendBtnLoading,
  } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token") || Cookies.get("token");

  const CVModification = async (payload) => {
    await axios
      .post(`${BASE_URL}/api/chat/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const messagedata = JSON.stringify(response.data);
        const newMessage = { role: "cv_data", content: messagedata };
        setChatData([newMessage]);
        setChatLoading(false);
        setSendBtnLoading(false);
        setShowMakeCVQuestion(true);
      })
      .catch((error) => {
        const newMessage = {
          role: "error",
          content:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
        };
        setChatData([newMessage]);
        setChatLoading(false);
        setSendBtnLoading(false);
      });
  };

  return { CVModification };
};

export default useCVModification;
