import Template1 from "../assets/images/templates/temp1.png";
import Template2 from "../assets/images/templates/temp2.png";
import Template3 from "../assets/images/templates/temp3.png";
import Template4 from "../assets/images/templates/temp4.png";
import Template5 from "../assets/images/templates/temp5.png";
import Template6 from "../assets/images/templates/temp6.png";
import pdficon from "../assets/images/templates/Icon.svg";

export const templates = [
  { id: "v102", img: Template5, icon: pdficon, var: true },
  { id: "v101", img: Template1, icon: pdficon, var: false },
  { id: "v103", img: Template4, icon: pdficon, var: false },
  { id: "v104", img: Template3, icon: pdficon, var: false },
  { id: "v105", img: Template6, icon: pdficon, var: false },
  { id: "v106", img: Template2, icon: pdficon, var: false }
  // { id: "v107", img: Template1, icon: pdficon },
  // { id: "v108", img: Template1, icon: pdficon },
  // { id: "v109", img: Template1, icon: pdficon },
  // { id: "v110", img: Template1, icon: pdficon },
];
