import React, { Fragment, useContext, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import Edit from "../../../assets/images/editavatar.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../style/Editor.css";
import GlobalContext from "../../../context/GlobalContext";
import axios from "axios";
import { ButtonLoader1 } from "../../shared/ButtonLoader";
import Cookies from "js-cookie";

const validationSchema = Yup.object().shape({
  // name: Yup.string().required("name is required"),
  // email: Yup.string().required("email is required"),
  name: Yup.string(),
  email: Yup.string(),
  job_title: Yup.string(),
  number: Yup.string(),
  address: Yup.string(),
  summary: Yup.string(),
});

function PersonalInformation(props) {
  const cancelButtonRef = useRef(null);
  const { data, onSave, setShowPicture, showPicture, template_id } = props;
  const { userInfo } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const initialValues = {
    name: data?.personal_information.name || "",
    job_title: data?.personal_information.job_title || "",
    number: data?.personal_information.number || "",
    email: data?.personal_information.email || "",
    address: data?.personal_information.address || "",
    summary: data?.personal_information.summary || "",
    profile_pic: data?.personal_information.profile_pic || "",
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        const updatedPersonalInfo = {
          profile_pic: values.profile_pic,
          name: values.name,
          job_title: values.job_title,
          number: values.number,
          email: values.email,
          address: values.address,
          summary: values.summary,
        };
        onSave(updatedPersonalInfo);
      },
    });

  const token = localStorage.getItem("token") || Cookies.get("token");
  const [loading, setLoading] = useState(false);
  const handlePictureChange = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setLoading(true);
      const payload = {
        template: template_id,
        picture: file,
        user: userInfo?.id,
      };
      await axios
        .post(`${BASE_URL}/api/save/picture/`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          setFieldValue("profile_pic", `/media/${response.data.picture_path}`);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[999999999]"
        initialFocus={cancelButtonRef}
        onClose={props.setIsOpenModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all lg:w-[600px] xs:w-[100%] py-4 px-8 ">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={() => props.setIsOpenModal(false)} />
                </div>

                <div className="flex flex-col w-full">
                  <h1 className="text-[#263238] font-medium text-[30px]">
                    Personal information
                  </h1>

                  <div className="flex lg:flex-row  xs:flex-col border border-[#EBEBEB] rounded-[20px] px-4 mb-2  ">
                    <div className="lg:w-[30%] xs:w-[100%] mt-3 flex flex-col items-center relative">
                      {loading ? (
                        <div className="w-[100px] h-[100px] rounded-full flex justify-center items-center">
                          <ButtonLoader1 />
                        </div>
                      ) : (
                        <>
                          <span className="font-normal mb-2 capitalize">
                            picture/logo
                          </span>
                          <label
                            htmlFor="fileInput"
                            className="cursor-pointer w-[100px] h-[100px] flex items-center justify-center rounded-full overflow-hidden"
                          >
                            {values?.profile_pic ? (
                              <img
                                src={`${BASE_URL}${values?.profile_pic}`}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                src={
                                  "https://walnuteducation.com/static/core/images/icon-profile.png"
                                }
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                            <input
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              onChange={handlePictureChange}
                              className="hidden "
                            />
                            <div className="flex items-center justify-center bg-[#C6A4FF] w-[25px] h-[25px] rounded-full border border-[#ffff] absolute top-[18%] left-[70%]">
                              <img src={Edit} alt="" />
                            </div>
                          </label>
                        </>
                      )}

                      <div className="flex items-center justify-center gap-1 mt-2">
                        <input
                          type="checkbox"
                          alt=""
                          className="w-[16px] h-[16px] rounded-[4px]"
                          value={showPicture}
                          checked={showPicture}
                          onChange={() => setShowPicture(!showPicture)}
                        />
                        <span>Show Picture</span>
                      </div>
                    </div>

                    <div className="lg:w-[70%] xs:w-[100%] px-4 py-2">
                      <form>
                        <div className="mb-2">
                          <label>Name</label>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                          />
                          {errors && (
                            <p className="text-red-700 text-xs">
                              {errors.name}
                            </p>
                          )}
                        </div>
                        <div className="mb-2">
                          <label>Job title</label>
                          <input
                            type="text"
                            placeholder="Enter Job Title"
                            name="job_title"
                            value={values.job_title}
                            onChange={handleChange}
                            className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                          />
                          {errors && (
                            <p className="text-red-700 text-xs">
                              {errors.job_title}
                            </p>
                          )}
                        </div>
                        <div className="mb-2">
                          <label>Phone number</label>
                          <input
                            type="text"
                            placeholder="Enter Phone"
                            name="number"
                            value={values.number}
                            onChange={handleChange}
                            className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                          />
                          {errors && (
                            <p className="text-red-700 text-xs">
                              {errors.number}
                            </p>
                          )}
                        </div>
                        <div className="mb-2">
                          <label>Email</label>
                          <input
                            type="text"
                            placeholder="Enter Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                          />
                          {errors && (
                            <p className="text-red-700 text-xs">
                              {errors.email}
                            </p>
                          )}
                        </div>
                        <div className="mb-2">
                          <label>Address</label>
                          <input
                            type="text"
                            placeholder="Enter Address"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                          />
                          {errors && (
                            <p className="text-red-700 text-xs">
                              {errors.address}
                            </p>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="w-full mb-2 mt-2">
                    <label className="text-[#4E4949] font-normal text-[16px]">
                      Summary
                    </label>
                    <div className="border-2 border-[#000] Model_editor">
                      <ReactQuill
                        value={values?.summary}
                        onChange={(value) =>
                          handleChange({
                            target: { name: "summary", value },
                          })
                        }
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "bullet" }],
                            [{ align: [] }],
                            [{ color: [] }, { background: [] }],
                          ],
                          clipboard: {
                            matchVisual: false,
                          },
                        }}
                        formats={[
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "list",
                          "bullet",
                          "align",
                          "color",
                          "background",
                        ]}
                        className=""
                      />
                    </div>
                    {errors && (
                      <p className="text-red-700 text-xs">{errors.summary}</p>
                    )}
                  </div>
                  <div className="flex justify-end w-full gap-4 py-4 ">
                    <button
                      onClick={() => props.setIsOpenModal(false)}
                      className="bg-[#4E494933] bg-opacity-[60%] text-[black] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-[#FF5E59]  text-[#fff] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                      type="sub"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PersonalInformation;
