import React, { useEffect, useState } from "react";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";
import { ButtonLoader2 } from "../components/shared/ButtonLoader";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Browse from "../assets/images/Browse.png";

import * as Yup from "yup";
import { useFormik } from "formik";
import useUploadCVLink from "../hooks/useUploadCVLink";

const validationSchema = Yup.object().shape({
  pdf: Yup.string().required("Please upload your cv"),
});
const Upload_CV = () => {
  const { loading, uploadCVLink } = useUploadCVLink();
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [fileError, setFileError] = useState("");
  const initialValues = {
    job_description: "",
    pdf: null,
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("file_upload", values.pdf);
      formData.append("job_description", values.job_description);

      await uploadCVLink(
        formData,
        // props.setAlreadyUploadedCV,
        // props.setIsOpenModal,
        setSelectedFile,
        handleClose
      );
    },
  });

  useEffect(() => {
    let interval;
    if (selectedFile) {
      interval = setInterval(() => {
        if (progress < 100) {
          setProgress(progress + 10);
        }
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [progress, selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFileTypes = [".pdf", ".doc", ".docx"];
      const selectedFileType = file.name.toLowerCase();
      const isValidFileType = allowedFileTypes.some((type) =>
        selectedFileType.endsWith(type)
      );

      if (!isValidFileType) {
        setFileError("Please select only docs and pdf file.");
        setSelectedFile(null);
        setFieldValue("pdf", null);
        return;
      }

      setSelectedFile(file);
      setFieldValue("pdf", file);

      const uploadTime = 3;
      let currentTime = 0;

      const timerInterval = setInterval(() => {
        currentTime += 1;
        const timeLeft = uploadTime - currentTime;

        if (timeLeft >= 0) {
          setRemainingTime(timeLeft);
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);

      setFileError("");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      setFieldValue("pdf", file);
      const uploadTime = 3;
      let currentTime = 0;

      const timerInterval = setInterval(() => {
        currentTime += 1;
        const timeLeft = uploadTime - currentTime;

        if (timeLeft >= 0) {
          setRemainingTime(timeLeft);
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInMb = sizeInBytes / (1024 * 1024);
    return sizeInMb.toFixed(2) + " MB";
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
    setProgress(0);
    setRemainingTime(0);
  };
  const handleClose = () => {
    removeSelectedFile();
    resetForm();
  };
  return (
    <>
      <Navbar />
      <div className="w-full min-h-[100vh] flex items-center justify-center ">
        <div className="flex h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className=" overflow-hidden  w-[500px] py-8 px-8 ">
            <div className="flex flex-col justify-center items-center w-full">
              <h1 className="text-[#263238] lg:text-[20px] text-[15px] font-medium mb-2">
                Upload your CV
              </h1>

              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="rounded-[10px] border border-dotted border-[#C6A4FF]  py-4 flex flex-col justify-center items-center w-full mt-2"
              >
                <img className="w-[68px] h-[57px]" src={Browse} alt="" />
                <div className="d-flex align-items-center justify-center text-center">
                  <span className="text-[16px]">
                    Drag and drop to upload <br />
                    or{" "}
                  </span>
                  <label
                    className="text-[#C6A4FF] text-[16px] font-normal cursor-pointer underline"
                    htmlFor="pdf"
                  >
                    Browse
                  </label>
                </div>
                <input
                  type="file"
                  id="pdf"
                  accept=".pdf, .doc, .docx"
                  name="pdf"
                  values={values.pdf}
                  onChange={(e) => {
                    handleChange(e);
                    handleFileChange(e);
                  }}
                  style={{ display: "none" }}
                />
              </div>

              <div
                className={`w-full flex ${
                  errors.pdf ? "justify-between" : "justify-end"
                } items-center text-left mt-[2px] mb-2`}
              >
                {errors.pdf && touched.pdf && (
                  <p className="text-red-700 text-xs w-full">{errors.pdf}</p>
                )}
                {fileError && (
                  <div className="text-red-700 text-[10px] text-left w-full">
                    {fileError}
                  </div>
                )}
                <span className="text-[10px] flex w-full justify-end">
                  DOC, DOCX, PDF
                </span>
              </div>
              {selectedFile && (
                <div className="w-full justify-start py-2">
                  <p className="text-[#4E4949] font-normal text-xs">
                    {selectedFile.name}
                  </p>
                  <p className="text-[#4E4949] font-normal text-[10px]">
                    {formatFileSize(selectedFile.size)}
                  </p>
                </div>
              )}
              <div className="w-full flex items-center justify-center gap-1">
                <div class="w-full bg-[#C6A4FF]  rounded-full h-0.5 dark:bg-[#FF5E59]">
                  <div
                    className="bg-[#FF5E59] h-0.5 rounded-full"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <AiOutlineCloseCircle
                  onClick={removeSelectedFile}
                  className="cursor-pointer"
                />
              </div>
              <div className="flex justify-between w-full py-1">
                <span className="text-[#4E4949] text-[10px]">{`${progress}%`}</span>
              </div>

              <div className="flex w-full gap-2 justify-end mt-5">
                <button
                  className="bg-[#4E494933] h-[40px] w-[100px] flex items-center justify-center rounded-[5px]  cursor-pointer text-[#263238]"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-[#FF5E59] h-[40px] w-[100px] flex items-center justify-center rounded-[5px] outline-none text-white cursor-pointer"
                >
                  {loading ? <ButtonLoader2 /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Upload_CV;
