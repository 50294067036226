/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Data } from "../../static-data/CVData";
import PersonalInformation from "../../components/modals/cv-modals/PersonalInformation";
import Education from "../../components/modals/cv-modals/Education";
import EmploymentHistory from "../../components/modals/cv-modals/EmploymentHistory";
import Skills from "../../components/modals/cv-modals/Skills";
import Reference from "../../components/modals/cv-modals/Reference";
import { useState } from "react";
import useSaveUserCV from "../../hooks/useSaveTemplate";
import {
  ButtonLoader1,
  ButtonLoader2,
} from "../../components/shared/ButtonLoader";
import GlobalContext from "../../context/GlobalContext";
import { useParams } from "react-router-dom";
import useUpdateUserCV from "../../hooks/useUpdateTemplate";
import useGetUserCV from "../../hooks/useGetUserCV";
import MainLoader from "../../components/shared/MainLoader";
import useDownloadCV from "../../hooks/useDownloadCV";
import useRemoveHtmlTags from "../../hooks/useRemoveHtmlTags";
import ReactQuill from "react-quill";
import Projects from "../../components/modals/cv-modals/Projects";
import PdfViewerModel from "../../components/modals/PdfViewerModel";

const CV_Template_3 = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { cv_id, template_id, check_page } = useParams();
  const { chatData, userInfo } = useContext(GlobalContext);
  const { saveUserCV, loading } = useSaveUserCV();
  const { updateUserCV, loading_2 } = useUpdateUserCV();
  const [personalInformation, setPersonalInformation] = useState(false);
  const [employmentHistory, setEmploymentHistory] = useState(false);
  const [education, setEducation] = useState(false);
  const [skills, setSkills] = useState(false);
  const [reference, setReference] = useState(false);
  const lastObject = chatData[chatData?.length - 1];
  const jsonStng = lastObject?.content
    .replace(/\\\\/g, "\\")
    .replace(/\\n/g, "\n")
    // .replace(/[-/]/g, "")
    .replace(/[\x00-\x1F\x7F-\x9F]/g, "");

  const jsonData = jsonStng ? JSON.parse(jsonStng) : null;
  const { userCVData, getUserCV, userCvLoading } = useGetUserCV();
  const [loadingCvData, setLoadingCvData] = useState(true);
  const [showPicture, setShowPicture] = useState(true);
  const [projects, setProjects] = useState(false);
  const [cvData, setCvData] = useState(
    jsonData
      ? {
          personal_information: {
            profile_pic: jsonData?.personal_information?.profile_pic,
            name: jsonData?.personal_information?.name || "",
            job_title: jsonData?.personal_information?.job_title || "",
            number: jsonData?.personal_information?.number || "",
            email: jsonData?.personal_information?.email || "",
            address: jsonData?.personal_information?.address || "",
            summary: jsonData?.personal_information?.summary || "",
          },
          skills: jsonData?.skills?.map((item) => ({ name: item.name })),
          education: jsonData?.education?.map((item) => ({
            institute_name: item.institute_name || "",
            field_of_study: item.field_of_study || "",
            city: item.city || "",
            country: item.country || "",
            start_date: item.start_date || "",
            end_date: item.end_date || "",
            description: item.description || "",
          })),
          employment_history: jsonData?.employment_history?.map((item) => ({
            company_name: item.company_name || "",
            job_title: item.job_title || "",
            city: item.city || "",
            country: item.country || "",
            start_date: item.start_date || "",
            end_date: item.end_date || "",
            description: item.description || "",
          })),
          projects: jsonData?.projects?.map((item) => ({
            title: item?.title || item?.project_name || "",
            description: item.description || "",
          })),
          reference: [],
        }
      : Data
  );

  useEffect(() => {
    if (cv_id && template_id) {
      getUserCV(cv_id, template_id);
    }
  }, []);

  useEffect(() => {
    if (userCVData) {
      setLoadingCvData(false);
      setCvData(userCVData);
    } else if (jsonData) {
      setLoadingCvData(false);
    } else {
      setLoadingCvData(false);
    }
  }, [userCVData]);

  const handleSavePersonalInformation = (updatedPersonalInfo) => {
    setCvData((prevCvData) => ({
      ...prevCvData,
      personal_information: {
        ...prevCvData.personal_information,
        name: updatedPersonalInfo.name,
        job_title: updatedPersonalInfo.job_title,
        number: updatedPersonalInfo.number,
        email: updatedPersonalInfo.email,
        address: updatedPersonalInfo.address,
        summary: updatedPersonalInfo.summary,
        profile_pic: showPicture ? updatedPersonalInfo?.profile_pic : null,
      },
    }));
    setPersonalInformation(false);
  };
  const handleSaveSkills = (updatedSkills) => {
    setCvData({
      ...cvData,
      skills: updatedSkills,
    });
    setSkills(false);
  };
  const handleSaveProjects = (updatedProjects) => {
    setCvData({
      ...cvData,
      projects: updatedProjects,
    });
    setProjects(false);
  };
  const handleSaveReference = (updatedReference) => {
    setCvData({
      ...cvData,
      reference: updatedReference,
    });
    setReference(false);
  };
  const handleSaveEmploymentHistory = (updatedEmploymentHistory) => {
    setCvData({
      ...cvData,
      employment_history: updatedEmploymentHistory,
    });
    setEmploymentHistory(false);
  };
  const handleSaveEducationHistory = (updatedEducationHistory) => {
    setCvData({
      ...cvData,
      education: updatedEducationHistory?.map((educationItem) => ({
        ...educationItem,
        description: educationItem.description,
      })),
    });
    setEducation(false);
  };

  useEffect(() => {
    if (!cvData?.personal_information?.profile_pic && userInfo?.profile_pic) {
      setCvData((prevCvData) => ({
        ...prevCvData,
        personal_information: {
          ...prevCvData.personal_information,
          profile_pic: null,
        },
      }));
    }
  }, [userInfo?.profile_pic, cvData]);

  const { removeHtmlTagsFromData } = useRemoveHtmlTags();
  const handleSave = () => {
    const cvDataWithoutHtml = removeHtmlTagsFromData(cvData);
    if (check_page === "save-resume") {
      saveUserCV({
        cv_data: cvDataWithoutHtml,
        template_id: template_id,
        name: cvData?.personal_information.name,
        profile_pic_state: cvData.personal_information.profile_pic
          ? true
          : false,
      });
    } else if (check_page === "update-resume") {
      updateUserCV({
        cv_id: cv_id,
        cv_data: cvDataWithoutHtml,
        template_id: template_id,
        username: cvData?.personal_information.name,
      });
    }
  };

  const [isEdit, setIsEdit] = useState(false);
  const [isEditRefrence, setIsEditRefrence] = useState(false);
  const downloadPDF = async () => {
    const element = document.getElementById("actual-receipt");
    const username = cvData?.personal_information?.name || "CV lab";
    const payload = element.outerHTML;
    const style = document.createElement("style");
    style.innerHTML = `
        @media print {
            /* Set margins */
            @page {
              size:A4;
              margin-top: 2.04cm;
              margin-bottom: 2.04cm;
              margin-left: 1.17cm;
              margin-right: 1.17cm;
            }
            /* Hide header and footer */
            header, footer {
                display: none !important;
            }
            /* Optionally, you can hide certain elements */
            /* Example: Hide buttons */
            .print-hide {
                display: none;
            }
 /* Hide specific elements */
      #edit-btn, .name-section:hover {
        display: none !important;
      }

        }
    `;
    document.head.appendChild(style);
    document.title = `${username}'s CV`;
    if (window.browser) {
      const { printToPDF } = window.browser;
      const pdfData = await printToPDF({
        content: payload,
        marginsType: 0,
        pageSize: "A4",
        printBackground: true,
      });
      const blob = new Blob([pdfData], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${username}_CV.pdf`;
      link.click();
    } else {
      const controls = document.getElementsByClassName("controls");
      window.print();
    }

    document.title = "CV Lab";
  };
  const { downloadBtnLoading, DownloadCV, pdfViewer } = useDownloadCV();
  const [showPDF, setShowPDF] = useState(false);
  // const downloadPDF = async () => {
  //   const element = document.getElementById("actual-receipt");
  //   const username = cvData?.personal_information?.name || "Crewdog";
  //   const payload = element.outerHTML;
  //   await DownloadCV(payload, username);
  //   setShowPDF(true);
  // };
  const PDF_BASE_URL = process.env.REACT_APP_PDF_BASE_URL;
  const pdfUrl = `${PDF_BASE_URL}${pdfViewer}`;
  const username = cvData?.personal_information?.name || "CV lab";

  return (
    <>
      {/* <PdfViewerModel
        showPDF={showPDF}
        setShowPDF={setShowPDF}
        pdfUrl={pdfUrl}
        username={username}
      /> */}
      <div className="w-full min-h-[80vh] flex flex-col items-center justify-center py-10 xs:px-3 lg:px-0">
        {loadingCvData || userCvLoading ? (
          <MainLoader />
        ) : (
          <div className="lg:w-[60%] xs:w-full h-full">
            <div className="w-full min-h-[100vh] border-2 border-gray-300 bg-[#FFF] rounded">
              <div
                id="actual-receipt"
                className="w-full min-h-[100vh] text-gray-700 xs:text-[12px] p-6"
              >
                <div
                  className="name-section"
                  onMouseEnter={() => setIsEdit(true)}
                  onMouseLeave={() => setIsEdit(false)}
                >
                  <div className="flex">
                    <div className="w-[30%] ">
                      {showPicture && (
                        <>
                          {cvData?.personal_information?.profile_pic ? (
                            <img
                              className="rounded-[100px] object-cover w-[80px] h-[80px]"
                              style={{ aspectRatio: "1/1", objectFit: "cover" }}
                              src={`${BASE_URL}${cvData?.personal_information?.profile_pic}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="rounded-[100px] object-cover w-[90px] h-[90px]"
                              style={{ aspectRatio: "1/1", objectFit: "cover" }}
                              src={
                                "https://walnuteducation.com/static/core/images/icon-profile.png"
                              }
                              alt=""
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="w-[70%] content-center flex items-center">
                      <p className="font-bold text-4xl ">
                        {cvData?.personal_information.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-[30%] items-start">
                      <p className="font-bold text-sm mt-2">
                        {cvData?.personal_information.job_title}
                      </p>
                      <p className="text-sm">
                        {cvData?.personal_information.email}
                      </p>
                      <p className="text-sm">
                        {cvData?.personal_information.number}
                      </p>
                      <p className="text-sm">
                        {cvData?.personal_information.address}
                      </p>
                    </div>
                    <div className="w-[70%] ">
                      {cvData?.personal_information.summary === "<p><br></p>" ||
                      cvData?.personal_information.summary?.length === 0 ? (
                        ""
                      ) : (
                        <div className="text-gray-700 bg-[#E1E1E1] rounded-lg">
                          <div className="px-4 pt-2 pb-4">
                            <p className="font-bold text-2xl mt-0 mb-1">
                              Profile
                            </p>

                            <ReactQuill
                              value={cvData?.personal_information.summary}
                              theme="snow"
                              readOnly
                              modules={{
                                toolbar: false,
                                clipboard: {
                                  matchVisual: false,
                                },
                              }}
                              formats={[
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "list",
                                "bullet",
                                "align",
                                "color",
                                "background",
                              ]}
                              className="font-[400]"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {isEdit && (
                    <button
                      className="edit-btn"
                      id="edit-btn"
                      onClick={() => setPersonalInformation(true)}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div className="border-b-2 border-gray-400 my-6"></div>
                <div className="flex gap-2">
                  <div className="w-[65%] h-auto flex ">
                    <div>
                      {cvData?.employment_history?.length > 0 && (
                        <div
                          className="mb-4 w-[90%] employment"
                          onMouseEnter={() => setIsEdit(true)}
                          onMouseLeave={() => setIsEdit(false)}
                        >
                          <h2 className="font-bold text-2xl mb-2">
                            Employment History
                          </h2>
                          {cvData?.employment_history.map((data, index) => (
                            <div key={index} className="mb-4">
                              <p className="w-[85%]  ">
                                <span className="font-bold text-base">
                                  {data.job_title} {data.company_name && "at"}{" "}
                                  {data.company_name}
                                  {data.city && ","} {data.city}
                                  {data.country && ","} {data.country}
                                </span>
                              </p>

                              {data.start_date && (
                                <div>
                                  <span className="text-[#878787]  text-sm">
                                    {data.start_date}
                                  </span>
                                  {data.start_date && (
                                    <span className="mx-2">-</span>
                                  )}

                                  <span className="text-[#878787] text-sm">
                                    {data.end_date ? data.end_date : "Present"}
                                  </span>
                                </div>
                              )}
                              {/* <div
                              className="text-[12px]"
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            /> */}
                              <ReactQuill
                                value={data.description}
                                theme="snow"
                                readOnly
                                modules={{
                                  toolbar: false,
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                                formats={[
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "list",
                                  "bullet",
                                  "align",
                                  "color",
                                  "background",
                                ]}
                                className="font-[400]"
                              />
                            </div>
                          ))}
                          {isEdit && (
                            <button
                              className="edit-btn"
                              id="edit-btn"
                              onClick={() => setEmploymentHistory(true)}
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      )}
                      {cvData?.education?.length > 0 && (
                        <div
                          className="mb-4 w-[90%] employment"
                          onMouseEnter={() => setIsEdit(true)}
                          onMouseLeave={() => setIsEdit(false)}
                        >
                          <h2 className="font-bold text-2xl mb-2">Education</h2>
                          {cvData?.education.map((data, index) => (
                            <div key={index} className="mb-4">
                              <p className="w-[85%]  ">
                                <span className="font-bold text-base">
                                  {data.field_of_study}{" "}
                                  {data.institute_name && "at "}
                                  {data.institute_name}
                                  {data.city && ", "}
                                  {data.city}
                                  {data.country && ", "}
                                  {data.country}
                                </span>
                              </p>
                              {data.start_date && (
                                <div>
                                  <span className="text-[#878787] text-sm">
                                    {data.start_date}
                                  </span>
                                  <span className="mx-2">-</span>
                                  <span className="text-[#878787]  text-sm">
                                    {data.end_date ? data.end_date : "Present"}
                                  </span>
                                </div>
                              )}
                              {/* <div
                              className="text-[12px]"
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            /> */}
                              <ReactQuill
                                value={data.description}
                                theme="snow"
                                readOnly
                                modules={{
                                  toolbar: false,
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                                formats={[
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "list",
                                  "bullet",
                                  "align",
                                  "color",
                                  "background",
                                ]}
                                className="font-[400]"
                              />
                            </div>
                          ))}
                          {isEdit && (
                            <button
                              className="edit-btn"
                              id="edit-btn"
                              onClick={() => setEducation(true)}
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      )}
                      {cvData?.projects.length > 0 && (
                        <div
                          className="mb-4 w-[90%] employment"
                          onMouseEnter={() => setIsEdit(true)}
                          onMouseLeave={() => setIsEdit(false)}
                        >
                          <h2 className="font-bold text-2xl mb-2">Projects:</h2>
                          {cvData?.projects.map((data, index) => (
                            <div key={index} className="mb-4">
                              <p className="w-[85%]  ">
                                <span className="font-bold text-base">
                                  {data.title}
                                </span>
                              </p>

                              {/* <div
                              className="text-[12px]"
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            /> */}
                              <ReactQuill
                                value={data.description}
                                theme="snow"
                                readOnly
                                modules={{
                                  toolbar: false,
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                                formats={[
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "list",
                                  "bullet",
                                  "align",
                                  "color",
                                  "background",
                                ]}
                                className="font-[400]"
                              />
                            </div>
                          ))}
                          {isEdit && (
                            <button
                              className="edit-btn"
                              id="edit-btn"
                              onClick={() => setProjects(true)}
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      )}

                      <div
                        className=" name-section flex flex-col mt-2"
                        onMouseEnter={() => (
                          setIsEdit(true), setIsEditRefrence(true)
                        )}
                        onMouseLeave={() => (
                          setIsEdit(false), setIsEditRefrence(false)
                        )}
                      >
                        {cvData?.reference.length > 0 ? (
                          <div className="w-[20%]">
                            <h1 className="font-bold text-2xl">Reference</h1>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="w-[80%]">
                          {cvData?.reference?.map((data, index) => (
                            <div
                              className="flex flex-col items-start justify-start"
                              key={index}
                            >
                              <span className="font-bold mt-2">
                                {data.person_name}
                              </span>
                              <span className="font-normal">
                                {data.contact_number}
                              </span>
                              {/* <div
                                className="text-[12px] ml-0"
                                dangerouslySetInnerHTML={{
                                  __html: data.reference_text,
                                }}
                              ></div> */}
                              <ReactQuill
                                value={data.reference_text}
                                theme="snow"
                                readOnly
                                modules={{
                                  toolbar: false,
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                                formats={[
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "list",
                                  "bullet",
                                  "align",
                                  "color",
                                  "background",
                                ]}
                                className="font-[400]"
                              />
                            </div>
                          ))}
                        </div>
                        {isEdit && (
                          <>
                            {!cvData?.reference.length > 0 &&
                              isEditRefrence && (
                                <span className="w-full flex items-start justify-start font-normal">
                                  Add any Refrence
                                </span>
                              )}
                            <button
                              className="edit-btn"
                              id="edit-btn"
                              onClick={() => setReference(true)}
                            >
                              Edit
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-[45%]">
                    {cvData.skills?.length > 0 && (
                      <div
                        className="bg-[#E1E1E1] h-auto w-full rounded-lg skills"
                        onMouseEnter={() => setIsEdit(true)}
                        onMouseLeave={() => setIsEdit(false)}
                      >
                        <div className="px-4 pt-2 pb-4">
                          <h2 className="font-bold text-2xl">Skills</h2>
                          {cvData.skills.map((data, index) => (
                            <ul
                              key={index}
                              className=" flex justify-between items-center"
                            >
                              <li className="font-bold text-sm">{data.name}</li>
                              {/* <div className="w-full px-4">
                              <div className="w-full border-t-[1px] border-black border-dotted"></div>
                            </div>
                            <p className="text-left">4/5</p> */}
                            </ul>
                          ))}
                        </div>
                        {isEdit && (
                          <button
                            className="edit-btn"
                            id="edit-btn"
                            onClick={() => setSkills(true)}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mt-3">
              <button
                onClick={handleSave}
                className="receipt-modal-download-button border border-solid border-[#FF5E59] w-[80px] h-[40px] flex justify-center items-center text-[#FF5E59] font-bold rounded"
              >
                {loading || loading_2 ? (
                  <ButtonLoader1 />
                ) : (
                  `${check_page === "update-resume" ? "Update" : "Save"}`
                )}
              </button>
              <button
                className="receipt-modal-download-button bg-[#FF5E59] w-[130px] h-[40px] flex justify-center items-center text-white font-bold rounded"
                onClick={downloadPDF}
              >
                {downloadBtnLoading ? <ButtonLoader2 /> : "Preview"}
              </button>
            </div>
          </div>
        )}
      </div>
      {cvData && (
        <>
          <PersonalInformation
            isOpenModal={personalInformation}
            setIsOpenModal={setPersonalInformation}
            data={cvData}
            onSave={handleSavePersonalInformation}
            setShowPicture={setShowPicture}
            showPicture={showPicture}
            template_id={template_id}
          />
          <EmploymentHistory
            isOpenModal={employmentHistory}
            setIsOpenModal={setEmploymentHistory}
            data={cvData}
            onSave={handleSaveEmploymentHistory}
          />
          <Education
            isOpenModal={education}
            setIsOpenModal={setEducation}
            data={cvData}
            onSave={handleSaveEducationHistory}
          />
          <Skills
            isOpenModal={skills}
            setIsOpenModal={setSkills}
            data={cvData}
            onSave={handleSaveSkills}
          />
          <Reference
            isOpenModal={reference}
            setIsOpenModal={setReference}
            data={cvData}
            onSave={handleSaveReference}
          />{" "}
          <Projects
            isOpenModal={projects}
            setIsOpenModal={setProjects}
            data={cvData}
            onSave={handleSaveProjects}
          />
        </>
      )}
    </>
  );
};

export default CV_Template_3;
