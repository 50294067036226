import React, { Fragment, useState, useRef, useEffect } from "react";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import Browse from "../../assets/images/Browse.png";
import useUploadCV from "../../hooks/useUploadCV";
import { ButtonLoader2 } from "../shared/ButtonLoader";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  job_description: Yup.string().required("Please upload your job description"),
  pdf: Yup.string().required("Please upload your cv"),
});

export default function UploadCV(props) {
  const cancelButtonRef = useRef(null);
  const { loading, uploadCV } = useUploadCV();
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [fileError, setFileError] = useState("");
  // const { clearChatData } = useContext(GlobalContext);

  const initialValues = {
    job_description: "",
    pdf: null,
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("file_upload", values.pdf);
      formData.append("job_description", values.job_description);

      await uploadCV(
        formData,
        props.setAlreadyUploadedCV,
        props.setIsOpenModal,
        setSelectedFile,
        handleClose
      );
    },
  });

  useEffect(() => {
    let interval;
    if (selectedFile) {
      interval = setInterval(() => {
        if (progress < 100) {
          setProgress(progress + 10);
        }
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [progress, selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFileTypes = [".pdf", ".doc", ".docx"];
      const selectedFileType = file.name.toLowerCase();
      const isValidFileType = allowedFileTypes.some((type) =>
        selectedFileType.endsWith(type)
      );

      if (!isValidFileType) {
        setFileError("Please select only docs and pdf file.");
        setSelectedFile(null);
        setFieldValue("pdf", null);
        return;
      }

      setSelectedFile(file);
      setFieldValue("pdf", file);

      const uploadTime = 3;
      let currentTime = 0;

      const timerInterval = setInterval(() => {
        currentTime += 1;
        const timeLeft = uploadTime - currentTime;

        if (timeLeft >= 0) {
          setRemainingTime(timeLeft);
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);

      setFileError("");
    }
  };

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const file = event.dataTransfer.files[0];
  //   if (file) {
  //     setSelectedFile(file);
  //     setFieldValue("pdf", file);
  //     const uploadTime = 3;
  //     let currentTime = 0;

  //     const timerInterval = setInterval(() => {
  //       currentTime += 1;
  //       const timeLeft = uploadTime - currentTime;

  //       if (timeLeft >= 0) {
  //         setRemainingTime(timeLeft);
  //       } else {
  //         clearInterval(timerInterval);
  //       }
  //     }, 1000);
  //   }
  // };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const allowedFileTypes = ["pdf"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isValidFileType = allowedFileTypes.includes(fileExtension);

      if (!isValidFileType) {
        setFileError("Upload only pdf file");
        setSelectedFile(null);
        setFieldValue("file", null);
        return;
      }

      setSelectedFile(file);
      setFileError(null);
      setFieldValue("file", file);

      const uploadTime = 3;
      let currentTime = 0;

      const timerInterval = setInterval(() => {
        currentTime += 1;
        const timeLeft = uploadTime - currentTime;

        if (timeLeft >= 0) {
          setRemainingTime(timeLeft);
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);
      setFileError("");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInMb = sizeInBytes / (1024 * 1024);
    return sizeInMb.toFixed(2) + " MB";
  };

  const fileInputRef = React.useRef(null);
  const removeSelectedFile = () => {
    setSelectedFile(null);
    setProgress(0);
    setRemainingTime(0);
    fileInputRef.current.value = null;
  };
  const handleClose = () => {
    removeSelectedFile();
    setFileError(null);
    props.setIsOpenModal(false);
    resetForm();
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999999999]"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[500px] py-8 px-8 ">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>

                <div className="flex flex-col justify-center items-start w-full">
                  <h1 className="text-[#263238] lg:text-[19px] text-[15px] font-medium mb-2">
                    Please upload CV
                  </h1>
                  <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    className="rounded-[10px] border border-dotted border-[#C6A4FF]  py-4 flex flex-col justify-center items-center w-full mt-2"
                  >
                    <img className="w-[68px] h-[57px]" src={Browse} alt="" />
                    <div className="d-flex align-items-center justify-center text-center">
                      <span className="text-[16px]">
                        Drag and drop to upload <br />
                        or
                      </span>
                      <label
                        className="text-[#C6A4FF] text-[16px] font-normal cursor-pointer underline ml-1"
                        htmlFor="pdf"
                      >
                        Browse
                      </label>
                    </div>
                    <input
                      type="file"
                      id="pdf"
                      accept=".pdf, .doc, .docx"
                      name="pdf"
                      values={values.pdf}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </div>
                  {selectedFile && (
                    <div className="w-full justify-start py-2">
                      <p className="text-[#4E4949] font-normal text-xs">
                        {selectedFile.name}
                      </p>
                      <p className="text-[#4E4949] font-normal text-[10px]">
                        {formatFileSize(selectedFile.size)}
                      </p>
                    </div>
                  )}
                  <div
                    className={`w-full flex ${
                      errors.pdf ? "justify-between" : "justify-end"
                    } items-center mt-[2px] mb-0 pl-0 `}
                  >
                    {fileError ? (
                      <>
                        {fileError && (
                          <div className="text-red-700 text-xs  w-full">
                            {fileError}
                          </div>
                        )}{" "}
                      </>
                    ) : (
                      <>
                        {errors.pdf && touched.pdf && (
                          <p className="text-red-700 text-xs w-full">
                            {errors.pdf}
                          </p>
                        )}
                      </>
                    )}

                    <span className="text-[10px] flex w-full justify-end">
                      PDF
                    </span>
                  </div>
                  <div className="w-full flex items-center justify-center gap-1 mb-3">
                    <div class="w-full bg-[#C6A4FF]  rounded-full h-0.5 dark:bg-[#FF5E59]">
                      <div
                        className="h-0.5 rounded-full"
                        style={{
                          width: `${progress}%`,
                          background: progress === 100 ? "#40ff60" : "#FF5E59",
                        }}
                      ></div>
                      <span className="text-[#4E4949] text-[10px]">{`${progress}%`}</span>
                    </div>
                    <AiOutlineCloseCircle
                      onClick={removeSelectedFile}
                      className="cursor-pointer"
                    />
                  </div>
                  <h1 className="text-[#263238] lg:text-[19px] text-[15px] font-medium mb-2 pt-2">
                    Copy and paste job description
                  </h1>{" "}
                  <textarea
                    placeholder="Job description"
                    name="job_description"
                    value={values.job_description}
                    onChange={handleChange}
                    className="border border-[#C6A4FF] w-full h-[100px] py-2 px-3 rounded-[10px] outline-none resize-none"
                  />
                  {errors.job_description && touched.job_description && (
                    <p className="text-red-700 text-xs w-full">
                      {errors.job_description}
                    </p>
                  )}
                  <div className="flex justify-between w-full py-1">
                    {/* <span className="text-[10px] text-[#4E4949]">
                      Time remaining: {remainingTime} sec
                    </span> */}
                  </div>
                  <div className="flex w-full gap-2 justify-end mt-5">
                    <button
                      className="bg-[#4E494933] h-[40px] w-[100px] flex items-center justify-center rounded-[5px]  cursor-pointer text-[#263238]"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="bg-[#FF5E59] h-[40px] w-[100px] flex items-center justify-center rounded-[5px] outline-none text-white cursor-pointer"
                    >
                      {loading ? <ButtonLoader2 /> : "Submit"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
