import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const useForgotPassword = () => {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const sendEmail = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/accounts/api/sendOTP/`, payload)
      .then((response) => {
        setLoading(false);
        Navigate(`/verify-otp/${payload.email}`);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, sendEmail };
};

export default useForgotPassword;
