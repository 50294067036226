import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import Navbar from "../../components/shared/Navbar";
import Footer from "../../components/shared/Footer";
import AvatarModal from "../../components/modals/AvatarModal";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import { ButtonLoader2 } from "../../components/shared/ButtonLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import Edit from "../../assets/images/Edit.png";
import demo_avatar from "../../assets/images/profilepic.png";
import "../../style/Circle.css";
import { IoMdArrowBack } from "react-icons/io";
import { avatars_list } from "../../static-data/AvaratsList";
import BackButton from "../../components/shared/BackButton";

// const validationSchema = Yup.object().shape({
//   firstname: Yup.string()
//     .required("First Name is required.")
//     .matches(/^[ A-Za-z/]+$/, "First Name must be only characters"),
// });

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("First Name is required.")
    .matches(
      /^[ A-Za-z]*[A-Za-z][ A-Za-z]*$/,
      "First Name must be only characters and can't be just spaces"
    )
    .max(20, "First Name must be at most 20 characters long"),
  lastname: Yup.string()
    .matches(
      /^[ A-Za-z]*[A-Za-z][ A-Za-z]*$/,
      "last Name must be only characters and can't be just spaces"
    )
    .max(20, "Last Name must be at most 20 characters long"),
});

const ProfileData = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const { userInfo } = useContext(GlobalContext);
  const { loading, UpdateProfile } = useUpdateProfile();
  const [showAvatar, setShowAvatar] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [picture, setPicture] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const Navigate = useNavigate();

  const handleSaveAvatar = (data) => {
    setAvatar(data);
    setPicture(null);
    setImageSrc(avatars_list[data]?.avatar);
  };

  const handleSavePicture = (file) => {
    setPicture(file);
    setAvatar(null);
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const initialValues = {
    firstname: userInfo?.first_name,
    lastname: userInfo?.last_name,
    email: userInfo?.email,
    profile_pic: userInfo?.profile_pic,
    avatar_url: userInfo?.avatar_url,
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        if (picture) {
          await UpdateProfile({
            first_name: values.firstname,
            last_name: values.lastname,
            profile_pic: picture,
            avatar_url: "",
          });
        } else if (avatar !== null || avatar === 0) {
          await UpdateProfile({
            first_name: values.firstname,
            last_name: values.lastname,
            profile_pic: "",
            avatar_url: avatar,
          });
        } else {
          await UpdateProfile({
            first_name: values.firstname,
            last_name: values.lastname,
          });
        }
      },
    });

  const goback = () => {
    Navigate("/profile");
  };

  return (
    <>
      <Navbar />
      <div className="profile_backgroundImage grid lg:grid-cols-[30%_minmax(70%,_1fr)] lg:mt-[75px] xs:mt-[70px] py-5 mb-5 bg-contain bg-no-repeat min-h-[75vh] xs:px-2 lg:px-0">
        <div className="h-[30px] cursor-pointer  px-10 lg:flex xs:hidden ">
          <Link to="/profile">
            <BackButton />
          </Link>
        </div>
        <div className="h-auto lg:w-[500px] 2xl:w-[50%] w-full flex flex-col justify-center items-center rounded-[20px] gap-3 relative py-10 lg:shadow-profile xs:shadow-profile lg:px-8 xs:px-6">
          <div className="absolute top-3 left-3 lg:hidden xs:block">
            <Link to="/profile">
              <IoMdArrowBack className="text-[30px]" />
            </Link>
          </div>
          <h1 className="text-[#263238] font-semibold text-[44px]">
            Edit Profile
          </h1>
          <div className="large-circle">
            {imageSrc ? (
              <img
                className="rounded-full w-full h-full"
                src={imageSrc}
                style={{ aspectRatio: "1/1", objectFit: "cover" }}
                alt=""
              />
            ) : (
              <>
                {userInfo?.profile_pic && (
                  <img
                    className="rounded-full w-full h-full"
                    style={{ aspectRatio: "1/1", objectFit: "cover" }}
                    src={`${BASE_URL}/${userInfo.profile_pic}`}
                    alt=""
                  />
                )}
                {userInfo?.avatar_url && (
                  <img
                    className="rounded-full w-full h-full"
                    style={{ aspectRatio: "1/1", objectFit: "cover" }}
                    src={avatars_list[userInfo.avatar_url]?.avatar}
                    alt=""
                  />
                )}
                {!userInfo?.profile_pic && !userInfo?.avatar_url && (
                  <img
                    className="rounded-full w-full h-full"
                    style={{ aspectRatio: "1/1", objectFit: "cover" }}
                    src={demo_avatar}
                    alt=""
                  />
                )}
              </>
            )}

            <div
              onClick={() => setShowAvatar(true)}
              className="flex items-center justify-center bg-[#ffff] rounded-full w-[50px] h-[50px] border-[2px] border-[#C6A4FF] absolute left-[80px] bottom-[-33px] cursor-pointer "
            >
              <img
                src={Edit}
                alt=""
                className="lg:w-[25px] lg:h-[25px] xs:w-[13px] xs:h-[14px]"
              />
            </div>
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label className="font-semibold lg:text-[18px]">First name</label>
            <input
              name="firstname"
              value={values.firstname}
              onChange={handleChange}
              className="border border-[#DFDFDF] w-full lg:h-[60px] xs:h-[37px] px-4 rounded-[10px] lg:text-[18px] "
              placeholder="Enter First Name..."
            />
            {errors.firstname && touched.firstname && (
              <p className="text-red-700 text-xs">{errors.firstname}</p>
            )}
            <label className="font-semibold lg:text-[18px]">Last name</label>
            <input
              name="lastname"
              value={values.lastname}
              onChange={handleChange}
              className="border border-[#DFDFDF] w-full lg:h-[60px] xs:h-[37px] px-4 rounded-[10px] lg:text-[18px]"
              placeholder="Enter Last Name..."
            />
            {errors.lastname && touched.lastname && (
              <p className="text-red-700 text-xs">{errors.lastname}</p>
            )}
            <label className="font-semibold lg:text-[18px]">Email</label>
            <input
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly
              placeholder="Enter Email..."
              className="border border-[#DFDFDF]w-full lg:h-[60px] xs:h-[37px] px-4 rounded-[10px] lg:text-[18px]"
            />
            {errors.name && touched.name && (
              <p className="text-red-700 text-xs">{errors.name}</p>
            )}
            <div className="w-full flex lg:justify-end xs:justify-center items-center lg:mt-3 xs:mt-4 gap-2">
              <button
                onClick={goback}
                className="bg-[#4E494933] lg:w-[140px] xs:w-[113px] lg:h-[50px] xs:h-[36px]  rounded-[5px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="bg-[#FF5E59] lg:w-[140px] xs:w-[110px] lg:h-[50px] xs:h-[35px] flex justify-center items-center text-white rounded-[5px]"
              >
                {loading ? <ButtonLoader2 /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <AvatarModal
        showAvatar={showAvatar}
        setShowAvatar={setShowAvatar}
        handleSaveAvatar={handleSaveAvatar}
        handleSavePicture={handleSavePicture}
      />
    </>
  );
};

export default ProfileData;
