import React, { Fragment, useState, useRef, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Dialog, Transition } from "@headlessui/react";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../style/Editor.css";

export default function UploadCV(props) {
  const cancelButtonRef = useRef(null);
  const { data, onSave } = props;
  const [cvData, setCvData] = useState(data);
  useEffect(() => {
    setCvData(data);
  }, [data]);

  const handleInputChange = (index, field, value) => {
    setCvData((prevState) => {
      const updatedReference = [...prevState.reference];
      updatedReference[index] = {
        ...updatedReference[index],
        [field]: value,
      };
      return {
        ...prevState,
        reference: updatedReference,
      };
    });
  };
  const addReference = () => {
    if (cvData?.reference.length < 2) {
      setCvData((prevState) => ({
        ...prevState,
        reference: [...prevState.reference, {}],
      }));
    }
  };

  const removeReference = (index) => {
    setCvData((prevState) => {
      if (prevState.reference.length === 0) {
        return prevState;
      }
      const updatedReference = [...prevState.reference];
      updatedReference.splice(index, 1);
      return {
        ...prevState,
        reference: updatedReference,
      };
    });
  };

  const handleSubmit = () => {
    onSave(cvData?.reference);
  };
  const [referenceVisibility, setReferenceVisibility] = useState(
    cvData?.reference.map(() => false)
  );

  const handleArrowClick = (index) => {
    setReferenceVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };
  const [editorContents, setEditorContents] = useState(() =>
    cvData?.education.map((education) => education.description || "")
  );
  const onEditorStateChange = (index, newEditorContent) => {
    setEditorContents((prevEditorContents) => {
      const updatedEditorContents = [...prevEditorContents];
      updatedEditorContents[index] = newEditorContent;

      setCvData((prevState) => {
        const updatedReference = [...prevState.reference];
        updatedReference[index] = {
          ...updatedReference[index],
          reference_text: newEditorContent,
        };
        return {
          ...prevState,
          reference: updatedReference,
        };
      });

      return updatedEditorContents;
    });
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999999]"
        initialFocus={cancelButtonRef}
        onClose={props.setIsOpenModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[500px] py-4 px-8 ">
                {/* <div className="absolute top-[10px] right-[10px] cursor-pointer">
                 */}
                <div className="flex justify-between items-center">
                  <h1 className="text-[#263238] font-medium text-[30px]">
                    Reference
                  </h1>
                  <AiOutlineClose
                    className="absolute top-[10px] right-[10px] cursor-pointer"
                    onClick={() => props.setIsOpenModal(false)}
                  />
                </div>

                <div className="flex flex-col justify-center items-center w-full    ">
                  <div className="max-h-[300px] overflow-y-scroll pe-2 mt-4">
                    {cvData?.reference?.map((data, index) => (
                      <div className="w-full h-full border border-[#EBEBEB] rounded-[20px] py-2 px-4 gap-4 flex flex-col mb-4">
                        <div className="w-full flex justify-between">
                          <span style={{ cursor: "pointer" }}>
                            Reference {index + 1}
                          </span>
                          <div className="flex justify-center gap-2">
                            <RiDeleteBin6Line
                              onClick={() => removeReference(index)}
                              className="cursor-pointer"
                            />

                            <div onClick={() => handleArrowClick(index)}>
                              {referenceVisibility[index] ? (
                                <MdKeyboardArrowDown className="text-[20px]" />
                              ) : (
                                <MdKeyboardArrowUp className="text-[20px]" />
                              )}
                            </div>
                          </div>
                        </div>
                        {!referenceVisibility[index] && (
                          <form>
                            <div className="w-full mb-2">
                              <label>Person name</label>
                              <input
                                type="text"
                                placeholder="John Doe"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.person_name || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "person_name",
                                    e.target.value
                                  )
                                }
                              />
                            </div>

                            <div className="w-full mb-2">
                              <label>Contact number</label>
                              <input
                                type="text"
                                placeholder="+92000000000"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.contact_number || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "contact_number",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="w-full mb-2">
                              <label>Description</label>
                              <div className="border border-[#EBEBEB] px-2 py-1 rounded-[10px]">
                                <ReactQuill
                                  value={editorContents[index]}
                                  onChange={(value) =>
                                    onEditorStateChange(index, value)
                                  }
                                  modules={{
                                    toolbar: [
                                      ["bold", "italic", "underline", "strike"],
                                      [{ list: "bullet" }],
                                      [{ align: [] }],
                                      [{ color: [] }, { background: [] }],
                                    ],
                                    clipboard: {
                                      matchVisual: false,
                                    },
                                  }}
                                  formats={[
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "list",
                                    "bullet",
                                    "align",
                                    "color",
                                    "background",
                                    "size",
                                  ]}
                                />
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                    ))}
                  </div>

                  <div
                    onClick={addReference}
                    className="flex items-center justify-center w-full border border-[#FF5E59] text-[#FF5E59] py-[10px] rounded-[5px] gap-1 mt-4 "
                  >
                    <GoPlus className="text-[25px]" />
                    <button className="text-[16px] font-normal ">
                      Add reference
                    </button>
                  </div>
                  <div className="flex justify-end w-full gap-4 py-4 ">
                    <button
                      onClick={() => props.setIsOpenModal(false)}
                      className="bg-[#4E494933] bg-opacity-[60%] text-[black] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-[#FF5E59]  text-[#fff] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
