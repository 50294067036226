import { useContext, useState } from "react";
import axios from "axios";
import GlobalContext from "../context/GlobalContext";
import Cookies from "js-cookie";

const useDeleteFavouriteTemp = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [deleteFavLoading, setDeleteFavLoading] = useState(null);
  const { setCheckDeleteStatus } = useContext(GlobalContext);

  const token = localStorage.getItem("token") || Cookies.get("token");

  const DeleteFavouriteTemp = async (tempId) => {
    setDeleteFavLoading(tempId);
    await axios
      .delete(`${BASE_URL}/api/favorite/?id=${tempId}`, {
        data: {
          id: tempId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCheckDeleteStatus(true);
        setDeleteFavLoading(null);
      })

      .catch((error) => {
        setDeleteFavLoading(null);
      });
  };

  return {
    deleteFavLoading,
    DeleteFavouriteTemp,
  };
};

export default useDeleteFavouriteTemp;
