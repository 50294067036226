import React from "react";

const StandOut = () => {
  return (
    <div className="w-full lg:h-[80vh] xs:h-[40vh] lg:px-[60px] xs:px-[20px] flex flex-col relative lg:mt-8 xs:mt-0">
      <div
        className="w-full h-full flex items-end justify-center bg-center bg-no-repeat standout_bg "
        style={{
          backgroundImage: "url(/images/standOutBG.svg)",
          backgroundSize: "100% 100%",
        }}
      >
        {/* <div className="absolute xl:top-[60%] lg:top-[50%] xs:top-[55%] xl:left-[28%] lg:left-[22%] sm:left-[18%] xs:left-[23%] flex flex-col gap-3"> */}
        <div className="flex flex-col gap-5 justify-center h-[50%] xs:pt-[10px]">
          <span className="text-[#263238] font-medium text-center xl:text-[18px] lg:text-[22px] xs:text-[12px] xs:hidden lg:flex ">
            All Crewdog’s CVLab templates are ATS compliant so you <br /> can
            chase that issue away.
          </span>
          <span className="text-[#263238] font-medium text-center xss:text-[11px] xs:text-[9px]  xss:flex lg:hidden ">
            All Crewdog’s CVLab templates are ATS <br /> compliant so you can
            chase that issue away.
          </span>
          <div className="flex justify-center items-center lg:gap-10 xs:gap-3 ">
            <h1 className="lg:text-[26px] xs:text-[12px] font-semibold">
              Source
            </h1>
            <p className="border-r border-[#E0E0E0] "></p>
            <span className="text-[#FF5E59] font-normal lg:text-[26px] xs:text-[10px] ">
              StandoutCV
            </span>
          </div>
        </div>
        {/* </div> */}
      </div>
      <TotalInterviewAVg />
    </div>
  );
};

export default StandOut;

function TotalInterviewAVg() {
  return (
    <>
      <div className="flex justify-between items-center w-full lg:gap-10 xs:gap-4">
        <div className="bg-[#F8F8F8] lg:w-[32%] xs:w-[150px] lg:h-[100px] xs:h-[45px] lg:rounded-[230px] xs:rounded-[78px] flex flex-col items-center justify-center">
          <h1 className="font-medium text-[#263238] lg:text-[22px] xs:text-[9px]">
            Total users
          </h1>
          <span className="text-[#FF5E59] font-bold lg:text-3xl xs:text-[14px]">
            1,587,632
          </span>
        </div>
        <div className="bg-[#F8F8F8] lg:w-[32%] xs:w-[150px] lg:h-[100px] xs:h-[45px] lg:rounded-[230px] xs:rounded-[78px] flex flex-col items-center justify-center">
          <h1 className="font-medium text-[#263238] lg:text-[22px] xs:text-[9px]">
            Interview rate
          </h1>
          <span className="text-[#FF5E59] font-bold lg:text-3xl xs:text-[14px]">
            62.18%
          </span>
        </div>
        <div className="bg-[#F8F8F8] lg:w-[32%] xs:w-[150px] lg:h-[100px] xs:h-[45px] lg:rounded-[230px] xs:rounded-[78px] flex flex-col items-center justify-center">
          <h1 className="font-medium text-[#263238] lg:text-[22px] xs:text-[9px]">
            Avg. user review
          </h1>
          <span className="text-[#FF5E59] font-bold lg:text-3xl xs:text-[14px]">
            8.23/10
          </span>
        </div>
      </div>
    </>
  );
}
