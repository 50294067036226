import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import useGetCurrentUser from "./useGetCurrentUser";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const useUpdateProfile = () => {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const [loading, setLoading] = useState(false);
  const { getCurrentUser } = useGetCurrentUser();
  const token = localStorage.getItem("token") || Cookies.get("token");

  const UpdateProfile = async (payload) => {
    setLoading(true);
    await axios
      .patch(`${BASE_URL}/api/accounts/profile/update/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        getCurrentUser(token);
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Profile updated successfully",
          customClass: {
            confirmButton: "custom-green-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Navigate("/");
          }
        });
      })
      .catch((error) => {
        setLoading(false);

        Swal.fire({
          icon: "error",
          text: "An error occurred. Please try again",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, UpdateProfile };
};

export default useUpdateProfile;
