import axios from "axios";

const useGetBlogs = () => {
  const GetBlogsData = async () => {
    await axios
      .get(`https://www.ogtc.ai/articles`)
      .then((response) => {
        localStorage.setItem("blogs", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return { GetBlogsData };
};

export default useGetBlogs;
