import React from "react";

function DataDeletion() {
  return (
    <div className="py-4 px-4">
      <h1>Control Your Data: Data Deletion Options</h1>** **
      <p>
        Your privacy is important to us. We respect your right to control your
        personal information. This page outlines how you can delete data
        associated with your Facebook account through our website.
      </p>
      ** **<h2>How to Delete Your Data</h2>** **
      <p>We offer two ways to delete your data:</p>** **
      <ol>
        <li>
          <strong>Delete specific information:</strong> You can selectively
          delete individual posts, photos, comments, and other content you've
          shared on our website. To do this, navigate to the specific item and
          click the "Delete" option.
        </li>
        <li>
          <strong>Permanently delete your account:</strong> If you want to
          completely remove your account and all associated data from our
          systems, you can request account deletion. This action is
          irreversible, so please consider it carefully.
        </li>
      </ol>
      ** **<h2>Request Account Deletion</h2>** **
      <p>To request account deletion, please follow these steps:</p>** **
      <ol>
        <li>Visit your account settings page.</li>
        <li>Click on the "Delete Account" option.</li>
        <li>Review the information provided and confirm your decision.</li>
        <li>Enter your password to securely verify your identity.</li>
        <li>Click "Delete Account" to initiate the process.</li>
      </ol>
      ** **
      <p>
        Once you've submitted your request, we'll begin the deletion process.
        This may take up to 90 days to fully complete. During this time, your
        account will be deactivated and inaccessible. After the deletion process
        is finalized, your data will be permanently removed from our systems.
      </p>
      ** **<h2>Important Notes</h2>** **
      <ul>
        <li>
          Account deletion is irreversible. Once your account is deleted, you
          will not be able to retrieve any of your data.
        </li>
        <li>
          Some information, such as activity logs and certain messages, may be
          retained for a limited period to comply with legal obligations or for
          security purposes.
        </li>
        <li>
          If you have any questions or concerns, please contact us through our
          support channels.
        </li>
      </ul>
      ** **
      <p>We appreciate your trust in us and strive to protect your privacy.</p>
      **
    </div>
  );
}

export default DataDeletion;
