/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";

const CategoryPage = () => {
  const { category } = useParams();
  const data = localStorage.getItem("blogs");

  const filteredBlogs = data
    ? JSON.parse(data).filter((blog) => blog.category === category)
    : [];

  return (
    <>
      <Navbar />
      <div className="w-full min-h-[100vh] xs:py-[50px] md:py-[100px] xs:px-[20px] md:px-[5%]">
        <h2 className="font-extrabold text-[#263238] text-[30px] mb-4">
          {category || "Mismatch"}
        </h2>
        <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {filteredBlogs.map((blog) => (
            <Link
              key={blog.id}
              to={`/blogs/${blog.title}/${blog.id}`}
              className="relative w-full h-[200px] overflow-hidden rounded-[10px]"
              style={{
                backgroundImage: `url(${blog.image_url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-5 text-white">
                <span className="bg-[#FF5E59] text-xs border border-white font-medium p-2 rounded-[5px] w-[80px] text-center">
                  {blog?.category || "Mismatch"}
                </span>
                <h3 className="font-bold text-sm h-[40px] mt-1">
                  {blog.title.length > 50
                    ? `${blog.title.slice(0, 50)}...`
                    : blog.title}
                </h3>
                <div className="flex items-center gap-4 pt-1 text-sm">
                  <div className="flex items-center gap-2">
                    <img src="/images/blog-profile.svg" alt=" " />
                    <p>{blog.author}</p>
                  </div>
                  <p>{blog.date}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryPage;
