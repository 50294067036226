/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import UploadCV from "./modals/UploadCv";
import ShowAllCV from "./modals/ShowCV's";
import Logo from "../assets/images/main.png";
import UserAvatar from "../assets/images/UserAvatar.png";
import Icon from "../assets/images/usericon.svg";
import "../style/ConversationView.css";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { avatars_list } from "../static-data/AvaratsList";
import Upload from "../assets/images/upload.png";
import Job from "../assets/images/job.png";
import Temp from "../assets/images/temp.png";
import DownloadCard from "../assets/images/downloadCard.png";

function ChatView(props) {
  const Navigate = useNavigate();
  const {
    chatData,
    chatLoading,
    errorMessage,
    clearChatData,
    secondQuestion,
    setSecondQuestion,
    setActiveButton,
    userInfo,
    showMakeCVQuestion,
    setShowMakeCVQuestion
  } = useContext(GlobalContext);

  const [uploadCVModal, setUploadCVModal] = useState(false);
  const [showCV, setShowCV] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const messageEndRef = useRef(null);
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end"
    });
  }, [chatData, props.debugMessage, chatLoading, errorMessage]);

  const copyResponses = (message) => {
    navigator.clipboard.writeText(message);
  };

  const FormatResponse = (data) => {
    var jsonString = data
      .replace(/\\\\/g, "\\")
      .replace(/\\n/g, "\n")
      // eslint-disable-next-line
      .replace(/[\x00-\x1F\x7F-\x9F]/g, "");
    var jsonObject = JSON?.parse(jsonString);
    return (
      <div className="xs:text-[12px] lg:text-[18px]">
        {jsonObject?.personal_information?.profile_pic && (
          <img
            className="rounded-[100px] object-cover w-[80px] h-[80px] mb-2 mt-2"
            style={{ aspectRatio: "1/1", objectFit: "cover" }}
            src={`${BASE_URL}/${jsonObject?.personal_information?.profile_pic}`}
            alt={"name"}
          />
        )}
        {jsonObject?.personal_information?.summary && (
          <h1 className="font-bold">Summary:</h1>
        )}
        <p>{jsonObject?.personal_information?.summary}</p>

        <h1 className="font-bold mt-2">Personal Info:</h1>
        <p>Name: {jsonObject?.personal_information?.name}</p>
        <p>Email: {jsonObject?.personal_information?.email}</p>
        <p>Phone: {jsonObject?.personal_information?.number}</p>
        <p>Address: {jsonObject?.personal_information?.address}</p>
        <p>
          LinkedIn:{" "}
          <a
            className="text-[#0066CC]"
            href={`${jsonObject?.personal_information?.linkedin}`}
            target="_blank"
          >
            Profile
          </a>
        </p>

        {jsonObject?.skills.length && (
          <h1 className="font-bold mt-2">Skills:</h1>
        )}
        <div>
          {jsonObject?.skills?.map((data, index) => (
            <div key={index}>
              <span className="bullet-point">&#8226;</span>
              <span className="ml-4">{data.name}</span>
            </div>
          ))}
        </div>

        {jsonObject?.education.length && (
          <h1 className="font-bold mt-2">Education:</h1>
        )}
        <div>
          {jsonObject?.education?.map((data, index) => (
            <div key={index} className="mb-4">
              <p>Degree: {data.field_of_study}</p>
              <p>Institute: {data.institute_name}</p>
              <p>City: {data.city}</p>
              <p>Country: {data.country}</p>
              <p>Start date: {data.start_date}</p>
              <p>End date: {data.end_date ? data.end_date : "Present"}</p>
              <p>Description: {data.description}</p>
            </div>
          ))}
        </div>

        {jsonObject?.employment_history.length && (
          <h1 className="font-bold mt-2">Employment History:</h1>
        )}
        <div>
          {jsonObject?.employment_history?.map((data, index) => (
            <div key={index} className="mb-4">
              <p>Position: {data.job_title}</p>
              <p>Company: {data.company_name}</p>
              <p>City: {data.city}</p>
              <p>Country: {data.country}</p>
              <p>Start date: {data.start_date}</p>
              <p>End date: {data.end_date ? data.end_date : "Present"}</p>
              <p>Description: {data.description}</p>
            </div>
          ))}
        </div>
        {jsonObject?.projects.length && (
          <h1 className="font-bold mt-2">Projects:</h1>
        )}
        <div>
          {jsonObject?.projects?.map((data, index) => (
            <div key={index} className="mb-4">
              <p>Title: {data?.title || data?.project_name}</p>
              <p>Description: {data.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getback = () => {
    setSecondQuestion(true);
    setShowMakeCVQuestion(null);
    clearChatData();
    props.setAlreadyUploadedCV(null);
  };

  const gotosaved = () => {
    Navigate("/saved");
    setActiveButton("templates");
  };
  const cardData = [
    {
      image: Upload,
      title: "Upload",
      titleColor: "#C6A4FF",
      desc: "Upload your current CV or used a saved one.",
      id: "upload"
    },
    {
      image: Job,
      title: "Job description",
      titleColor: "#263238",
      desc: "Copy and paste the job description",
      id: "job"
    },
    {
      image: Temp,
      title: "Choose template",
      titleColor: "#FF5E59",
      desc: "Review your new CV and choose a template",
      id: "template"
    },
    {
      image: DownloadCard,
      title: "Download",
      titleColor: "#06A3F5",
      desc: "Now you can save or download it!",
      id: "download"
    }
  ];
  return (
    <>
      <div id="conversation-view">
        <div className="conversation-box ">
          {chatData?.map((data, chatIndex) => (
            <div key={chatIndex}>
              {data.role === "user" && (
                <div className="w-full h-auto flex flex-col items-end py-4 lg:pr-[3%] xs:pr-[3%]">
                  <div className="lg:w-[55%] xs:w-[80%] flex items-center relative">
                    {userInfo?.profile_pic && (
                      <img
                        src={`${BASE_URL}/${userInfo?.profile_pic}`}
                        alt=""
                        className="rounded-full border-4 border-white absolute lg:top-[-30px] lg:right-[-30px] xs:top-[-10px] xs:right-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                      />
                    )}
                    {userInfo?.avatar_url && (
                      <img
                        src={`${avatars_list[userInfo?.avatar_url].avatar}`}
                        alt=""
                        className="rounded-full border-4 border-white absolute lg:top-[-30px] lg:right-[-30px] xs:top-[-10px] xs:right-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                      />
                    )}
                    {!userInfo?.profile_pic && !userInfo?.avatar_url && (
                      <img
                        src={UserAvatar}
                        alt=""
                        className="rounded-full border-4 border-white absolute lg:top-[-30px] lg:right-[-30px] xs:top-[-10px] xs:right-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                      />
                    )}
                    <p
                      className="w-full bg-opacity-[10%] py-4 px-6  lg:text-base xs:text-[10px] text-[#4E4949] rounded-dummy"
                      style={{
                        background: "rgba(255, 94, 89, 0.1)",
                        borderRadius: "20px 0px 20px 20px"
                      }}
                    >
                      {data.content}
                    </p>
                  </div>
                </div>
              )}

              {data.role === "question" && (
                <div className="receiver-div relative px-5 mb-[10px]">
                  <div>
                    <img
                      src={Icon}
                      alt=""
                      className="absolute lg:top-[-30px] lg:left-[-30px] xs:top-[-10px] xs:left-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                    />
                  </div>
                  <div className="receiver-message ">
                    {data.content?.split("\n").map((message, index) => (
                      <div key={index} className="mb-3 text-base break-words">
                        <p>{message}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {data.role === "cv_data" && (
                <div className="lg:w-full h-auto  w-full flex flex-col 2xl:justify-center py-6 lg:pl-[3%] xs:pl-[3%]">
                  <div className="lg:w-[70%] xs:w-[80%] flex items-center relative">
                    <img
                      src={Icon}
                      alt=""
                      className="rounded-full border-4 border-white absolute lg:top-[-30px] lg:left-[-30px] xs:top-[-10px] xs:left-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                    />
                    <p className="bg-[#C6A4FF] w-full bg-opacity-[10%] py-4 px-6  lg:text-base xs:text-[10px] text-[#4E4949] rounded-dummy">
                      {FormatResponse(data.content)}
                    </p>
                  </div>
                </div>
              )}

              {data.role === "error" && (
                <div className="lg:w-full h-auto pt-8  w-full flex flex-col 2xl:justify-center py-4 lg:pl-[3%] xs:pl-[3%]">
                  <div className="lg:w-[55%] xs:w-[80%] flex items-center relative">
                    <img
                      src={Icon}
                      alt=""
                      className="rounded-full border-4 border-white absolute lg:top-[-30px] lg:left-[-30px] xs:top-[-10px] xs:left-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                    />
                    <p className="bg-[#C6A4FF] w-full bg-opacity-[10%] py-4 px-6  lg:text-base xs:text-[10px] text-[#4E4949] rounded-dummy">
                      {data.content}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}

          {showMakeCVQuestion && (
            <div className="lg:w-full h-auto  w-full flex flex-col 2xl:justify-center py-4 lg:pl-[3%] xs:pl-[3%]">
              <div className="lg:w-[55%] xs:w-[80%] flex items-center relative">
                <img
                  src={Icon}
                  alt=""
                  className="rounded-full border-4 border-white absolute lg:top-[-30px] lg:left-[-30px] xs:top-[-10px] xs:left-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                />
                <p className="bg-[#C6A4FF] w-full bg-opacity-[10%] py-4 px-6  lg:text-base xs:text-[10px] text-[#4E4949] rounded-dummy">
                  Do you want to choose a template now?
                </p>
              </div>
              <div className="lg:w-[55%] xs:w-[80%] mt-2 lg:px-0 xs:px-2 flex flex-col gap-2 ">
                <button
                  className="border border-[#C6A4FF] rounded-bubble bg-[#FFFFFF] lg:text-base xs:text-[10px] py-2 cursor-pointer"
                  onClick={getback}
                >
                  No
                </button>
                <button
                  className="border border-[#C6A4FF] rounded-bubble bg-[#FFFFFF] lg:text-base xs:text-[10px] py-2 cursor-pointer"
                  onClick={gotosaved}
                >
                  Yes
                </button>
              </div>
            </div>
          )}

          {chatLoading && (
            <div className="receiver-div d-flex align-items-center mb-[30px]">
              <img src={Icon} alt="" className="w-[50px]" />
              <CVLabLoader
                progress={props.progress}
                setProgress={props.setProgress}
                textIndex={props.textIndex}
                setTextIndex={props.setTextIndex}
              />
            </div>
          )}

          <div ref={messageEndRef} />

          {secondQuestion && (
            <div className="w-full flex flex-col items-start justify-between pt-[10px] h-[100%]">
              <div className="">
                <div className="grid grid-cols-1 lg:grid-cols-4 xs:grid-cols-2 gap-3 w-full px-[10px] ">
                  {cardData.map((item, index) => (
                    <div
                      className={`flex rounded-[10px] bg-[#fff] lg:p-[16px] xs:p-[9px] items-center justify-start flex-col gap-3 ${
                        index === 2
                          ? "lg:col-span-1 xs:col-span-1"
                          : "lg:col-span-1 xs:col-span-1"
                      }`}
                      style={{
                        boxShadow: "0px 0px 20px 1px rgba(183, 147, 255, 0.30)"
                        // height: "120px",
                      }}
                      key={index}
                    >
                      <div className=" w-full flex items-center justify-start gap-3 h-[60%]">
                        <img
                          src={item.image}
                          alt=""
                          className="w-[36px] h-[40px]"
                        />
                        <span
                          className={`lg:text-[18px] xs:text-[14px]  font-[700] text-[${item.titleColor}]`}
                        >
                          {item.title}
                        </span>
                      </div>
                      <span className="lg:text-[14px] xs:text-[11px] text-[#4E4949] font-normal text-left w-full xs:mt-1 h-[40%] ">
                        {item.desc}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="lg:w-full  w-full flex flex-col 2xl:justify-center py-4 lg:pl-[3%] xs:pl-[3%]">
                <div className="lg:w-[55%] xs:w-[80%] flex items-center relative">
                  <img
                    src={Icon}
                    alt=""
                    className="rounded-full border-4 border-white absolute lg:top-[-30px] lg:left-[-30px] xs:top-[-10px] xs:left-[-10px] lg:w-[55px] lg:h-[55px] xs:w-[30px] xs:h-[30px]"
                  />
                  <p className="bg-[#C6A4FF] w-full bg-opacity-[10%] py-4 px-6  lg:text-base xs:text-[10px] text-[#4E4949] rounded-dummy">
                    Let's fine-tune your CV for a new role.
                    <br /> Please choose:
                  </p>
                </div>
                <div className="lg:w-[25%] xs:w-[45%] mt-2 lg:px-0 xs:px-2 flex flex-col gap-2 ">
                  <button
                    className="border w-[60%] border-[#C6A4FF] rounded-bubble bg-[#FFFFFF] lg:text-base xs:text-[10px] py-2 cursor-pointer text-left pl-6"
                    onClick={() => setUploadCVModal(true)}
                  >
                    <span>Upload CV</span>
                  </button>
                  <button
                    className="border w-[80%] border-[#C6A4FF] rounded-bubble bg-[#FFFFFF] lg:text-base xs:text-[10px] py-2 cursor-pointer text-left pl-6"
                    onClick={() => {
                      setShowCV(true);
                    }}
                  >
                    Use My Saved CV's
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <UploadCV
        isOpenModal={uploadCVModal}
        setIsOpenModal={setUploadCVModal}
        setAlreadyUploadedCV={props.setAlreadyUploadedCV}
      />
      <ShowAllCV
        isOpenModal={showCV}
        setIsOpenModal={setShowCV}
        setAlreadyUploadedCV={props.setAlreadyUploadedCV}
      />
    </>
  );
}

export default ChatView;

const CVLabLoader = ({ progress, setProgress, textIndex, setTextIndex }) => {
  const texts = [
    "Analyzing query",
    "Processing",
    "Matching results",
    "Performing actions",
    "Finalizing result",
    "Generating response"
  ];
  const circumference = 2 * Math.PI * 20;
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress >= 100 ? 100 : prevProgress + 5;
        if (newProgress < 100) {
          if (newProgress < 15) {
            setTextIndex(0);
          } else if (newProgress < 30) {
            setTextIndex(1);
          } else if (newProgress < 55) {
            setTextIndex(2);
          } else if (newProgress < 70) {
            setTextIndex(3);
          } else if (newProgress < 100) {
            setTextIndex(4);
          }
        } else {
          setTextIndex(5);
        }
        return newProgress;
      });
    }, 600);
    return () => {
      clearInterval(timer);
    };
  }, [setTextIndex, setProgress]);

  return (
    <div className="ms-4 flex items-center gap-3">
      <svg className="transform -rotate-90 w-[50px] h-[50px]">
        <circle
          cx="25"
          cy="25"
          r="18"
          stroke="currentColor"
          strokeWidth="5"
          fill="transparent"
          className="text-[#F3F3EE]"
        />
        <circle
          cx="25"
          cy="25"
          r="18"
          stroke="currentColor"
          strokeWidth="5"
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (progress / 100) * circumference}
          className="text-[#c6a4ff]"
        />
      </svg>
      <div className="m-0 text-lg font-[500] flex items-end gap-1 ">
        <p>{texts[textIndex]}</p>
        <ThreeDots
          height="20"
          width="20"
          radius="9"
          color="#000"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    </div>
  );
};
