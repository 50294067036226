/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  Fragment,
  useRef,
  useEffect,
  useState,
} from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import GlobalContext from "../../context/GlobalContext";
import useGetUserCVList from "../../hooks/useGetUserCVList";
import MainLoader from "../shared/MainLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ButtonLoader2 } from "../shared/ButtonLoader";
import { FaCheck } from "react-icons/fa6";
import Cookies from "js-cookie";

const validationSchema = Yup.object().shape({
  job_description: Yup.string().required("Job description is required"),
  cv_data: Yup.mixed().required("Please choose a CV"),
});

export default function UploadCV(props) {
  const cancelButtonRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [btnLoading, setBtnLoading] = useState(false);
  const { setCVDetail, setSecondQuestion } = useContext(GlobalContext);
  // const [query, setQuery] = useState("");
  const token = localStorage.getItem("token") || Cookies.get("token");
  const { loading, cvList, getUserCVList } = useGetUserCVList();
  useEffect(() => {
    getUserCVList("");
  }, []);
  const initialValues = {
    job_description: "",
    cv_data: null,
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      setBtnLoading(true);
      chooseCV(values, action);
    },
  });
  const chooseCV = async (data, action, query) => {
    await axios
      .get(
        `${BASE_URL}/api/getCv/?cv_id=${data.cv_data.tempId}&template_id=${data.cv_data.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        props.setAlreadyUploadedCV("UPLOAD_CV");
        setSecondQuestion(false);
        props.setIsOpenModal(false);
        setBtnLoading(false);
        handleClose();
        setCVDetail({
          job_description: data.job_description,
          cv_obj: response.data.cv.cv,
        });
        action.resetForm();
      })
      .catch((error) => {
        setBtnLoading(false);
      });
  };
  const handleClose = () => {
    props.setIsOpenModal(false);
    resetForm();
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999999999]"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[500px] py-8 px-8 ">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>

                <div className="flex flex-col justify-center w-full">
                  <div className="flex flex-col gap-2 mt-2">
                    <label className="text-[#263238] lg:text-[19px] text-[15px] font-medium">
                      Select a CV
                    </label>
                    <div className="flex justify-start items-center gap-4 flex-wrap w-full max-h-[250px] py-2 px-2 overflow-y-scroll">
                      {loading && <MainLoader />}
                      {cvList?.length === 0 && (
                        <h2 className="text-sm">
                          You have not saved any CV yet
                        </h2>
                      )}
                      {cvList?.map((data, index) => (
                        <div
                          className="flex flex-col items-center relative cv-main"
                          key={index}
                        >
                          <div
                            style={{ boxShadow: " 0px 0px 6px 0px #00000040" }}
                          >
                            <img
                              src={data.img}
                              alt=""
                              className="lg:w-[120px] xs:w-[80px] 2xl:w-150px] h-auto"
                            />
                          </div>
                          <span className="mt-2 lg:text-[16px] xs:text-[12px] text-center">
                            Title:{" "}
                            <span className="font-[600]">{data.username}</span>
                          </span>
                          {values?.cv_data?.tempId === data.tempId && (
                            <div className="absolute top-[-5px] right-[-5px] z-[10] bg-[#C6A4FF] rounded-full">
                              <FaCheck className="text-white" />
                            </div>
                          )}
                          <div className="update-main">
                            <button
                              className="update"
                              onClick={() => setFieldValue("cv_data", data)}
                            >
                              {values?.cv_data?.tempId === data.tempId
                                ? "Selected"
                                : "Select"}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    {errors.cv_data && touched.cv_data && (
                      <p className="text-red-700 text-xs">{errors.cv_data}</p>
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-[#263238] lg:text-[19px] text-[15px] font-medium mb-2">
                      Copy and paste job description
                    </label>
                    <textarea
                      placeholder="Enter job description"
                      name="job_description"
                      value={values.job_description}
                      onChange={handleChange}
                      className="border border-[#C6A4FF] w-full h-[100px] py-2 px-3 rounded-[10px] outline-none resize-none"
                    />

                    {errors.job_description && touched.job_description && (
                      <p className="text-red-700 text-xs">
                        {errors.job_description}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-end items-center mt-8 gap-2">
                    <button
                      onClick={handleClose}
                      className="bg-[#4E494933]  h-[40px] w-[100px] rounded-[5px]  cursor-pointer text-[#263238]"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-[#FF5E59] h-[40px] w-[100px] flex items-center justify-center text-white rounded-[5px] border border-[#fffff]"
                      onClick={handleSubmit}
                      type="submit"
                    >
                      {btnLoading ? <ButtonLoader2 /> : "Submit"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
