/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import tree from "../assets/images/tree.jpg";
import logo from "../assets/images/activelogo2.png";

function ContactUs() {
  return (
    <>
      <div className="w-full h-[100vh] flex justify-center items-center bg-slate-50">
        <div className="w-[850px] h-[520px] p-4 flex justify-center items-center bg-slate-100 ">
          <div className="w-[400px] h-[470px] p-4 flex justify-center items-center flex-col  bg-white ">
            <h1 className=" text-[30px] font-[700] mb-4 font-serif text-green-800">
              Contact Us
            </h1>
            <div
              className="w-[300px] h-[300px] px-4 bg-white  flex justify-center items-center flex-col"
              style={{ boxShadow: "0px 0px 5px gray" }}
            >
              <img src={logo} alt="" className="mb-2" />
              <h1 className=" text-[18px] font-[700]  font-serif  text-center my-1 text-green-800">
                Talk to a member replaced by Get in touch with us
              </h1>
              <p className="font-serif text-center text-[14px] my-2 text-green-600">
                If you have any query, get in touch with us. Send an email on
                this address (hr@crewdog.ai) and we will get back to you
              </p>
              <button className="font-semibold font-serif mr-2 bg-green-800 text-white w-full p-2 mt-2">
                Mail to
              </button>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${tree})`,
              backgroundSize: "100% 100%",
            }}
            className="w-[400px] h-[470px] bg-white  flex justify-center items-center flex-col "
          >
            <div
              className="w-[300px] h-[300px]  px-4 bg-white flex justify-center items-center flex-col ml-[-180px]"
              style={{ boxShadow: "0px 0px 5px gray" }}
            >
              <img src={logo} alt="" className="mb-2" />
              <h1 className=" text-[18px] font-[700]  font-serif  text-center my-1 text-green-800">
                Visit us
              </h1>
              <p className="font-serif text-center text-[14px] my-2 text-green-600">
                If you want to pay us a visit, don't hesitate. Look for us at (
                Marischal Square Aberdeen UK AB10 1BL)
              </p>
              <button className="font-semibold font-serif mr-2 bg-green-800 text-white w-full p-2 mt-4">
                Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
