/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../style/JobInput.css";
import TopicIcon from "../assets/images/newtopic.png";
import ReconnectingWebSocket from "reconnecting-websocket";
import Voice from "../assets/images/Voicesearch.png";

import Send from "../assets/images/send.svg";
import SpeechRecognition, {
  useSpeechRecognition
} from "react-speech-recognition";

function JobInput({
  inputQuery,
  setInputQuery,
  setChatData,
  setChatLoading,
  setErrorMessage,
  setBtnLoading,
  btnLoading,
  setDebugMessage,
  webSocket,
  setClearChatData
}) {
  const [isBlinking, setBlinking] = useState(false);

  const ConnectWebSockets = () => {
    webSocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "debug") {
        setDebugMessage((prevMessage) => prevMessage + data.message);
        setChatLoading(false);
        setErrorMessage("");
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        setChatLoading(false);
        const newMessage = { role: "ai", content: data["message"] };
        setChatData((prevMessage) => [...prevMessage, newMessage]);
        setDebugMessage("");
        setErrorMessage("");
      }
      if (data.type === "error") {
        setErrorMessage("an error occurred! please try again later");
        setBtnLoading(false);
        setChatLoading(false);
      }
    };

    webSocket.current.onclose = (e) => {
      setErrorMessage("an error occurred! please try again later");
      setBtnLoading(false);
      setChatLoading(false);
    };

    webSocket.current.onerror = (err) => {
      setErrorMessage("an error occurred! please try again later");
      setBtnLoading(false);
      setChatLoading(false);
    };
  };
  useEffect(() => {
    webSocket.current = new ReconnectingWebSocket(
      `
wss://beta-staging-backend.crewdog.ai/ws/job_search/`
    );
    ConnectWebSockets(webSocket);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const messageWithLineBreaks = inputQuery.replace(/\n/g, "\n");
    webSocket.current?.send(
      JSON.stringify({
        message: inputQuery,
        chat_id: 1
      })
    );
    setDebugMessage("");
    setBtnLoading(true);
    setChatLoading(true);
    setChatData((prevData) => [
      ...prevData,
      { role: "user", content: messageWithLineBreaks }
    ]);
    setInputQuery("");
  };

  const handleChange = (e) => {
    setInputQuery(e.target.value);
  };

  useEffect(() => {
    const textarea = document.querySelector(".input");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [inputQuery]);

  useEffect(() => {
    const textarea = document.querySelector(".input");
    if (textarea) {
      textarea.removeAttribute("style");
      textarea.style.height = "20px";
      textarea.style.minHeight = "20px";
      textarea.style.maxHeight = "100px";
      textarea.style.resize = "none";
      const scrollHeight = textarea.scrollHeight;
      if (scrollHeight <= 150) {
        textarea.style.height = `${scrollHeight}px`;
      } else {
        textarea.style.height = "100px";
      }
      textarea.addEventListener("input", () => {
        const scrollHeight = textarea.scrollHeight;
        if (scrollHeight <= 150) {
          textarea.style.height = `${scrollHeight}px`;
        } else {
          textarea.style.height = "100px";
        }
      });
    }
  }, [inputQuery]);

  const handleVoiceClick = () => {
    if (listening) {
      setBlinking(false);
      SpeechRecognition.stopListening();
    } else {
      setBlinking(true);
      SpeechRecognition.startListening();
    }
  };

  const { transcript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  useEffect(() => {
    if (transcript) {
      setInputQuery(transcript);
    }
  }, [transcript]);
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
  const handlecleardata = () => {
    setChatData("");
    setChatLoading(false);
  };
  return (
    <>
      <div
        className="flex items-center xs:justify-center gap-3 mb-[15px] xs:w-[95%] lg:w-[60%] h-[50px] bg-white rounded-[10px] absolute xs:top-[10px] lg:top-[0px] 2xl:top-[10px]"
        style={{ boxShadow: "0px 0px 5px gray" }}
      >
        <div className="lg:w-full xs:w-full flex md:gap-4 gap-2 items-center justify-center  shadow-custom rounded-[10px] py-1 px-2 ">
          <div
            onClick={handlecleardata}
            className="cursor-pointer bg-[#C6A4FF] lg:w-[117px] xs:w-[30px] xs:h-[20px] lg:h-[34px]   lg:text-xs xs:text-[10px] text-white flex items-center justify-center gap-2 shadow-topic rounded-[5px]"
          >
            <img src={TopicIcon} alt="" className="xs:w-[12px] xs:h-[12px]" />
            <span className="xs:hidden lg:flex">New Topic</span>
          </div>

          <div className="lg:w-full w-full lg:h-[44px]  flex z-[1] items-center justify-center py-1 h-[40px]  box-border border border-[#EBEBEB] rounded-[10px]">
            <textarea
              className="w-full  px-[10px] pt-[9px]  border-none touch-manipulation  focus:outline-none  bg-transparent textarea-custom"
              type="text"
              placeholder="Hello, how can I help you..."
              onChange={(e) => handleChange(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.stopPropagation();
                  if (inputQuery && !btnLoading) {
                    handleSubmit(e);
                  }
                }
              }}
              value={inputQuery}
              style={{
                fontSize: "16px",
                maxHeight: "40px",
                minHeight: "20px",
                resize: "none"
              }}
              required
              name="footer-input"
            />
            <div className="flex items-center ">
              {btnLoading ? (
                <section class="dots-container">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </section>
              ) : (
                <div
                  onClick={
                    inputQuery && !btnLoading
                      ? handleSubmit
                      : (e) => e.preventDefault()
                  }
                  className="generateBtn relative flex items-center justify-cente text-center rounded-[100px] p-[10px] cursor-pointer border-none "
                >
                  <img src={Send} alt="" />
                </div>
              )}
            </div>
          </div>

          <div
            onClick={handleVoiceClick}
            style={{ boxShadow: " 0px 2px 8px 0px #00000040" }}
            className="bg-[#ffff] lg:rounded-[50px] lg:w-[45px] lg:h-[35px] xs:w-[35px] xs:h-[28px] xs:rounded-[50px] flex items-center justify-center cursor-pointer lg:mx-3 xs:mx-0 "
          >
            <img
              className={`lg:w-[15px] xs:w-[7px] lg:h-[18px] xs:h-[12px] relative ${
                isBlinking && listening ? "blinking" : ""
              }`}
              src={Voice}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default JobInput;
