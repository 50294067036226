import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonLoader2 } from "../../components/shared/ButtonLoader";
import userForgotPassword from "../../hooks/useForgotPassword";
import Cookies from "js-cookie";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
});

const ForgotPassword = () => {
  const { loading, sendEmail } = userForgotPassword();
  const initialValues = {
    email: "",
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        Cookies.set("userEmail", values.email);
        await sendEmail({
          email: values.email,
        });
      },
    });

  return (
    <>
      <div className="flex flex-col justify-center w-full h-screen items-center">
        <div className="fixed top-[10px] left-[10px] lg:flex xs:hidden flex items-center justify-start gap-2 ">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={"/images/crewdog_black.svg"} alt="" className="" />
          </a>
          <img src={"/images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img src={"/images/cvlab_black.svg"} alt="" className="" />
          </Link>
        </div>
        <div className="lg:hidden xs:flex justify-center flex items-center gap-2 h-[25vh]">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={"images/crewdog_black.svg"} alt="" className="" />
          </a>
          <img src={"images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img src={"images/cvlab_black.svg"} alt="" className="" />
          </Link>
        </div>
        <div className="lg:w-[40%] 2xl:w-[35%] 3xl:w-[25%] w-full xs:h-[75vh] h-auto flex flex-col justify-center items-center box-border border border-[#FFEFEE] bg-[#fcf4f4] p-forgot rounded-[45px] px-5 xs:pb-2 lg:pb-8 xs:pt-12 lg:pt-16 lg:mt-0 xs:mt-4">
          <h1 className="text-[#263238] lg:text-[44px] xs:text-2xl font-semibold">
            Forgot password
          </h1>
          <span className="text-[#95969D] text-[18px] font-normal my-4 text-center w-full">
            Enter your email for the verification process.
          </span>
          <div className="w-full">
            <label className="mb-1 md:text-[18px] text-[14px]">Email</label>
            <input
              type="text"
              placeholder="Enter email address...."
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className="bg-[#fff] w-full mt-1 py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px] text-[18px]"
            />
            {errors.email && touched.email && (
              <p className="text-red-700 text-xs">{errors.email}</p>
            )}
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-[#FF5E59] w-full h-[50px] text-white rounded-[5px] flex justify-center items-center mt-8 text-[20px]"
          >
            {loading ? <ButtonLoader2 /> : "  Send"}
          </button>

          <div className="flex justify-center items-center gap-1 lg:mt-24 xs:mt-16">
            <span className="text-[#95969D] text-sm font-medium">Back to?</span>
            <Link
              to="/login"
              className="text-[#FF5E59] text-sm font-normal underline"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
