import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const usePostFavouriteTemp = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [addFavLoading, setAddFavLoading] = useState(null);
  const token = localStorage.getItem("token") || Cookies.get("token");

  const FavouriteTemp1 = async (payload) => {
    setAddFavLoading(payload.template_id);
    await axios
      .post(`${BASE_URL}/api/favorite/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAddFavLoading(null);
        Swal.fire({
          icon: "success",
          text: response.data.message,
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
      })
      .catch((error) => {
        setAddFavLoading(null);
        Swal.fire({
          icon: "error",
          text: "An error occurred. Please try again",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { addFavLoading, FavouriteTemp1 };
};

export default usePostFavouriteTemp;
