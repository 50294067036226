export const Data = {
  personal_information: {
    profile_pic: null,
    name: "Lorem Ipsum",
    job_title: "Lorem Ipsum",
    number: "+923001234567",
    email: "lorem@gamil.com",
    address: "2980 Smith Street, Massachusetts, USA",
    summary:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },

  skills: [
    { name: "Lorem Ipsum" },
    { name: "Lorem Ipsum" },
    { name: "Lorem Ipsum" },
    { name: "Lorem Ipsum" },
    { name: "Lorem Ipsum" },
    { name: "Lorem Ipsum" },
  ],

  education: [
    {
      field_of_study: "Lorem Ipsum",
      institute_name: "simply dummy text of the printing",
      city: "Lorem Ipsum",
      country: "Lorem Ipsum",
      start_date: "2021-03-01",
      end_date: "2022-03-01",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
    {
      field_of_study: "Lorem Ipsum",
      institute_name: "simply dummy text of the printing",
      city: "Lorem Ipsum",
      country: "Lorem Ipsum",
      start_date: "2021-03-01",
      end_date: "2022-03-01",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
  ],

  employment_history: [
    {
      company_name: "simply dummy text of the printing",
      job_title: "Lorem Ipsum",
      city: "Lorem Ipsum",
      country: "Lorem Ipsum",
      start_date: "2021-03-01",
      end_date: "2022-03-01",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
    {
      company_name: "simply dummy text of the printing",
      job_title: "Lorem Ipsum",
      city: "Lorem Ipsum",
      country: "Lorem Ipsum",
      start_date: "2021-03-01",
      end_date: "2022-03-01",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
  ],
  projects: [
    {
      title: "simply dummy",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
    {
      title: "simply dummy",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
  ],

  reference: [
    {
      person_name: "Lorem Ipsum",
      contact_number: "+923001234567",
      reference_text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
  ],
};
