// import React from 'react'

// const FeedbackModel = () => {
//   return (
//     <div>FeedbackModel</div>
//   )
// }

// export default FeedbackModel
import React, { useRef, useState, Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../context/GlobalContext";
import Swal from "sweetalert2";
import rate_us_image from "../../assets/images/rate_us_image.png";
import { Browsers, Devices, Rating } from "../../static-data/RateUsEnums";
import { IoIosAttach } from "react-icons/io";
// import { ButtonLoader2 } from "../ButtonLoader";
import { MdOutlineDone } from "react-icons/md";
import { ButtonLoader2 } from "../shared/ButtonLoader";
import Cookies from "js-cookie";

const validationSchema = Yup.object().shape({
  user: Yup.string().required("User required"),
  comments: Yup.string().required("Comments required"),
  result: Yup.string().required("Result required"),
  device: Yup.string().required("Select at least one device "),
  browser: Yup.string().required("Select at least one browser"),
  file: Yup.string().required("Attach required file"),
});

function FeedbackModal({ rateus, setRateus }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("token");
  const { userInfo } = useContext(GlobalContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const [fileError, setFileError] = useState("");

  const initialValues = {
    rating: 0,
    user: userInfo?.id,
    comments: "",
    result: "",
    device: "",
    browser: "",
    file: null,
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFileTypes = [".png", ".jpg", ".jpeg"];
      const selectedFileType = file.name.toLowerCase();
      const isValidFileType = allowedFileTypes.some((type) =>
        selectedFileType.endsWith(type)
      );

      if (!isValidFileType) {
        setFileError("Please select only image files (PNG, JPG, JPEG).");
        setSelectedFile(null);
        setFieldValue("file", null);
        return;
      }

      setSelectedFile(file);
      setFieldValue("file", file);

      setFileError("");
    }
  };

  const { values, errors, handleChange, handleSubmit, touched, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: async (values, action) => {
        setLoading(true);
        await axios
          .post(
            `${BASE_URL}/api/feedback/`,
            {
              user: values.user,
              rating: values.rating,
              comments: values.comments,
              result: values.result,
              device: values.device,
              browser: values.browser,
              file: values.file,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              customClass: {
                confirmButton: "custom-green-button",
              },
            });
            setLoading(false);
            setRateus(false);
            action.resetForm();
          })

          .catch((error) => {
            setLoading(false);
            setRateus(false);

            Swal.fire({
              icon: "error",
              text:
                error?.response?.data?.detail ||
                "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
              customClass: {
                confirmButton: "custom-red-button",
              },
            });
          });
      },
    });

  console.log(errors);

  return (
    <>
      <Transition.Root show={rateus} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[9999999999]"
          initialFocus={cancelButtonRef}
          onClose={() => setRateus(false)}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black bg-opacity-75 py-5">
            <div className="flex min-h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all 2xl:w-[1200px] lg:w-[900px] xs:w-full h-auto xs:mx-2 lg:pl-2 lg:pr-6 lg:py-6">
                  <div className="cursor-pointer absolute right-4 top-4">
                    <AiOutlineClose onClick={() => setRateus(false)} />
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <h1 className="font-[500] text-[40px] text-[#292929]">
                      Rate us!
                    </h1>
                    <p className="font-[600] text-[#5A5A5A]">
                      Rate your experience
                    </p>
                    <div className="mt-2 flex flex-row-reverse  items-center gap-4">
                      {Rating.map((data, index) => (
                        <div key={index}>
                          <img
                            src={data.picture}
                            alt={data.picture}
                            style={{
                              width: "40px",
                              height: "40px",
                              scale: data.id === values.rating ? "1.3" : "1",
                            }}
                            className="cursor-pointer"
                            onClick={() => setFieldValue("rating", data.id)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="w-full flex items-center mt-4">
                    <div className="w-[50%] lg:flex xs:hidden">
                      <img src={rate_us_image} alt="Rate us" />
                    </div>

                    <div className="lg:w-[50%] xs:w-full border bg-[#FFFCFC] rounded-[10px] px-6 py-6">
                      <div className="w-full text-[#263238] flex items-end gap-1">
                        <h1 className="text-[18px] font-[600]">Email</h1>
                        <span className="text-[13px] font-[600]  mb-[3px]">
                          (in case we need to ask for more information)
                        </span>
                      </div>
                      <div className="w-full">
                        <input
                          type="text"
                          placeholder="Email"
                          className="bg-themeColor border border-[#F5FFF5] rounded-[10px] w-full px-5 h-[50px] text-[#263238] placeholder:text-[#828282]  font-[500]  mt-2"
                          value={userInfo?.email}
                        />

                        <input
                          type="text"
                          placeholder="Describe bug/ feature/ suggestion/ comment."
                          className="bg-themeColor border border-[#F5FFF5] rounded-[10px] w-full px-5 h-[50px] text-[#263238] placeholder:text-[#828282] font-[500]  mt-2"
                          name="comments"
                          value={values.comments}
                          onChange={handleChange}
                        />
                        {errors.comments && touched.comments && (
                          <p className="text-red-700 text-xs mt-1">
                            {errors.comments}
                          </p>
                        )}
                        <input
                          type="text"
                          placeholder="What was the actual result?"
                          className="bg-themeColor border border-[#F5FFF5] rounded-[10px] w-full px-5 h-[50px] text-[#263238] placeholder:text-[#828282]  font-[500] mt-2"
                          name="result"
                          value={values.result}
                          onChange={handleChange}
                        />
                        {errors.result && touched.result && (
                          <p className="text-red-700 text-xs mt-1">
                            {errors.result}
                          </p>
                        )}
                      </div>

                      <div className="w-full mt-4">
                        <label className="w-full text-[#263238] text-[18px] font-[600]">
                          Which device are you currently using?
                        </label>
                        <div className="flex items-center gap-4 w-full mt-2">
                          {Devices.map((data, index) => (
                            <div
                              className={`bg-[#FFEFEE] w-[105px] h-[105px] rounded-[10px] cursor-pointer relative overflow-hidden`}
                              key={index}
                              onClick={() => setFieldValue("device", data.name)}
                            >
                              {data.name === values.device && (
                                <div className="absolute top-1 right-1 text-black">
                                  <MdOutlineDone />
                                </div>
                              )}
                              <div className="bg-themeColor w-full h-[80px] rounded-[10px] flex justify-center items-center">
                                <img src={data.picture} alt={data.picture} />
                              </div>
                              <span className="w-full flex justify-center mt-1 text-[12px] font-[400] text-[#ffff] bg-[#C6A4FF] h-[50px]">
                                {data.name}
                              </span>
                            </div>
                          ))}
                        </div>
                        {errors.device && touched.device && (
                          <p className="text-red-700 text-xs mt-1">
                            {errors.device}
                          </p>
                        )}
                      </div>

                      <div className="w-full mt-4">
                        <label className="w-full text-[#263238] text-[18px] font-[600]">
                          Which browser are you currently using?
                        </label>
                        <div className="flex items-center flex-wrap gap-4 w-full mt-2">
                          {Browsers.map((data, index) => (
                            <div
                              className="relative bg-[#FFEFEE] w-auto px-6 h-[30px] font-[600] rounded-[10px] text-[12px] font-[400] text-[#263238] flex justify-center items-center cursor-pointer"
                              key={index}
                              onClick={() =>
                                setFieldValue("browser", data.name)
                              }
                            >
                              {data.name === values.browser && (
                                <div className="absolute top-1 right-1 text-black">
                                  <MdOutlineDone />
                                </div>
                              )}
                              {data.name}
                            </div>
                          ))}
                        </div>
                        {errors.browser && touched.browser && (
                          <p className="text-red-700 text-xs mt-1">
                            {errors.browser}
                          </p>
                        )}
                      </div>

                      <div className="w-full mt-4">
                        <label className="w-full text-[#263238] text-[18px] font-[600]">
                          Attach screenshot
                        </label>
                        <div className="mt-2">
                          <label
                            className="cursor-pointer px-[10px] py-[14px] inline-flex justify-center items-center gap-2 rounded-[5px] border-2 border-[#263238] text-[12px] font-[700]"
                            htmlFor="choose_file"
                          >
                            <IoIosAttach size={15} />

                            <span>Browse files</span>
                          </label>

                          <input
                            type="file"
                            id="choose_file"
                            accept=".image/png,image/jpeg"
                            name="file"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />

                          {/* {errors.file && touched.file && (
                            <p className="text-red-700 text-xs my-1">
                              {errors.file}
                            </p>
                          )} */}
                          {fileError ? (
                            <>
                              {fileError && (
                                <div className="text-red-700 text-xs  w-full">
                                  {fileError}
                                </div>
                              )}{" "}
                            </>
                          ) : (
                            <>
                              {errors.file && touched.file && (
                                <p className="text-red-700 text-xs w-full">
                                  {errors.file}
                                </p>
                              )}
                            </>
                          )}
                          {selectedFile && (
                            <span className="text-[#263238] text-[13px] font-semibold mt-2 pl-4">
                              {selectedFile.name}
                            </span>
                          )}

                          {!selectedFile && (
                            <div className="flex items-center gap-1 mt-2">
                              <h1 className="text-[#263238] text-[13px] font-semibold">
                                File type:
                              </h1>
                              <span className="text-[#263238] text-[13px] font-normal">
                                JPG, JPEG and PNG
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-end mb-2">
                        <button
                          onClick={handleSubmit}
                          type="button"
                          className="bg-[#FF5E59] rounded-[10px] w-[120px] h-[50px] text-[#FFF] font-[600] flex justify-center items-center"
                        >
                          {loading ? <ButtonLoader2 /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
export default FeedbackModal;
