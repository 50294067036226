/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";
import { Link } from "react-router-dom";

const BlogArticle = () => {
  const data = localStorage.getItem("blogs");
  const [randomBlogs, setRandomBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (JSON.parse(data).length > 0) {
      const shuffled = JSON.parse(data).sort(() => 0.5 - Math.random());
      setRandomBlogs(shuffled);
    }
  }, [data]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchedData = JSON.parse(data).filter((blog) =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className="flex flex-col w-full min-h-[100vh] h-full gap-6 xs:py-[80px] md:py-[100px] xs:px-[20px] md:px-[5%]">
        <form className="w-full">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-[#FF5E59]"
              placeholder="Search your blog"
              required
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </form>

        {searchQuery ? (
          <>
            {searchedData.length > 0 ? (
              <div className="grid xs:grid-cols-1 sm:grid-cols-4 gap-4 mt-2">
                {searchedData.map((blog) => (
                  <Link
                    key={blog.id}
                    to={`/blogs/${blog.title}/${blog.id}`}
                    className="relative w-full h-[200px] overflow-hidden rounded-[10px]"
                    style={{
                      backgroundImage: `url(${blog.image_url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-5 text-white">
                      <span className="bg-[#FF5E59] border border-white font-medium p-2 text-xs rounded-[5px] w-[80px] text-center">
                        {blog?.category || "Mismatch"}
                      </span>

                      <h3 className="font-bold text-sm h-[40px] mt-1">
                        {blog.title.length > 50
                          ? `${blog.title.slice(0, 50)}...`
                          : blog.title}
                      </h3>
                      <div className="flex items-center gap-6 text-sm pt-2">
                        <div className="flex items-center gap-2">
                          <img src="/images/blog-profile.svg" alt="" />
                          <p>{blog.author}</p>
                        </div>
                        <p>{blog.date}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="w-full h-[300px] flex justify-center items-center">
                Data not found
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex xs:flex-col md:flex-row gap-4 mt-2">
              <div className="xs:w-full md:w-[40%] grid grid-cols-1 gap-4">
                {randomBlogs.slice(0, 1).map((blog) => (
                  <Link
                    key={blog.id}
                    to={`/blogs/${blog.title}/${blog.id}`}
                    className="relative w-full h-auto overflow-hidden rounded-[10px]"
                    style={{
                      backgroundImage: `url(${blog.image_url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-5 text-white">
                      <span className="bg-[#FF5E59] border border-white font-medium p-2 text-xs rounded-[5px] w-[80px] text-center">
                        {blog?.category || "Mismatch"}
                      </span>
                      <h3 className="font-bold text-sm h-[40px] mt-1">
                        {blog.title.length > 50
                          ? `${blog.title.slice(0, 50)}...`
                          : blog.title}
                      </h3>
                      <div className="flex items-center gap-6 text-sm pt-2">
                        <div className="flex items-center gap-2">
                          <img src="/images/blog-profile.svg" alt="" />
                          <p>{blog.author}</p>
                        </div>
                        <p>{blog.date}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="xs:w-full md:w-[60%] grid xs:grid-cols-1 sm:grid-cols-2 gap-4">
                {randomBlogs.slice(0, 4).map((blog) => (
                  <Link
                    key={blog.id}
                    to={`/blogs/${blog.title}/${blog.id}`}
                    className="relative w-full h-[200px] overflow-hidden rounded-[10px]"
                    style={{
                      backgroundImage: `url(${blog.image_url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-5 text-white">
                      <span className="bg-[#FF5E59] border border-white font-medium p-2 text-xs rounded-[5px] w-[80px] text-center">
                        {blog?.category || "Mismatch"}
                      </span>

                      <h3 className="font-bold text-sm h-[40px] mt-1">
                        {blog.title.length > 50
                          ? `${blog.title.slice(0, 50)}...`
                          : blog.title}
                      </h3>
                      <div className="flex items-center gap-6 text-sm pt-2">
                        <div className="flex items-center gap-2">
                          <img src="/images/blog-profile.svg" alt="" />
                          <p>{blog.author}</p>
                        </div>
                        <p>{blog.date}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <Category />
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default BlogArticle;

function Category() {
  const data = localStorage.getItem("blogs");
  const [uniqueCategories, setUniqueCategories] = useState([]);

  useEffect(() => {
    if (JSON.parse(data) && JSON.parse(data).length > 0) {
      const categories = JSON.parse(data).map((blog) => blog.category);
      const uniqueCategories = [...new Set(categories)];
      setUniqueCategories(uniqueCategories);
    }
  }, [data]);

  return (
    <>
      {uniqueCategories?.length > 0 && data?.length > 0 && (
        <>
          {uniqueCategories?.map((category) => (
            <div className="flex flex-col mb-6" key={category}>
              <div className="py-4 flex items-center w-full justify-between">
                <h2 className="font-semibold text-[#263238] text-[24px] ">
                  {category || "Mismatch"}
                </h2>
                {category && (
                  <Link
                    to={{
                      pathname: `/blogs/${category}`,
                      state: { data, uniqueCategories },
                    }}
                  >
                    <button className="bg-[#DBDBDB33] border border-[#00000038] p-2 text-[14px] rounded-md">
                      View All Post
                    </button>
                  </Link>
                )}
              </div>
              <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {JSON.parse(data)
                  .filter((blog) => blog.category === category)
                  .slice(0, 4)
                  .map((blog) => (
                    <Link
                      key={blog.id}
                      to={`/blogs/${blog.title}/${blog.id}`}
                      className="relative w-full h-[200px] overflow-hidden rounded-[10px]"
                      style={{
                        backgroundImage: `url(${blog.image_url})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-5 text-white">
                        <span className="bg-[#FF5E59] border border-white font-medium text-xs p-2 rounded-[5px] w-[80px] text-center">
                          {blog?.category || "Mismatch"}
                        </span>

                        <h3 className="font-bold text-sm h-[40px] mt-1">
                          {blog.title.length > 50
                            ? `${blog.title.slice(0, 50)}...`
                            : blog.title}
                        </h3>
                        <div className="flex items-center gap-6 text-sm pt-1">
                          <div className="flex items-center gap-2">
                            <img src="/images/blog-profile.svg" alt=" " />
                            <p>{blog.author}</p>
                          </div>
                          <p>{blog.date}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}
