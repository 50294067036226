import React from "react";
import "../../style/PageLoader.css";

function PageLoader() {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-opacity-25 bg-white flex justify-center items-center z-50">
      <div className="border-4 border-white border-opacity-25 rounded-full w-10 h-10 page-loader"></div>
    </div>
  );
}

export default PageLoader;
