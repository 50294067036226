import React, { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import signout from "../assets/images/SignOut.png";
import { TiArrowSortedDown } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../context/GlobalContext";
import useLogout from "../hooks/useLogout";
import demo_avatar from "../assets/images/profilepic.png";
import "../style/Circle.css";
import { ButtonLoader1 } from "./shared/ButtonLoader";
import { avatars_list } from "../static-data/AvaratsList";
import Cookies from "js-cookie";

const ProfileMenu = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const { userInfo } = useContext(GlobalContext);
  const { logout, loading } = useLogout();
  const navigate = useNavigate();
  const goToChat = () => {
    navigate("/profile");
  };

  const refresh = localStorage.getItem("refresh") || Cookies.get("refresh");
  const handleLogout = async () => {
    await logout({ refresh_token: refresh });
    localStorage.clear();
    Cookies.remove("token");
    Cookies.remove("refresh_token");
    Cookies.remove("user_id");
    Cookies.remove("user_id");
    Cookies.remove("current_Page");
    window.location.href = "/";
  };
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="flex items-center outline-none">
          <div className="small-circle">
            {userInfo?.profile_pic && (
              <img
                className="rounded-full w-full h-full"
                style={{ aspectRatio: "1/1", objectFit: "cover" }}
                src={`${BASE_URL}${userInfo.profile_pic}`}
                alt=""
              />
            )}
            {userInfo?.avatar_url && (
              <img
                className="rounded-full w-full h-full"
                style={{ aspectRatio: "1/1", objectFit: "cover" }}
                src={avatars_list[userInfo.avatar_url]?.avatar}
                alt=""
              />
            )}
            {!userInfo?.profile_pic && !userInfo?.avatar_url && (
              <img
                className="rounded-full w-full h-full"
                style={{ aspectRatio: "1/1", objectFit: "cover" }}
                src={demo_avatar}
                alt=""
              />
            )}
          </div>
          <TiArrowSortedDown />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-1">
            <div className="">
              <div className="absolute top-[100%] right-0 bg-[#F8F8F8] border border-[#FFFCFC] rounded-[20px] z-[99]s  lg:w-[300px] xs:w-[256px] xs:h-[320px] lg:h-[340px] ">
                <div
                  style={{
                    padding: "10px 10px",
                  }}
                >
                  <div className="bg-[#ffffff] border border-[#F8F8F8] lg:w-[280px] xs:h-[220px]  lg:h-[240px] flex flex-col justify-center items-center pt-1  gap-2 rounded-[12px]">
                    <div className="medium-circle">
                      {userInfo?.profile_pic && (
                        <img
                          className="rounded-full w-full h-full"
                          style={{ aspectRatio: "1/1", objectFit: "cover" }}
                          src={`${BASE_URL}${userInfo.profile_pic}`}
                          alt=""
                        />
                      )}
                      {userInfo?.avatar_url && (
                        <img
                          className="rounded-full w-full h-full"
                          style={{ aspectRatio: "1/1", objectFit: "cover" }}
                          src={avatars_list[userInfo.avatar_url]?.avatar}
                          alt=""
                        />
                      )}
                      {!userInfo?.profile_pic && !userInfo?.avatar_url && (
                        <img
                          className="rounded-full w-full h-full"
                          style={{ aspectRatio: "1/1", objectFit: "cover" }}
                          src={demo_avatar}
                          alt=""
                        />
                      )}
                    </div>
                    <h1 className="font-semibold text-[#263238] text-[20px] pb-2 py-1 text-center ">
                      {userInfo?.first_name} {userInfo?.last_name}
                    </h1>
                    <div
                      onClick={goToChat}
                      className=" cursor-pointer  border border-[#FF5E59] py-[10px] px-[20px] mb-3 text-[16px] font-medium text-[#FF5E59] rounded-[5px]"
                    >
                      Manage Profile
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    padding: "2px 10px",
                  }}
                >
                  <div
                    onClick={handleLogout}
                    className="lg:w-[280px] xs:w-[240px] xs:h-[60px] lg:h-[70px] border border-[#F8F8F8]  bg-[#ffff] flex justify-center items-center cursor-pointer rounded-[12px] gap-[20px]"
                  >
                    {loading ? (
                      <ButtonLoader1 />
                    ) : (
                      <div className="flex items-center gap-4 ">
                        <img
                          className="w-[25px] h-[30px]"
                          src={signout}
                          alt=""
                        />
                        <span className="font-normal text-[16px] text-[#263238]">
                          Sign Out
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default ProfileMenu;
