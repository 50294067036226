/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import PrivateRoute from "../routing/PrivateRouting";
import Homepage from "../pages/Homepage";
import CVtemplate from "../pages/CVTemplates";
import Chat from "../pages/Chat";
import Signin from "../pages/auth/SignIn";
import Signup from "../pages/auth/SignUp";
import ForgotPassword from "../pages/auth/ForgotPassword";
import NewPassword from "../pages/auth/NewPassword";
import Profile from "../pages/profile/ManageProfile";
import ChangePassword from "../pages/ChangePassword";
import ProfileData from "../pages/profile/ProfileData";
import useGetCurrentUser from "../hooks/useGetCurrentUser";
import VerifyOTP from "../pages/auth/VerifyOtp";
import EditTemplate from "../pages/EditTemplate";
import ScrollToTop from "./ScrollToTop";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import DataDeletion from "../pages/DataDeletion";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Cookies from "js-cookie";
import CookiesJS from "../pages/CookiesJS";
import { OauthReceiver } from "react-oauth-flow";
import Upload_CV from "../pages/Upload_CV";
import TermsCondition from "../pages/TermsCondition";
import GDPR from "../pages/GDPR";
import ContactUs from "../pages/ContactUs";
import Blogs from "../pages/Blogs";
import CategoryPage from "../pages/CategoryPage";
import BlogDetails from "../pages/BlogDetails";
import useGetBlogs from "../hooks/useGetBlogs";
import JobSearch from "../pages/JobSearch";
import PageLoader from "../components/shared/PageLoader";

const Routing = () => {
  const {loading, getCurrentUser } = useGetCurrentUser();
  const [debugMessage, setDebugMessage] = useState("");
  const token = localStorage.getItem("token") || Cookies.get("token");
  const verification_token = Cookies.get("verification_token");
  const uid = Cookies.get("UID");
  useEffect(() => {
    if (token) {
      getCurrentUser(token);
    }
  }, []);
  const location = useLocation();
  const currentPageUrl = location.pathname;
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      Cookies.set("current_Page", currentPageUrl);
      if (verification_token) {
        navigate(currentPageUrl);
      } else {
        if (
          currentPageUrl === "/" ||
          currentPageUrl === "/cookies" ||
          currentPageUrl === "/privacy-policy" ||
          currentPageUrl === "/terms-condition" ||
          currentPageUrl === "/contact-us"
        ) {
          navigate(currentPageUrl);
        } else {
          navigate("/");
        }
      }
    } else {
      navigate(currentPageUrl);
    }
  }, [token]);
  useEffect(() => {
    const currentPageUrl2 = location.pathname;
    sessionStorage.setItem("current_Page2", currentPageUrl2);
    if (
      (token && currentPageUrl2 === "/login") ||
      (token && currentPageUrl2 === "/register")
    ) {
      navigate("/");
    }
  }, [token, navigate]);

  const handleSuccess = async (accessToken, { response }) => {
    Cookies.set("token", response.access_token);
    Cookies.set("refresh", response.refresh_token);
    getCurrentUser(response.access_token);
  };

  const handleError = (error) => {
    console.log({ error });
  };
  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;
  const redirectUrl = process.env.REACT_APP_REDIRECT_URL_SSO;
  const SSO_Client_id = process.env.REACT_APP_CLIENT_ID_SSO;
  const SSO_Client_secret = process.env.REACT_APP_CLIENT_SECRET_SSO;

  // Get all blogs when page loads and store in localStorage
  const { GetBlogsData } = useGetBlogs();
  useEffect(() => {
    GetBlogsData();
  }, []);

  return (
    <>
      <Routes>
        {token ? (
          <>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Homepage />
                </PrivateRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <PrivateRoute>
                  <Chat
                    debugMessage={debugMessage}
                    setDebugMessage={setDebugMessage}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/editprofile"
              element={
                <PrivateRoute>
                  <ProfileData />
                </PrivateRoute>
              }
            />
            <Route
              path="/changepassword"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/saved"
              element={
                <PrivateRoute>
                  <CVtemplate />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/saved/:check_page/:template_id/:cv_id"
              element={
                <PrivateRoute>
                  <EditTemplate />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/saved/:check_page/:template_id"
              element={
                <PrivateRoute>
                  <EditTemplate />
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Homepage />
                </PrivateRoute>
              }
            />
            <Route
              path="/uploadcv"
              element={
                <PrivateRoute>
                  {/*  eslint-disable-next-line */}
                  <Upload_CV />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/jobchat"
              element={
                <PrivateRoute>
                  <JobSearch />
                </PrivateRoute>
              }
            />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies" element={<CookiesJS />} />
            <Route path="/gdpr" element={<GDPR />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/data-deletion" element={<DataDeletion />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:category" element={<CategoryPage />} />
            <Route path="/blogs/:title/:id" element={<BlogDetails />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<Homepage />} />
            <Route path="/login" element={<Signin />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/verify-otp/:email" element={<VerifyOTP />} />
            {verification_token && uid && (
              <Route path="/new-password" element={<NewPassword />} />
            )}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies" element={<CookiesJS />} />
            <Route path="/gdpr" element={<GDPR />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/data-deletion" element={<DataDeletion />} />
            <Route path="/callback" element={<LinkedInCallback />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:category" element={<CategoryPage />} />
            <Route path="/blogs/:title/:id" element={<BlogDetails />} />
          </>
        )}
      </Routes>
      <ScrollToTop />
      {!token && (
        <OauthReceiver
          clientId={SSO_Client_id}
          clientSecret={SSO_Client_secret}
          tokenUrl={`${SSOUrl}/token`}
          redirectUri={redirectUrl}
          onAuthSuccess={handleSuccess}
          onAuthError={handleError}
          // render={({ processing, state, error }) => (
          //   <div>
          //     {processing && <p>Authorizing now...</p>}
          //     {error && (
          //       <p className="error">An error occured: {error.message}</p>
          //     )}
          //   </div>
          // )}
          render={({ processing, state, error }) => (
            <>
              {processing && (
                <div className="fixed top-0 left-0 w-full h-[100vh] bg-white z-50 flex justify-center items-center">
                  <PageLoader />
                </div>
              )}
            </>
          )}
        />
      )}
      {loading && (
        <div className="fixed top-0 left-0 w-full h-[100vh] bg-white z-50 flex justify-center items-center">
          <PageLoader />
        </div>
      )}
    </>
  );
};

export default Routing;
