import React from "react";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";

function TermsCondition() {
  return (
    <>
      <Navbar />
      <div className=" py-10 lg:px-14 xs:px-7 w-full text-justify">
        <h1 className="font-semibold text-[20px] text-center mt-14 mb-8 ">
          Terms and Conditions
        </h1>
        <p>
          This website is owned and operated by CrewDog and is made available to
          you on the following terms and conditions. By using the site and the
          services available on it, you are deemed to accept these terms and
          conditions and any additional terms and conditions which expressly
          apply to services and information provided by third parties.
        </p>

        <h1 className="text-[#DB0000] my-6 font-semibold text-center">
          IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS & CONDITIONS, PLEASE DO
          NOT REGISTER OR USE THIS WEBSITE.
        </h1>
        <div>
          <li>
            'CrewDog'; 'we'; 'us'; and 'our'; means CrewDog Ltd of 71-75 Shelton
            Street, Covent Garden, London UK WC2H 9JQ
          </li>
          <li>
            'Candidate'; 'You' and 'Your' means any person registering with us
            or purchasing any of our services to assist with your .
          </li>
          <li>
            'Employer'; 'You' and 'Your' means any person, company,
            organisation, or firm which purchases services from us to assist
            your recruitment needs.
          </li>
        </div>
        <h1 className="font-semibold text-[16px]  my-4">1. General Terms</h1>
        <p>
          The information and services available on the site are provided for
          the sole purpose of individuals looking for employment opportunities
          and career information and for employers seeking to recruit staff. You
          may use, print, and download information from the site for these
          purposes only and for no other personal or commercial purpose. You may
          not otherwise copy, display, transmit, or distribute any material from
          the site. All copyright, database rights, and other intellectual
          property rights in the site and the material available on the site
          belong to CrewDog or its third-party suppliers. Use of the site does
          not give you any proprietary rights in such materials. You agree not
          to deep-link and/or frame the Website or any of the Services for any
          purpose unless specifically authorised by CrewDog to do so.
        </p>
        <p className="mt-4">
          CrewDog will permit the Candidate and Employer to access, use, and
          interact with our site subject to these terms and conditions. Any
          party using this service for any purpose other than those specifically
          stated in these Terms and Conditions shall immediately be banned from
          using this site.
        </p>
        <p className="mt-4">
          We try to ensure continuous availability of the site and all the
          services available on it but accept no responsibility for the
          consequences of interruptions or delays, however caused. All liability
          of CrewDog, its directors, or employees howsoever arising for any loss
          whatsoever arising from your use of or inability to use the Website
          and/or the Services is excluded, insofar as it is possible to do so in
          law. We may, additionally, alter the design and specification of the
          site at any time.
        </p>
        <p className="mt-4">
          Candidates and Employers need to be aware that CrewDog does not
          introduce or supply Candidates to Employers (or vice versa). It is
          recommended that, if you are a Candidate, to ensure your suitability
          for the role advertised or if you are an Employer, to ensure a job
          seeker's suitability for the role, you should undertake the steps set
          out in The Conduct of Employment Agencies and Employment Business
          Regulations 2003.
        </p>
        <p className="mt-4">
          You agree that you shall not use the Website or the Services to
          transmit or knowingly or recklessly receive (or knowingly or
          recklessly authorise or permit any other person to receive or
          transmit) material which is obscene, threatening, menacing, offensive,
          defamatory, abusive, untrue, in breach of confidence, in breach of any
          intellectual property right (including copyright), or which may cause
          anxiety to others including racist or sexist content or material which
          otherwise violates any applicable law or regulation or code, or which
          makes excessive demands for bandwidth or contains any virus or which
          may otherwise impair or harm CrewDog's computer systems or any
          third-party computer system.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default TermsCondition;
