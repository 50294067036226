import axios from "axios";
import Cookies from "js-cookie";
const useRefreshToken = () => {
  const refresh = Cookies.get("refresh");
  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;

  const RefreshToken = async () => {
    const data = {
      refresh: refresh
    };
    try {
      const response = await axios.post(`${SSOUrl}/token/refresh/`, data);
      window.location.reload();
      Cookies.set("token", response.access_token);
      Cookies.set("refresh", response.refresh_token);
    } catch (error) {
      if (error?.response?.status === 401) {
        Cookies.remove("token");
        Cookies.remove("refresh");
        window.location.href = "/";
      }
    }
  };
  return { RefreshToken };
};
export default useRefreshToken;
