import React, { useEffect, useState } from "react";
import { ButtonLoader2 } from "../../components/shared/ButtonLoader";
import useRegister from "../../hooks/useRegister";
import { Link } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  GoogleLogin,
  AppleLogin,
  LinkedInCallback,
} from "../../components/SocialLogins";
import AuthBG from "../../assets/images/authBG.png";

const initialValues = {
  first_name: "",
  email: "",
  password: "",
  confirm_password: "",
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Name must be only characters")
    .min(3, "Name must be at least 3 characters")
    .max(30, "Name must not exceed 30 characters"),
  email: Yup.string()
    .required("Email is required")
    .matches(/^\S+@\S+\.\S+$/, "Invalid email"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password length must be at least 6 characters long!")
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      "Password must contain at least one alphabetic character and one special character"
    )
    .max(20, "password must not exceed 20 characters"),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

const SignUp = () => {
  // scroll
  const [fixed, setFixed] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    });
  }, []);
  return (
    <>
      <div className="flex flex-col w-full min-h-screen justify-center items-center lg:items-start">
        <div className="fixed top-[10px] left-[10px] lg:flex xs:hidden flex items-center justify-start gap-2 ">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={fixed ? "images/crewdog_black.svg" : "images/crewdog.svg"}
              alt="img"
            />
          </a>
          <img src={"/images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img src={"/images/cvlab_black.svg"} alt="" className="" />
          </Link>
        </div>
        <div className="w-full min-h-screen flex lg:flex-row xs:flex-col justify-center items-center">
          <div className="w-[55%] h-[100vh] flex-1  justify-center items-center hidden md:flex bg-slate-400 ">
            <img src={AuthBG} alt="" className=" h-full w-full object-center" />
          </div>
          <div className="lg:hidden xs:flex justify-center flex items-center gap-2 h-[25vh]">
            <a
              href="https://beta.crewdog.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={"/images/crewdog_black.svg"} alt="" className="" />
            </a>
            <img src={"/images/divider.svg"} alt="" className="" />
            <Link to="/">
              <img src={"/images/cvlab_black.svg"} alt="" className="" />
            </Link>
          </div>
          <div className="w-full flex flex-1  items-center justify-center lg:w-[45%] sm:w-full lg:h-auto lg:mt-0 xs:mt-4 lg:bg-transparent lg:border-none xs:bg-[#fcf4f4] xs:border-2 xs:rounded-[45px] xs:border-[#FFEFEE]">
            <SignUpFrom />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;

function SignUpFrom() {
  const [password, setPassword] = useState(false);
  const [confrimpassword, setConfrimPassword] = useState(false);
  const { loading, registerUser } = useRegister();

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await registerUser({
        first_name: values.first_name,
        email: values.email,
        password: values.password,
      });
    },
  });

  return (
    <>
      <div className="flex flex-col items-center justify-between sm:w-[80%] xs:w-full  h-auto lg:px-8 px-4 lg:py-6 py-4">
        <h2 className="text-[#263238] font-semibold text-[44px]">Sign up</h2>
        <div className="flex flex-col w-full gap-2 lg:gap-1 2xl:gap-2 ">
          <div className="w-full">
            <label className="md:text-[18px] text-[14px]">Name</label>
            <input
              type="text"
              name="first_name"
              value={values.first_name}
              onChange={handleChange}
              placeholder="Enter name"
              className="bg-[#fff] w-full mt-1 py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px] text-[18px] "
            />
            {errors.first_name && touched.first_name && (
              <p className="text-red-700 text-xs">{errors.first_name}</p>
            )}
          </div>
          <div className="w-full">
            <label className="md:text-[18px] text-[14px]">Email</label>
            <input
              type="text"
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="Enter email address"
              className="bg-[#fff] w-full mt-1 py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px] text-[18px] "
            />
            {errors.email && touched.email && (
              <span className="text-red-700 text-xs">{errors.email}</span>
            )}
          </div>
          <div className="w-full">
            <label className="md:text-[18px] text-[14px]">Password</label>
            <div className="relative mt-1">
              <input
                type={password ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Enter password"
                className="bg-[#fff] w-full  py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px] text-[18px]"
              />

              <div
                className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                onClick={() => setPassword(!password)}
              >
                {password ? (
                  <BiShow className="text-2xl text-[#95969D]" />
                ) : (
                  <BiHide className="text-2xl text-[#95969D]" />
                )}
              </div>
            </div>
            {errors.password && touched.password && (
              <p className="text-red-700 text-xs ">{errors.password}</p>
            )}
          </div>
          <div className="w-full">
            <label className="md:text-[18px] text-[14px]">
              Confirm Password
            </label>
            <div className="relative mt-1">
              <input
                type={confrimpassword ? "text" : "password"}
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                placeholder="Enter confirm password"
                className="bg-[#fff] w-full py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px] text-[18px]"
              />
              <div
                className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                onClick={() => {
                  setConfrimPassword(!confrimpassword);
                }}
              >
                {confrimpassword ? (
                  <BiShow className="text-2xl text-[#95969D]" />
                ) : (
                  <BiHide className="text-2xl text-[#95969D]" />
                )}
              </div>
            </div>
            {errors.confirm_password && touched.confirm_password && (
              <p className="text-red-700 text-xs ">{errors.confirm_password}</p>
            )}
          </div>
        </div>
        <div className="mt-6 mb-3 w-full">
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-[#FF5E59] w-full h-[50px] text-[20px] text-white rounded-[5px] flex justify-center items-center"
          >
            {loading ? <ButtonLoader2 /> : "Sign up"}
          </button>
        </div>
        <div className="flex flex-row justify-center items-center w-full px-0 gap-1  lg:py-0 ">
          <div className="bg-[#9d9d9d] lg:w-[35%] w-[30%] h-[1px]"></div>
          <div className="text-sm  text-center text-[#95969D]">
            Or sign up with
          </div>
          <div className="bg-[#9d9d9d] lg:w-[35%] w-[30%] h-[1px]"></div>
        </div>
        <div className="flex justify-center gap-5 lg:my-5 xs:my-4">
          <GoogleLogin />
          <AppleLogin />
          <LinkedInCallback />
        </div>
        <div className="mt-5">
          <span className="text-[#95969D] text-[14px]">
            Already have an account?{" "}
          </span>
          <Link to="/login" className="text-[#FF5E59] text-[14px] underline">
            Sign in
          </Link>
        </div>
      </div>
    </>
  );
}
