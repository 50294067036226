/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";
import { templates } from "../static-data/TemplateImg";
import deleteicon from "../assets/images/templates/delete.svg";
import useGetUserCVList from "../hooks/useGetUserCVList";
import MainLoader from "../components/shared/MainLoader";
import usePostFavouriteTemp from "../hooks/usePostFavouriteTemp";
import useGetFavouriteTemp from "../hooks/useGetFavouriteTemp";
import useDeleteFavouriteTemp from "../hooks/useDeleteFavouriteTemp";
import useDeleteMyCV from "../hooks/useDeleteMyCV";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import { ButtonLoader1 } from "../components/shared/ButtonLoader";
import NoCollection from "../assets/images/noCollection.png";

const Saved = () => {
  const {
    activeButton,
    setActiveButton,
    clearChatData,
    setShowMakeCVQuestion,
    setSecondQuestion,
  } = useContext(GlobalContext);
  const location = useLocation();

  return (
    <>
      <Navbar />
      <div className="w-full flex flex-col items-center h-auto mt-[90px]">
        <div className="tab-button flex flex-col min-h-[70vh] items-center w-full  xs:px-2 lg:px-0">
          <div className="flex gap-[15px] py-[10px] px-[10px] lg:w-auto xs:w-full bg-[#4E49491A] rounded-[10px]">
            <button
              className={`picture-btn ${
                activeButton === "templates" ? "active" : ""
              }`}
              onClick={() => {
                setActiveButton("templates");
              }}
            >
              Templates
            </button>
            <button
              className={`picture-btn ${
                activeButton === "my-cvs" ? "active" : ""
              }`}
              onClick={() => {
                setActiveButton("my-cvs");
                clearChatData();
                setShowMakeCVQuestion(false);
                setSecondQuestion(true);
              }}
            >
              My CV's
            </button>
            <button
              className={`avatar-btn ${
                activeButton === "favourites" ? "active" : ""
              }`}
              onClick={() => {
                setActiveButton("favourites");
              }}
            >
              Favourites
            </button>
          </div>
          <div className="w-full h-auto flex justify-center">
            {activeButton === "templates" && <Templates />}
            {activeButton === "my-cvs" && <MyCV />}
            {activeButton === "favourites" && <YourTemplate />}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Saved;

function Templates() {
  const Navigate = useNavigate();
  const { favoriteTemplates, getFavouriteTemp } = useGetFavouriteTemp();

  const openPdf = (id) => {
    Navigate(`/saved/save-resume/${id}`);
  };

  const { addFavLoading, FavouriteTemp1 } = usePostFavouriteTemp();
  const addToFavorites = async (templateId) => {
    await FavouriteTemp1({ template_id: templateId });
  };
  useEffect(() => {
    getFavouriteTemp();
  }, [addFavLoading]);

  return (
    <>
      <div className="flex justify-center gap-6 flex-wrap lg:w-[80%] w-full py-8">
        {templates.map((data, index) => {
          return (
            <div className="flex relative cv-main" key={index}>
              <div style={{ boxShadow: " 0px 0px 6px 0px #00000040" }}>
                {data?.var === true ? (
                  <>
                    <img
                      src={data.img}
                      alt=""
                      className="lg:w-[150px] xs:w-[130px] 2xl:w-[200px] relative"
                    />
                    <p className="bg-red-500 px-3 absolute top-1 left-1 text-white text-[14px] rounded-sm">
                      Hot
                    </p>
                  </>
                ) : (
                  <>
                    <img
                      src={data.img}
                      alt=""
                      className="lg:w-[150px] xs:w-[130px] 2xl:w-[200px] "
                    />
                  </>
                )}
              </div>
              {addFavLoading === data.id ? (
                <div
                  className="absolute top-[0px] right-[0px] z-[10] w-full h-full flex justify-center items-center"
                  style={{ background: "rgba(0, 0, 0, 0.219)" }}
                >
                  <ButtonLoader1 />
                </div>
              ) : (
                <>
                  {favoriteTemplates && favoriteTemplates.length > 0 ? (
                    favoriteTemplates.some((item) => item.id === data.id) ? (
                      <img
                        src={"/images/saved.svg"}
                        alt=""
                        className="absolute top-[-10px] right-[-10px] z-[10]"
                      />
                    ) : (
                      <img
                        onClick={() => addToFavorites(data.id)}
                        src={data.icon}
                        alt=""
                        className="absolute top-[-10px] right-[-10px] z-[10] cursor-pointer"
                      />
                    )
                  ) : (
                    <img
                      onClick={() => addToFavorites(data.id)}
                      src={data.icon}
                      alt=""
                      className="absolute top-[-10px] right-[-10px] z-[10] cursor-pointer"
                    />
                  )}

                  <div className="update-main">
                    <button className="update" onClick={() => openPdf(data.id)}>
                      Select
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

function MyCV() {
  const Navigate = useNavigate();
  const { loading, cvList, getUserCVList } = useGetUserCVList();
  const { deleteCVLoading, DeleteMyCV } = useDeleteMyCV();
  const { checkDeleteStatus } = useContext(GlobalContext);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getUserCVList(query);
  }, [checkDeleteStatus, query]);

  const handleDeleteCV = async (cv_id, tempId) => {
    await DeleteMyCV(cv_id, tempId);
  };

  const openPdf = (data) => {
    Navigate(`/saved/update-resume/${data.id}/${data.tempId}`);
  };
  return (
    <div className="w-full flex flex-col justify-start items-center py-8">
      <div
        style={{ boxShadow: "0px 2px 8px 0px #00000040" }}
        className=" rounded-[10px]"
      >
        <input
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search Here"
          type="search"
          className="py-2 px-3 lg:w-[350px] xs:w-[320px] rounded-[10px] text-[15px]"
        />
      </div>
      {loading && (
        <div className="h-[35vh] flex justify-center items-center ">
          <MainLoader />
        </div>
      )}
      {cvList?.length === 0 && (
        <div className="h-full flex flex-col justify-start items-center">
          <img src={NoCollection} alt="" className="mt-3 w-[500px] h-[300px]" />
          <p className="lg:text-[30px] xs:text-[20px] text-center text-[#FF5E59] font-[600] mt-4">
            No collection
          </p>
          <p className="lg:text-[16px] xs:text-[12px] text-center text-[#4E4949] font-[600] mt-1">
            You have not saved any CV yet
          </p>
        </div>
      )}
      <div className="flex justify-center gap-6 flex-wrap lg:w-[80%] w-full mt-8">
        {cvList?.map((data, index) => (
          <div
            className="flex flex-col items-center relative cv-main"
            key={index}
          >
            <div style={{ boxShadow: " 0px 0px 6px 0px #00000040" }}>
              <img
                src={data.img}
                alt=""
                className="lg:w-[150px] xs:w-[130px] 2xl:w-[200px]"
              />
            </div>
            <span className="mt-2 lg:text-[16px] xs:text-[12px] text-center">
              Title: <span className="font-[600]">{data.username}</span>
            </span>
            {deleteCVLoading === data.tempId ? (
              <div
                className="absolute top-[0px] right-[0px] z-[10] w-full h-full flex justify-center items-center"
                style={{ background: "rgba(0, 0, 0, 0.219)" }}
              >
                <ButtonLoader1 />
              </div>
            ) : (
              <>
                <img
                  onClick={() => handleDeleteCV(data.tempId, data.id)}
                  src={deleteicon}
                  alt=""
                  className="absolute top-[-10px] right-[-10px] z-[10] cursor-pointer"
                />
                <div className="update-main">
                  <button className="update" onClick={() => openPdf(data)}>
                    Update
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function YourTemplate() {
  const Navigate = useNavigate();
  const openPdf = (id) => {
    Navigate(`/saved/save-resume/${id}`);
  };
  const { loading, favoriteTemplates, getFavouriteTemp } =
    useGetFavouriteTemp();
  const { deleteFavLoading, DeleteFavouriteTemp } = useDeleteFavouriteTemp();
  const { checkDeleteStatus } = useContext(GlobalContext);

  useEffect(() => {
    getFavouriteTemp();
  }, [checkDeleteStatus]);

  const handleDeleteFavorite = async (templateId) => {
    await DeleteFavouriteTemp(templateId);
  };

  return (
    <div className="w-full flex flex-col justify-start items-center py-8">
      {loading && (
        <div className="h-[35vh] flex justify-center items-center">
          <MainLoader />
        </div>
      )}
      {favoriteTemplates?.length === 0 && (
        <div className="h-full flex flex-col justify-start items-center">
          <img
            src={NoCollection}
            alt=""
            className="mb-3 lg:w-[500px] xs:w-[300px] lg:h-[300px]"
          />
          <p className="lg:text-[30px] xs:text-[20px] text-center text-[#FF5E59] font-[600] mt-4">
            No collection
          </p>
          <p className="lg:text-[16px] xs:text-[12px] text-center text-[#4E4949] font-[600] mt-1">
            Go to templates to add favorite CV
          </p>
        </div>
      )}
      <div className="flex justify-center gap-6 flex-wrap lg:w-[80%] h-auto w-full">
        {favoriteTemplates?.map((data, index) => (
          <div
            className="flex relative cv-main"
            key={index}
            style={{ boxShadow: " 0px 0px 6px 0px #00000040" }}
          >
            <img
              src={data.img}
              alt=""
              className="lg:w-[150px] xs:w-[130px] 2xl:w-[200px] h-full"
            />
            {deleteFavLoading === data.tempId ? (
              <div
                className="absolute top-[0px] right-[0px] z-[10] w-full h-full flex justify-center items-center"
                style={{ background: "rgba(0, 0, 0, 0.219)" }}
              >
                <ButtonLoader1 />
              </div>
            ) : (
              <>
                <img
                  onClick={() => handleDeleteFavorite(data.tempId)}
                  src={deleteicon}
                  alt=""
                  className="absolute top-[-10px] right-[-10px] z-[10] cursor-pointer"
                />
                <div className="update-main">
                  <button className="update" onClick={() => openPdf(data.id)}>
                    Select
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
