import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const useGetUserCV = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [userCvLoading, setUserCvLoading] = useState(false);
  const [userCVData, setUserCVData] = useState(null);
  const token = localStorage.getItem("token") || Cookies.get("token");

  const getUserCV = async (cv_id, template_id) => {
    setUserCvLoading(true);
    await axios
      .get(`${BASE_URL}/api/getCv/?cv_id=${cv_id}&template_id=${template_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserCvLoading(false);
        setUserCVData(response.data.cv.cv);
      })
      .catch((error) => {
        setUserCvLoading(false);
      });
  };

  return { userCvLoading, getUserCV, userCVData };
};

export default useGetUserCV;
