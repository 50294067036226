import React, { useRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import "@react-pdf-viewer/core/lib/styles/index.css";
import PdfViewer from "../PdfViewer";

function PdfViewerModel({ showPDF, setShowPDF, pdfUrl, username }) {
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={showPDF} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[9999999999]"
          initialFocus={cancelButtonRef}
          onClose={() => setShowPDF(false)}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black bg-opacity-75 py-5">
            <div className="flex min-h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all 2xl:w-[900px] lg:w-[700px] xs:w-full h-auto xs:mx-2 lg:pl-2 lg:pr-6 lg:py-6">
                  <div className="cursor-pointer absolute right-4 top-4">
                    <AiOutlineClose onClick={() => setShowPDF(false)} />
                  </div>
                  <PdfViewer
                    url={pdfUrl}
                    username={username}
                    setShowPDF={setShowPDF}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
export default PdfViewerModel;
