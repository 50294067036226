import { useGoogleLogin } from "@react-oauth/google";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaApple } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { LoginSocialApple } from "reactjs-social-login";
import axios from "axios";
import Swal from "sweetalert2";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useState } from "react";
import { ButtonLoader1 } from "./shared/ButtonLoader";
import Cookies from "js-cookie";

/************************************* Google ************************************/
export function GoogleLogin() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const currentPagePath = Cookies.get("current_Page");

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setLoading(true);
      axios
        .post(`${BASE_URL}/accounts/social/google/login/`, {
          access_token: codeResponse.access_token,
        })
        .then((response) => {
          if (response.data.status === true) {
            if (!currentPagePath === "/") {
              window.location.href = currentPagePath;
            } else {
              window.location.href = "/chat";
            }

            Cookies.set("token", response.data.tokens.access);
            Cookies.set("refresh", response.data.tokens.refresh);
            setLoading(false);
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              text: response.data.message,
              customClass: {
                confirmButton: "custom-red-button",
              },
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log({ error });
        });
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  return (
    <>
      <div
        onClick={() => login()}
        className="bg-[#FFFFFF] w-[90px] h-[40px] flex items-center justify-center rounded-[10px] cursor-pointer"
      >
        {loading ? <ButtonLoader1 /> : <FaGoogle className="text-[25px]" />}
      </div>
    </>
  );
}

/************************************* Apple ************************************/
export function AppleLogin() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const currentPagePath = Cookies.get("current_Page");

  const LoginWithApple = async (provider) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/accounts/social/apple/login/`, {
        token: provider.data.authorization.id_token,
      })
      .then((response) => {
        if (!currentPagePath === "/") {
          window.location.href = currentPagePath;
        } else {
          window.location.href = "/chat";
        }
        Cookies.set("token", response.data.tokens.access);
        Cookies.set("refresh", response.data.tokens.refresh);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log({ error });
      });
  };
  return (
    <>
      <LoginSocialApple
        client_id={"ai.crewdog.cv-lab"}
        scope={"name email"}
        redirect_uri={"https://cvlab.crewdog.ai/login"}
        onResolve={(provider, data) => {
          LoginWithApple(provider);
        }}
        onReject={(error) => {
          console.log({ error });
        }}
      >
        <div className="bg-[#FFFFFF] w-[90px] h-[40px] flex items-center justify-center rounded-[10px] cursor-pointer">
          {loading ? <ButtonLoader1 /> : <FaApple className="text-[25px]" />}
        </div>
      </LoginSocialApple>
    </>
  );
}

/************************ Linkedin ***********************************/
export function LinkedInCallback() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const clientId = "77bi1jwyush1y1";
  const redirectUri = `https://cvlab.crewdog.ai/callback`;
  const currentPagePath = Cookies.get("current_Page");

  const { linkedInLogin } = useLinkedIn({
    clientId: clientId,
    redirectUri: redirectUri,
    onSuccess: (code) => {
      setLoading(true);
      axios
        .post(`${BASE_URL}/accounts/social/linkedin/login/`, {
          code: code,
        })
        .then((response) => {
          if (!currentPagePath === "/") {
            window.location.href = currentPagePath;
          } else {
            window.location.href = "/chat";
          }
          Cookies.set("token", response.data.tokens.access);
          Cookies.set("refresh", response.data.tokens.refresh);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log({ error });
        });
    },
    scope: ["r_liteprofile", "r_emailaddress"],
  });

  return (
    <>
      <div
        className="bg-[#FFFFFF] w-[90px] h-[40px] flex items-center justify-center rounded-[10px] cursor-pointer"
        onClick={linkedInLogin}
      >
        {loading ? (
          <ButtonLoader1 />
        ) : (
          <IoLogoLinkedin className="text-[25px]" />
        )}
      </div>
    </>
  );
}
