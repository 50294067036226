import React, { useContext, useState } from "react";
import { ButtonLoader2 } from "../../components/shared/ButtonLoader";
import useLogin from "../../hooks/useLogin";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { BiHide, BiShow } from "react-icons/bi";
import {
  GoogleLogin,
  AppleLogin,
  LinkedInCallback,
} from "../../components/SocialLogins";
import AuthBG from "../../assets/images/authBG.png";
import { AuthContext } from "react-oauth2-code-pkce";

const Signin = () => {
  const { login } = useContext(AuthContext);
  const handleLogin = () => {
    login();
  };

  // const token = localStorage.getItem("token") || Cookies.get("token");
  // useEffect(() => {
  //   if (token) {
  //     navigate("/");
  //   }
  // }, [token, navigate]);

  // scroll
  // const [fixed, setFixed] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY > 100) {
  //       setFixed(true);
  //     } else {
  //       setFixed(false);
  //     }
  //   });
  // }, []);

  return (
    <>
      <div className="flex flex-col w-full min-h-screen justify-center items-center lg:items-start">
        <div className="fixed top-[10px] left-[10px] lg:flex xs:hidden flex items-center justify-start gap-2 ">
          <a
            href="https://beta.crewdog.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <img
              src={fixed ? "images/crewdog_black.svg" : "images/crewdog.svg"}
            /> */}
          </a>
          <img src={"/images/divider.svg"} alt="" className="" />
          <Link to="/">
            <img src={"/images/cvlab_black.svg"} alt="" className="" />
          </Link>
        </div>
        <div className="w-full min-h-screen flex lg:flex-row xs:flex-col justify-center items-center">
          <div className="w-[55%] h-[100vh]  justify-center items-center hidden md:flex flex-1 bg-slate-400 ">
            <img src={AuthBG} alt="" className=" h-full w-full object-center" />
          </div>

          <div className="lg:hidden xs:flex justify-center flex items-center gap-2 h-[25vh]">
            <a
              href="https://beta.crewdog.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={"/images/crewdog_black.svg"} alt="" className="" />
            </a>
            <img src={"/images/divider.svg"} alt="" className="" />
            <Link to="/">
              <img src={"/images/cvlab_black.svg"} alt="" className="" />
            </Link>
          </div>

          <div className="w-full flex flex-1 items-center justify-center lg:w-[45%] sm:w-full lg:h-auto lg:mt-0 xs:mt-4 lg:bg-transparent lg:border-none xs:bg-[#fcf4f4] xs:border-2 xs:rounded-[45px] xs:border-[#FFEFEE]">
            <SignInFrom />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

function SignInFrom() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { loading, rememberMe, setRememberMe, Login } = useLogin();

  const initialValues = {
    username: "",
    password: "",
  };

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await Login({
        username: values.username,
        password: values.password,
      });
    },
  });
  // Spell checker setup

  return (
    <>
      <div className="flex flex-col items-center justify-between sm:w-[80%] xs:w-full  h-auto lg:px-8 px-4 lg:py-6 py-4">
        <div className="lg:w-full w-full flex flex-col items-center lg:gap-0 2xl:gap-6 gap-4 ">
          <h2 className="text-[#263238] font-semibold text-[44px]">Sign in</h2>
          <div className="w-full flex flex-col gap-2 lg:gap-1 2xl:gap-4 mt-5">
            <div className="w-full">
              <label className="md:text-[18px] text-[14px]">Email</label>
              <input
                type="text"
                placeholder="Enter email address"
                name="username"
                value={values.username}
                onChange={handleChange}
                className="bg-[#fff] w-full mt-1 py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px]"
              />
              {errors.username && touched.username && (
                <p className="text-red-700 text-xs">{errors.username}</p>
              )}
            </div>
            <div className="w-full">
              <label className="md:text-[18px] text-[14px]">Password</label>
              <div className="relative mt-1">
                <input
                  spellCheck="false"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Enter password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  className="bg-[#fff] w-full  py-3 lg:py-2 2xl:py-3 border border-[#DFDFDF] px-4 rounded-[10px]"
                />

                <div
                  className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                >
                  {passwordVisible ? (
                    <BiShow className="text-2xl text-[#95969D]" />
                  ) : (
                    <BiHide className="text-2xl text-[#95969D]" />
                  )}
                </div>
              </div>
              {errors.password && touched.password && (
                <p className="text-red-700 text-xs">{errors.password}</p>
              )}
            </div>
          </div>
          <div className="flex w-full justify-between lg:pt-2 2xl:py-0 px-2">
            <div className="flex items-center justify-center gap-1 ">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => {
                    setRememberMe(!rememberMe);
                  }}
                  className="sr-only peer"
                />
                <div className="w-8 h-4  text-[18px] bg-[#4E4949] peer-focus:outline-none  dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>

              <span className="xs:text-[10px] lg:text-[12px]">Remember me</span>
            </div>
            <Link
              to="/forgotpassword"
              className="text-[#FF5E59] lg:text-[12px] xs:text-[10px]"
            >
              Forgot Password?
            </Link>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-[#FF5E59] w-full h-[50px] text-[20px] text-white my-5 lg:my-6 xs:mx-3 rounded-[5px] shadow-sigin flex justify-center items-center"
          >
            {loading ? <ButtonLoader2 /> : "Sign in"}
          </button>
          {/* <a
            href={`http://18.204.195.243/login/request/?client_id=tQ2O6AhvqTCWkIpyDfjiInmAYx2dRTZF`}
            className="bg-[#FF5E59] w-full h-[50px] text-[20px] text-white my-5 lg:my-6 xs:mx-3 rounded-[5px] shadow-sigin flex justify-center items-center"
          >
            Server Sign In
          </a> */}
          <div className="flex flex-row justify-center items-center w-full px-0 gap-1  lg:py-0 ">
            <div className="bg-[#9d9d9d] lg:w-[35%] w-[30%] h-[1px]"></div>
            <div className="text-sm  text-center text-[#95969D]">
              Or sign in with
            </div>
            <div className="bg-[#9d9d9d] lg:w-[35%] w-[30%] h-[1px]"></div>
          </div>
          <div className="flex justify-center gap-5 lg:my-5 xs:my-4">
            <GoogleLogin />
            <AppleLogin />
            <LinkedInCallback />
          </div>
        </div>

        <div className="mt-5">
          <span className="text-[#95969D]">Don’t have an account? </span>
          <Link to="/register" className="text-[#FF5E59] underline">
            Sign up
          </Link>
        </div>
      </div>
    </>
  );
}
