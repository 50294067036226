import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useChangePassword from "../hooks/useChangePassword";
import { ButtonLoader2 } from "../components/shared/ButtonLoader";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";
import { BiHide, BiShow } from "react-icons/bi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IoMdArrowBack } from "react-icons/io";
import BackButton from "../components/shared/BackButton";

const initialValues = {
  old_password: "",
  password: "",
  confirm_password: "",
};
const validationSchema = Yup.object().shape({
  old_password: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .required("New password is required")
    .min(6, "Password length must be at least 6 characters long!")
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      "Password must contain at least one alphabetic character and one special character"
    )
    .max(20, "password must not exceed 20 characters"),
  confirm_password: Yup.string()
    .required("Confirm new password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

const ChangePassword = () => {
  const Navigate = useNavigate();
  const { loading, ChangePassword } = useChangePassword();
  const [oldPassowrdVisible, setOldPassowrdVisible] = useState(false);
  const [passowrdVisible, setPassowrdVisible] = useState(false);
  const [confirmPassowrdVisible, setConfirmPassowrdVisible] = useState(false);

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      await ChangePassword(
        {
          old_password: values.old_password,
          new_password: values.password,
        },
        action
      );
    },
  });

  const handleGoBack = () => {
    Navigate(-1);
  };

  return (
    <>
      <Navbar />
      <div className="profile_backgroundImage grid lg:grid-cols-[30%_minmax(60%,_1fr)] lg:mt-[75px] xs:mt-[70px] py-5 mb-5 bg-contain bg-no-repeat min-h-[75vh] xs:px-2 lg:px-0">
        <div className="h-[30px] cursor-pointer px-10 lg:flex xs:hidden">
          <Link to="#" onClick={handleGoBack}>
            <BackButton />
          </Link>
        </div>
        <div className="h-auto lg:w-[500px] 2xl:w-[50%] w-full flex flex-col justify-center items-center rounded-[20px] gap-3 relative py-10 lg:shadow-profile xs:shadow-profile lg:px-8 xs:px-6">
          <div className="absolute top-3 left-3 lg:hidden xs:block">
            <Link to="#" onClick={handleGoBack}>
              <IoMdArrowBack className="text-[30px]" />
            </Link>
          </div>
          <h1 className="text-center text-[#263238] font-semibold lg:text-[35px] xs:text-[25px]">
            Change password
          </h1>
          <div className="w-full">
            <label>Current password</label>
            <div className="relative">
              <input
                style={{
                  boxShadow:
                    "0px 4.171053886413574px 12.513161659240723px 0px #00000026",
                }}
                type={oldPassowrdVisible ? "text" : "password"}
                name="old_password"
                value={values.old_password}
                onChange={handleChange}
                placeholder="Enter current password"
                className="bg-[#FFFFFF] w-full py-3 border border-[#F1F1F1] px-4 rounded-[10px]"
              />
              <div
                className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                onClick={() => setOldPassowrdVisible(!oldPassowrdVisible)}
              >
                {oldPassowrdVisible ? (
                  <BiShow className="text-2xl text-[#95969D]" />
                ) : (
                  <BiHide className="text-2xl text-[#95969D]" />
                )}
              </div>
            </div>
            {errors.old_password && touched.old_password && (
              <p className="text-red-700 text-xs">{errors.old_password}</p>
            )}
          </div>
          <div className="w-full">
            <label>New password</label>
            <div className="relative">
              <input
                style={{
                  boxShadow:
                    "0px 4.171053886413574px 12.513161659240723px 0px #00000026",
                }}
                type={passowrdVisible ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Enter new password"
                className="bg-[#FFFFFF] w-full py-3 border border-[#F1F1F1] px-4 rounded-[10px]"
              />
              <div
                className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                onClick={() => setPassowrdVisible(!passowrdVisible)}
              >
                {passowrdVisible ? (
                  <BiShow className="text-2xl text-[#95969D]" />
                ) : (
                  <BiHide className="text-2xl text-[#95969D]" />
                )}
              </div>
            </div>
            {errors.password && touched.password && (
              <p className="text-red-700 text-xs">{errors.password}</p>
            )}
          </div>
          <div className="w-full">
            <label>Confirm new password</label>
            <div className="relative">
              <input
                style={{
                  boxShadow:
                    "0px 4.171053886413574px 12.513161659240723px 0px #00000026",
                }}
                type={confirmPassowrdVisible ? "text" : "password"}
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                placeholder="Confirm new password"
                className="bg-[#FFFFFF] w-full py-3 border border-[#F1F1F1] px-4 rounded-[10px]"
              />
              <div
                className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                onClick={() =>
                  setConfirmPassowrdVisible(!confirmPassowrdVisible)
                }
              >
                {confirmPassowrdVisible ? (
                  <BiShow className="text-2xl text-[#95969D]" />
                ) : (
                  <BiHide className="text-2xl text-[#95969D]" />
                )}
              </div>
            </div>
            {errors.confirm_password && touched.confirm_password && (
              <p className="text-red-700 text-xs">{errors.confirm_password}</p>
            )}
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-[#FF5E59] rounded-[5px] mt-3 lg:h-[50px] xs:h-[35px] flex justify-center items-center text-white w-full"
          >
            {loading ? <ButtonLoader2 /> : "Confirm Password"}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChangePassword;
