import Avatar1 from "../assets/images/Avatar1.svg";
import Avatar2 from "../assets/images/Avatar2.svg";
import Avatar3 from "../assets/images/Avatar4.svg";
import Avatar4 from "../assets/images/Avatar5.svg";
import Avatar5 from "../assets/images/Avatar6.svg";
import Avatar6 from "../assets/images/Avatar7.svg";
import Avatar7 from "../assets/images/Avatar8.svg";

export const avatars_list = [
  { id: 1, avatar: Avatar1 },
  { id: 2, avatar: Avatar2 },
  { id: 3, avatar: Avatar3 },
  { id: 4, avatar: Avatar4 },
  { id: 5, avatar: Avatar5 },
  { id: 6, avatar: Avatar6 },
  { id: 7, avatar: Avatar7 },
];
