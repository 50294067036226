import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import ChatView from "../components/ChatView";
import Navbar from "../components/shared/Navbar";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Feedback from "../../src/assets/images/feedback.png";
import FeedbackModal from "../components/modals/FeedbackModel";
function Chat(props) {
  const [alreadyUploadedCV, setAlreadyUploadedCV] = useState(null);
  const [progress, setProgress] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token") || Cookies.get("token");
  useEffect(() => {
    if (token) {
      navigate("/chat");
    }
  }, [token]);

  return (
    <>
      <div
        className="w-full h-full"
        style={{
          backgroundImage: "url(/images/bgChat.png)",
          backgroundSize: "100%",
        }}
      >
        {showFeedback && (
          <FeedbackModal setRateus={setShowFeedback} rateus={showFeedback} />
        )}

        <Navbar setAlreadyUploadedCV={setAlreadyUploadedCV} />
        <div className="flex justify-center w-full lg:h-[100vh] xs:h-[100vh] relative lg:pt-[80px] xs:pt-[70px] overflow-hidden">
          <div className="flex flex-col items-center justify-between lg:w-[85%] w-full 2xl:w-[60%] py-4 lg:px-6 xs:px-2">
            <div className="w-full h-[88%]">
              <ChatView
                debugMessage={props.debugMessage}
                alreadyUploadedCV={alreadyUploadedCV}
                setAlreadyUploadedCV={setAlreadyUploadedCV}
                progress={progress}
                setProgress={setProgress}
                textIndex={textIndex}
                setTextIndex={setTextIndex}
              />
            </div>
            <div className="w-full h-[10%] flex justify-center items-end">
              <Input
                alreadyUploadedCV={alreadyUploadedCV}
                setAlreadyUploadedCV={setAlreadyUploadedCV}
                debugMessage={props.debugMessage}
                setDebugMessage={props.setDebugMessage}
                progress={progress}
                setProgress={setProgress}
                textIndex={textIndex}
                setTextIndex={setTextIndex}
              />
            </div>
            <div className="fixed 2xl:right-9 2xl:bottom-8 lg:right-9 lg:bottom-20 xs:bottom-1 xs:right-1 xs:hidden lg:block ">
              <button
                className="px-[20px] py-[10px] inline-flex items-center justify-center gap-3 rounded-[10px] bg-[#FF5E59] border-2 border-[ #FFFFFF] lg:text-[16px] sm:text-[12px] font-[600] text-[#F2F2F2]"
                style={{
                  boxShadow: "0px 0px 10px 0px rgba(185, 212, 128, 0.50)",
                }}
                onClick={() => setShowFeedback(true)}
              >
                <img src={Feedback} alt="" className="w-[18px] h-[15px]" />
                <span className="">Feedback</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
