import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const useSSOCrewdog = () => {
  const token = localStorage.getItem("token") || Cookies.get("token");
  const SSOUrl = process.env.REACT_APP_BASE_URL_SSO;
  const loginToCrewdog = async () => {
    const clientID = process.env.REACT_APP_CLIENT_ID_SSO_CREWDOG;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {
      client_id: clientID,
    };
    try {
      const response = await axios.post(`${SSOUrl}/authorize-token/`, data, {
        headers: headers,
      });
      const url = response.data;
      window.open(url, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  return { loginToCrewdog };
};

export default useSSOCrewdog;
