import React, { Fragment, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { useState } from "react";
import { GoPlus } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../style/Editor.css";
import { Log } from "oidc-client-ts";
// import QuillEditor from "react-quill";
function EmploymentHistory(props) {
  const cancelButtonRef = useRef(null);
  const { data, onSave } = props;

  const [cvData, setCvData] = useState(data);
  const [validationErrors, setValidationErrors] = useState([]);
  useEffect(() => {
    setCvData(data);
  }, [data]);

  const [employmentVisibility, setEmploymentVisibility] = useState(
    cvData?.employment_history.map(() => true)
  );
  const [editorContents, setEditorContents] = useState(() =>
    cvData?.employment_history.map((employment) => employment.description || "")
  );

  const onEditorStateChange = (index, newEditorContent) => {
    setValidationErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = { ...updatedErrors[index], description: null };
      return updatedErrors;
    });

    setEditorContents((prevEditorContents) => {
      const updatedEditorContents = [...prevEditorContents];
      updatedEditorContents[index] = newEditorContent;

      setCvData((prevState) => {
        const updatedEmployment = [...prevState.employment_history];
        updatedEmployment[index] = {
          ...updatedEmployment[index],
          description: newEditorContent,
        };
        return {
          ...prevState,
          employment_history: updatedEmployment,
        };
      });

      return updatedEditorContents;
    });
  };

  const handleInputChange = (index, field, value) => {
    setValidationErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = { ...updatedErrors[index], [field]: null };
      return updatedErrors;
    });

    setCvData((prevState) => {
      const updatedEmployment = [...prevState.employment_history];
      updatedEmployment[index] = {
        ...updatedEmployment[index],
        [field]: value,
      };
      return {
        ...prevState,
        employment_history: updatedEmployment,
      };
    });
  };

  const addEmployment = () => {
    setCvData((prevState) => ({
      ...prevState,
      employment_history: [...prevState.employment_history, {}],
    }));
  };

  const removeEmployment = (index) => {
    setCvData((prevState) => {
      if (prevState.employment_history.length === 1) {
        return prevState;
      }
      const updatedEmployment = [...prevState.employment_history];
      updatedEmployment.splice(index, 1);
      return {
        ...prevState,
        employment_history: updatedEmployment,
      };
    });
  };

  const handleArrowClick = (index) => {
    setEmploymentVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };
  const validateEmployment = () => {
    const errors = cvData?.employment_history.map((employment) => {
      return {
        company_name: !employment.company_name
          ? "Company name is required"
          : null,
        job_title: !employment.job_title ? "Job title is required" : null,
        // city: !employment.city ? "City is required" : null,
        // country: !employment.country ? "Country is required" : null,
        // start_date: !employment.start_date ? "Start date is required" : null,
        description: !employment.description ? "Description is required" : null,
      };
    });
    cvData?.employment_history.forEach((employment, index) => {
      if (!employment.company_name?.trim()) {
        errors[index].company_name = "Company name cannot be empty";
      }
      if (!employment.job_title?.trim()) {
        errors[index].job_title = "Job title cannot be empty";
      }
      // if (!employment.city?.trim()) {
      //   errors[index].city = "City cannot be empty";
      // }
      // if (!employment.country?.trim()) {
      //   errors[index].country = "Country cannot be empty";
      // }
      // if (!employment.start_date?.trim()) {
      //   errors[index].start_date = "Start date cannot be empty";
      // }
      if (!employment.description?.trim()) {
        errors[index].description = "Description cannot be empty";
      }
    });

    setValidationErrors(errors);

    return errors.every((error) =>
      Object.values(error).every((value) => value === null)
    );
  };

  const handleSubmit = () => {
    if (validateEmployment()) {
      onSave(cvData?.employment_history);
    }
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[999999]"
        initialFocus={cancelButtonRef}
        onClose={props.setIsOpenModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full lg:items-center xs:items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[500px] py-4 px-8 ">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={() => props.setIsOpenModal(false)} />
                </div>

                <div className="flex flex-col w-full">
                  <h1 className="text-[#263238] font-medium text-[30px]">
                    Employment history
                  </h1>
                  <div className="max-h-[300px] overflow-y-scroll pe-2 mt-4">
                    {cvData?.employment_history?.map((data, index) => (
                      <div className="w-full h-auto border border-[#EBEBEB] rounded-[20px] py-2 px-4 gap-4 flex flex-col mb-4">
                        <div className="w-full flex justify-between items-end">
                          <span>Employment history {index + 1}</span>
                          <div className="flex justify-center items-center gap-2">
                            <RiDeleteBin6Line
                              onClick={() => removeEmployment(index)}
                              className="cursor-pointer text-[15px]"
                            />
                            <div onClick={() => handleArrowClick(index)}>
                              {employmentVisibility[index] ? (
                                <MdKeyboardArrowDown className="text-[20px] cursor-pointer" />
                              ) : (
                                <MdKeyboardArrowUp className="text-[20px] cursor-pointer" />
                              )}
                            </div>
                          </div>
                        </div>
                        {!employmentVisibility[index] && (
                          <form className="flex flex-col w-full">
                            <div className="w-full mb-2">
                              <label>Company name</label>
                              <input
                                type="text"
                                placeholder="Enter company name"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.company_name || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "company_name",
                                    e.target.value
                                  )
                                }
                              />
                              {validationErrors[index]?.company_name && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].company_name}
                                </p>
                              )}
                            </div>
                            <div className="w-full mb-2">
                              <label>Job title</label>
                              <input
                                type="text"
                                placeholder="Enter field of study"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.job_title || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "job_title",
                                    e.target.value
                                  )
                                }
                              />
                              {validationErrors[index]?.job_title && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].job_title}
                                </p>
                              )}
                            </div>
                            <div className="w-full mb-2">
                              <label>City</label>
                              <input
                                type="text"
                                placeholder="Enter city"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.city}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "city",
                                    e.target.value
                                  )
                                }
                              />
                              {validationErrors[index]?.city && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].city}
                                </p>
                              )}
                            </div>
                            <div className="w-full mb-2">
                              <label>Country</label>
                              <input
                                type="text"
                                placeholder="Enter country"
                                className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                value={data.country || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "country",
                                    e.target.value
                                  )
                                }
                              />
                              {validationErrors[index]?.country && (
                                <p className="text-red-500 text-sm mt-1">
                                  {validationErrors[index].country}
                                </p>
                              )}
                            </div>
                            {data.start_date && (
                              <div className="w-full mb-2">
                                <label>Time period</label>
                                <div className="flex items-center gap-2">
                                  <div>
                                    <input
                                      type="date"
                                      className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                      value={data.start_date || ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "start_date",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {validationErrors[index]?.start_date && (
                                      <p className="text-red-500 text-sm mt-1">
                                        {validationErrors[index].start_date}
                                      </p>
                                    )}
                                  </div>
                                  <div>
                                    <input
                                      type="date"
                                      className="border border-[#EBEBEB] w-full py-2 px-4 rounded-[10px]"
                                      value={data.end_date || ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "end_date",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="w-full mb-2 ">
                              <label>Employment history </label>
                              <div className="border-2 border-[#000] Model_editor">
                                <ReactQuill
                                  value={editorContents[index]}
                                  onChange={(value) =>
                                    onEditorStateChange(index, value)
                                  }
                                  modules={{
                                    toolbar: [
                                      ["bold", "italic", "underline", "strike"],
                                      [{ list: "bullet" }],
                                      [{ align: [] }],
                                      [{ color: [] }, { background: [] }],
                                    ],
                                    clipboard: {
                                      matchVisual: false,
                                    },
                                  }}
                                  formats={[
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "list",
                                    "align",
                                    "bullet",
                                    "color",
                                    "background",
                                    "size",
                                  ]}
                                />
                              </div>
                            </div>
                            {validationErrors[index]?.description && (
                              <p className="text-red-500 text-sm mt-1">
                                {validationErrors[index].description}
                              </p>
                            )}
                          </form>
                        )}
                      </div>
                    ))}
                  </div>
                  <div
                    onClick={addEmployment}
                    className="flex items-center justify-center w-full border border-[#FF5E59] text-[#FF5E59] py-[10px] rounded-[5px] gap-1 mt-4 cursor-pointer"
                  >
                    <GoPlus className="text-[25px]" />
                    <button className="text-[16px] font-normal ">
                      Add Employment History
                    </button>
                  </div>
                  <div className="flex justify-end w-full gap-4 py-4 ">
                    <button
                      onClick={() => props.setIsOpenModal(false)}
                      className="bg-[#4E494933] bg-opacity-[60%] text-[black] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="bg-[#FF5E59]  text-[#fff] text-[16px] py-[10px] px-[30px] rounded-[5px]"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default EmploymentHistory;
